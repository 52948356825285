import { memo, useEffect, useMemo, useRef } from 'react';
import { useSelector } from '@xstate/react';
import Uplot from 'uplot';
import UplotReact from 'uplot-react';
import groupBy from 'lodash/groupBy';
// TODO: move to agents
import { dashboardActor } from '@components/RealtimeDashboard/model/xstate/dashboardMachine';

import { calculateDurations, renderRectangles } from './model/utils/speakerActivation';
import useResizeObserver from './model/hooks/useResizeObserver';
import { saveRealtimeAgentData } from '@api/ai/realtime';

// TODO: figure out how to stream ws data into components not by props drilling / or state machine
const SpeakerActivationComponent = ({
  //   isConnected,
  //   realtimeClientInstance,
  // realtimeEvents,
  sessionId,
  userId,
}: any) => {
  const realtimeEvents = useSelector(dashboardActor, (state) => state.context.realtimeEvents);

  const containerRef = useRef<HTMLDivElement>(null);

  const { width = 400, height = 150 } = useResizeObserver(containerRef);

  // Group and process events
  const userData = groupBy(
    realtimeEvents?.filter(
      (ev: any) =>
        ((ev.event.item_id || ev.event.item?.item_id) &&
          ev.event.type === 'input_audio_buffer.speech_started') ||
        ev.event.type === 'input_audio_buffer.speech_stopped'
    ),
    (ev: any) => ev.event.item_id
  );

  const AIData = groupBy(
    realtimeEvents?.filter(
      (ev: any) =>
        ev.role === 'assistant' &&
        ev.source === 'server' &&
        (ev.event.item_id || ev.event.item?.item_id)
    ),
    (ev: any) => ev.event.item_id || ev.event.item?.id
  );
  const chartData = useMemo(() => {
    return {
      userDurations: calculateDurations(userData),
      assistantDurations: calculateDurations(AIData),
    };
  }, [userData, AIData]);

  useEffect(() => {
    const saveSpeakerActivationData = async (agentData: any) => {
      await saveRealtimeAgentData(agentData, sessionId, userId, 'speaker-activation-ai-agent');
    };

    const agentData = [...Object.values(userData).flat(), ...Object.values(AIData).flat()];

    if (agentData.length && sessionId && userId) {
      saveSpeakerActivationData(agentData);
    }
  }, [userData, AIData]);

  const mergedTimestamps = useMemo(
    () => [
      ...chartData.userDurations.flatMap((d) => [d.start, d.end]),
      ...chartData.assistantDurations.flatMap((d) => [d.start, d.end]),
    ],
    [chartData]
  );

  const data = useMemo(() => {
    return [
      mergedTimestamps?.length ? mergedTimestamps : [0], // x-axis timestamps
      mergedTimestamps?.length ? Array(mergedTimestamps.length).fill(null) : [null], // y-axis placeholder
    ] as Uplot.AlignedData;
  }, [mergedTimestamps]);

  // Chart options
  const options: Uplot.Options = useMemo(
    () => ({
      width,
      height,
      padding: [0, 0, 0, 0],
      scales: {
        x: { time: true, auto: true },
        y: { auto: true, min: 0.5, max: 2.5 },
      },
      series: [{}, { stroke: 'transparent' }],
      axes: [
        {
          stroke: '#9ca3af',
          grid: { show: true, stroke: '#e5e7eb' },
          values: (_, ticks) => ticks.map((tick) => new Date(tick).toLocaleTimeString()),
        },
        {
          grid: { show: true, stroke: '#e5e7eb' },
          scale: 'y',
          values: (_, ticks) => ticks.map((val) => (val === 1 ? 'User' : val === 2 ? 'AI' : '')),
        },
      ],
      legend: { show: false },
      hooks: {
        draw: [
          (u) => {
            const ctx = u.ctx;
            renderRectangles(ctx, chartData.assistantDurations, u, 2, '#3b82f6', -7); // AI rectangles
            renderRectangles(ctx, chartData.userDurations, u, 1, '#9333ea', -7); // User rectangles
          },
        ],
      },
    }),
    [width, height, chartData]
  );

  return (
    <div
      ref={containerRef}
      className="w-full h-[150px]"
    >
      <UplotReact
        data={data}
        options={options}
      />
    </div>
  );
};

const SpeakerActivationPlot = memo(SpeakerActivationComponent);
SpeakerActivationPlot.displayName = 'SpeakerActivationPlot';
export default SpeakerActivationPlot;
