import * as Sentry from '@sentry/react';

const sentryEventHandler = (event: Sentry.ErrorEvent) => {
  const { extra = {}, message = '' } = event;
  // ignore errors if they have sentryHandled flag
  if ((extra.arguments as any[])?.[1]?.sentryHandled === true) {
    return null;
  }
  // ignore Clerk info/warnings
  if (message.includes('ClerkJS:') || message.includes('Clerk:')) {
    return null;
  }

  return event;
};

export const useSentry = () => {
  Sentry.init({
    dsn: 'https://24610ddf7f11a8e4a68d2c8fa0b7726b@o4506670857977856.ingest.us.sentry.io/4507741056991232',
    environment: process.env.VITE_ENV,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.browserProfilingIntegration(),
      Sentry.replayIntegration(),
      Sentry.captureConsoleIntegration({
        levels: ['error', 'info', 'warn'],
      }),
    ],
    beforeSend(event) {
      return sentryEventHandler(event);
    },
    // Performance Monitoring
    tracesSampleRate: 0.1, //  Capture 10% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/^https:\/\/(app|dev)\.betteryou\.tech(\/api)?/],
    // Set profilesSampleRate to 1.0 to profile every transaction.
    // Since profilesSampleRate is relative to tracesSampleRate,
    // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
    // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
    // results in 25% of transactions being profiled (0.5*0.5=0.25)
    profilesSampleRate: 0.1,
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 0.1, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    allowUrls: [/^https:\/\/(app|dev)\.betteryou\.tech(\/api)?/],
  });
};
