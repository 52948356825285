import { memo } from 'react';
import ToggleableTabs from '@features/Tab/ToggleableTabs/ToggleableTabs';
import { dashboardActor } from '../../RealtimeDashboard/model/xstate/dashboardMachine';
import { useSelector } from '@xstate/react';
import { TOGGLEABLE_SECTIONS } from '../../RealtimeDashboard/model/constants/togglableSections';

type AgentProps = {
  speechCharacteristics: {
    gender: string;
    language: string;
    tone: string;
  };
};

export function SelfReviewTopicDetectionAgent({ speechCharacteristics }: AgentProps) {
  const sections = useSelector(dashboardActor, (state) => state.context.sections);

  const handleSectionToggle = (key: string) => {
    dashboardActor.send({ type: 'TOGGLE_SECTION', key });
  };

  return (
    <ToggleableTabs
      isShown={sections[TOGGLEABLE_SECTIONS.SPEECH_CHARACTERISTICS]}
      onToggle={() => handleSectionToggle(TOGGLEABLE_SECTIONS.SPEECH_CHARACTERISTICS)}
      tabs={[
        {
          label: 'Speech characteristics',
          content: (
            <div className="my-4">
              <div className="flex gap-4">
                <div>
                  <p className="font-bold">Language:</p>
                  <p className="font-bold">Gender:</p>
                  <p className="font-bold">Tone:</p>
                </div>
                <div>
                  <p>{speechCharacteristics.language}</p>
                  <p>{speechCharacteristics.gender}</p>
                  <p>{speechCharacteristics.tone}</p>
                </div>
              </div>
            </div>
          ),
        },
      ]}
    />
  );
}

export default memo(SelfReviewTopicDetectionAgent);
