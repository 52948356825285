import DailyCoMeetComponent from '@components/Daily.co';
import { refaelaTherapistActor } from '@components/xState/machines/refaelaTherapistMachine';
import { useSelector } from '@xstate/react';
import SessionWrapup from '../SessionWrapup';

import { useEffect, useState } from 'react';
import { getKeyMoments, startDailySession } from '@api/userAPI';
import WaitingRoom from '../WaitingRoom';
import { fetchPatientRecordingData, fetchTherapistRecordingData } from '@api/session/recordingData';
import { fetchPatientTranscriptsData } from '@api/session/transcriptsData';

import { ShamefulAny } from '@interfaces/index';
import useGetTherapistPatientFrames from '@hooks/useGetTherapistPatientFrames';

export const Session = () => {
  const snapshot = useSelector(refaelaTherapistActor, (snapshot) => snapshot);

  const [keyMomentData, setKeyMomentsData] = useState(null);
  const [updatedKeyMoments, setUpdatedKeymoments] = useState(null);

  const [loadingState, setIsLoadingState] = useState<Record<string, ShamefulAny>>({
    general: { isLoading: false, isFailed: null, error: null },
    recording: { isLoading: false, isFailed: null, error: null },
    therapistRecording: { isLoading: false, isFailed: null, error: null },
    transcripts: { isLoading: false, isFailed: null, error: null },
    frames: { isLoading: false, isFailed: false, error: null, data: null },
    therapistFrames: { isLoading: false, isFailed: false, error: null, data: null },
  });

  const patientId = snapshot.context.currentPatientId!;

  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
  const therapistId = snapshot.context.currentUser?.therapistId!;

  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
  const roomUrl = snapshot.context?.roomUrl!;

  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
  const sessionId = roomUrl?.split('/').pop()!;
  const { fetchFrames } = useGetTherapistPatientFrames(
    patientId,
    therapistId,
    sessionId,

    setIsLoadingState
  );
  useEffect(() => {
    const startSession = async () => {
      await startDailySession(roomUrl);
    };
    // @ts-expect-error: state machine types
    snapshot.matches('TherapistSession.TherapistMeetingRoom') && startSession();
  }, [snapshot]);

  const handleFinishSession = async () => {
    try {
      setIsLoadingState((prev) => ({
        ...prev,
        general: { ...prev.general, isLoading: true, isFailed: false, error: null },
      }));

      const keyMomentsResponse = await getKeyMoments(patientId, sessionId);
      setKeyMomentsData(keyMomentsResponse);
      setUpdatedKeymoments(keyMomentsResponse);

      fetchPatientRecordingData(
        patientId,
        sessionId,
        therapistId,
        undefined,
        undefined,
        false,
        setIsLoadingState
      );
      fetchTherapistRecordingData(
        patientId,
        sessionId,
        therapistId,
        undefined,
        undefined,
        true,
        setIsLoadingState
      );
      await fetchFrames();

      await fetchPatientTranscriptsData(patientId, sessionId, therapistId, setIsLoadingState);

      setIsLoadingState((prev) => ({
        ...prev,
        general: { ...prev.general, isLoading: false, isFailed: false, error: null },
      }));
    } catch (error: ShamefulAny) {
      setIsLoadingState((prev) => ({
        ...prev,
        general: { ...prev.general, isLoading: false, isFailed: true, error: error.message },
      }));
      console.error('An error occurred while finishing the session:', JSON.stringify(error));
    }
  };

  return (
    <>
      {(snapshot.matches('TherapistSession.TherapistPretalkWaitingRoom' as ShamefulAny) ||
        snapshot.matches('TherapistSession.TherapistMeetingRoom' as ShamefulAny)) && (
        <WaitingRoom />
      )}
      {snapshot.matches('TherapistSession.TherapistMeetingRoom' as ShamefulAny) && (
        <DailyCoMeetComponent
          key="daily_meet_therapist_component"
          onFinishSession={handleFinishSession}
          actor={refaelaTherapistActor}
        />
      )}
      {snapshot.matches('TherapistSession.TherapistDebriefWaitingRoom' as ShamefulAny) && (
        <SessionWrapup
          keyMomentData={keyMomentData}
          updatedKeyMoments={updatedKeyMoments}
          loadingState={loadingState}
          setUpdatedKeymoments={setUpdatedKeymoments}
        />
      )}
    </>
  );
};
