import { useMXLabsLogsAISessionReview } from '@components/Plot/utils';
import { AISession, ShamefulAny } from '@interfaces/index';

import { useState, useEffect, useCallback, useRef } from 'react';
import SelfReviewMeasurementGraphs from './ui/SelfReviewMeasurementGraphs';
import SelfReviewTimeline from './ui/SelfReviewTimeline';
import SelfReviewVisionAIModel from './ui/VisionAIModel/SelfReviewVisionAIModel';
import { refaelaTherapistActor } from '@components/xState/machines/refaelaTherapistMachine';
import { useSelector } from '@xstate/react';
import { getAiSessionFrames } from '@api/ai/aiFrame';
import { getAiSessionRecording } from '@api/ai/aiRecording';

import SelfReviewMediaDisplayBlock from './ui/SelfReviewMediaDisplayBlock';
import { getSessionInfo } from '@api/session/sessionInfo';
import { getIdWithoutPrefix } from '@utils/prefixes';
import { fetchAiSessionSettings } from '@api/userAPI';
import { SessionSettings } from '@components/RealtimeDashboard/types';
import { getAiSessionRealtimeData } from '@api/ai/aiRealtimeData';
import SelfReviewConversationData from './ui/SelfReviewConversationData';
import SelfReviewConversationalModelProperties from './ui/SelfReviewConversationalModelProperties';
import { defaultSessionSettings } from '@shared/constants/sessionSettings';
import { getLatestItemByTimestamp } from '@shared/utils/shenai/getLatestItemByTimestamp';
import SelfReviewTopicDetectionAgent from './ui/SelfReviewTopicDetectionAgent';
import SelfReviewConversationAnalysisData from './ui/SelfReviewConversationAnalysisData';
import SelfReviewCharacteristicData from './ui/SelfReviewCharacteristicData';
import SelfReviewFrequentKeywordsData, {
  FrequentKeywordsLibrary,
} from './ui/SelfReviewFrequentKeywordsData';
import _ from 'lodash';
import SelfReviewSummaryData from './ui/SelfReviewSummaryData';
import SelfReviewSuggestionData from './ui/SelfReviewSuggestionData';
import SelfReviewSpeakerActivationData from './ui/SelfReviewSpeakerActivationData';
import { flattenDataArray } from './utils';

const SelfWorkReview = () => {
  const stateMachineContext = useSelector(
    refaelaTherapistActor,
    (state: ShamefulAny) => state.context
  );

  const aiReviewUserId = stateMachineContext.aiReviewUserId;
  const aiReviewSessionId = stateMachineContext.aiReviewSessionId;
  const [currentSelfReviewTime, setCurrentSelfReviewTime] = useState<number>(0);
  const [currentDataPoint, setCurrentDataPoint] = useState<ShamefulAny | null>(null);
  const [frames, setFrames] = useState<ShamefulAny | null>(null);
  const [recording, setRecording] = useState<ShamefulAny | null>(null);

  const [realtimeEvents, setRealtimeEvents] = useState<ShamefulAny[]>([]);
  const [realtimeTranscripts, setRealtimeTranscripts] = useState<ShamefulAny[]>([]);
  const [realtimeAgents, setRealtimeAgents] = useState<ShamefulAny[]>([]);
  const [activeTopic, setActiveTopic] = useState<string>('');
  const [speakerActivationData, setSpeakerActivationData] = useState<ShamefulAny[]>([]);

  const [summaryData, setSummaryData] = useState({
    mainTopic: 'Waiting for data...',
    mostMeaningfulStatement: 'Waiting for data...',
    summary: 'Waiting for data...',
  });

  const [suggestionData, setSuggestionData] = useState({
    topicsToExplore: ['Waiting for data...'],
    topicsToExpand: ['Waiting for data...'],
    deepeningQuestions: ['Waiting for data...'],
  });

  const [speechCharacteristics, setSpeechCharacteristics] = useState({
    language: 'Waiting for data...',
    gender: 'Waiting for data...',
    tone: 'Waiting for data...',
  });

  const [characteristicData, setCharacteristicData] = useState({
    openness: 0,
    introspection: 0,
    depthOfSharing: 0,
  });

  const [filteredFrames, setFilteredFrames] = useState<ShamefulAny[]>([]);
  const [frequentKeywords, setFrequentKeywords] = useState<FrequentKeywordsLibrary>({});
  const [filteredMxlabsData, setFilteredMxlabsData] = useState<ShamefulAny[]>([]); // filtered according start and stop time from session.json
  const [sessionInfoData, setSessionInfoData] = useState<AISession | null>(null);
  const [sessionSettings, setSessionSettings] = useState<SessionSettings>(defaultSessionSettings);

  const [currentFrame, setCurrentFrame] = useState<ShamefulAny | null>(null);
  const videoRef = useRef<HTMLVideoElement>(null);

  const { logs: mxlabs } = useMXLabsLogsAISessionReview(aiReviewUserId, aiReviewSessionId);

  const sessionReviewUrl = `${window.location.origin}/?userId=${getIdWithoutPrefix(aiReviewUserId)}&sessionId=${aiReviewSessionId}`;

  useEffect(() => {
    const fetchAiSettings = async () => {
      try {
        const { data: settings } = await fetchAiSessionSettings(aiReviewSessionId);
        setSessionSettings(settings);
      } catch (e) {
        console.log('error getting AI session settings: ', e);
      }
    };

    fetchAiSettings();
  }, []);

  const fetchSessionData = useCallback(async () => {
    try {
      const sessionResponse = await getSessionInfo(aiReviewSessionId);

      if (sessionResponse.error) {
        throw new Error(sessionResponse.message);
      }

      setSessionInfoData(sessionResponse);
    } catch (error: ShamefulAny) {
      console.error('Error fetching session data:', JSON.stringify(error));
    }
  }, [aiReviewSessionId]);

  useEffect(() => {
    fetchSessionData();
  }, [fetchSessionData]);

  const fetchFrames = useCallback(async () => {
    try {
      const framesResponse = await getAiSessionFrames(aiReviewUserId, aiReviewSessionId);

      if (framesResponse.error) {
        console.error(
          'Error while fetching ai session frames',
          JSON.stringify(framesResponse.message)
        );
        return;
      }

      const framesData = framesResponse.data.frames || [];
      setFrames(framesData);
    } catch (error: ShamefulAny) {
      console.error('Error fetching frames:', JSON.stringify(error));
    }
  }, [aiReviewUserId, aiReviewSessionId]);

  useEffect(() => {
    fetchFrames();
  }, [fetchFrames]);

  const fetchRecording = useCallback(async () => {
    try {
      const recordingResponse = await getAiSessionRecording(aiReviewUserId, aiReviewSessionId);

      if (recordingResponse.error) {
        console.error(
          'Error while fetching ai session recording',
          JSON.stringify(recordingResponse.error)
        );
        return;
      }

      const recordingData = recordingResponse.data.recording || {};
      setRecording(recordingData);
    } catch (error: ShamefulAny) {
      console.error('Error fetching recording:', JSON.stringify(error));
    }
  }, [aiReviewUserId, aiReviewSessionId]);

  useEffect(() => {
    fetchRecording();
  }, [fetchRecording]);

  const fetchRealtimeData = useCallback(async () => {
    try {
      const { events, transcripts, agents } = await getAiSessionRealtimeData(
        aiReviewUserId,
        aiReviewSessionId
      );

      setRealtimeAgents(agents || []);
      setRealtimeEvents(events || []);
      setRealtimeTranscripts(transcripts || []);
    } catch (error: ShamefulAny) {
      console.error('Error fetching realtime data:', JSON.stringify(error));
    }
  }, [aiReviewUserId, aiReviewSessionId]);

  useEffect(() => {
    fetchRealtimeData();
  }, [fetchRealtimeData]);

  useEffect(() => {
    if (sessionInfoData && mxlabs) {
      const { sessionStartTime, sessionStopTime } = sessionInfoData;

      // If sessionStopTime is 0 and sessionStartTime > 0, update sessionStopTime

      let updatedSessionStopTime = sessionStopTime;
      if (sessionStopTime === 0 && sessionStartTime > 0 && mxlabs.length > 0) {
        updatedSessionStopTime = mxlabs[mxlabs.length - 1].timestamp;

        setSessionInfoData((prev) =>
          prev ? { ...prev, sessionStopTime: updatedSessionStopTime } : null
        );
      }

      setCurrentSelfReviewTime(sessionStartTime || 0);

      // Filter mxlabs based on sessionStartTime and updatedSessionStopTime
      const filteredMxlabs = mxlabs.filter(
        (data: ShamefulAny) =>
          data.timestamp >= sessionStartTime && data.timestamp <= updatedSessionStopTime
      );
      setFilteredMxlabsData(filteredMxlabs);
    }
  }, [sessionInfoData, mxlabs]);

  // Filter frames based on sessionInfoData
  // TODO: test how it works when frames are availiable
  useEffect(() => {
    if (sessionInfoData && frames) {
      const { sessionStartTime, sessionStopTime } = sessionInfoData;

      const filteredFramesData = frames.filter(
        (frame: ShamefulAny) =>
          frame.timestamp >= sessionStartTime && frame.timestamp <= sessionStopTime
      );
      setFilteredFrames(filteredFramesData);
    }
  }, [sessionInfoData, frames]);

  useEffect(() => {
    if (sessionInfoData) {
      const { sessionStartTime } = sessionInfoData;

      // filter agents by the timeline
      const filteredRealtimeAgentsData = realtimeAgents?.filter((realtimeAgent: ShamefulAny) => {
        return (
          realtimeAgent.timestamp >= sessionStartTime &&
          realtimeAgent.timestamp <= currentSelfReviewTime
        );
      });

      // filter agents by type
      const filteredSpeakerActivationAgentData = filteredRealtimeAgentsData.filter(
        (item: any) => item.agentType == 'speaker-activation-ai-agent'
      );

      const filteredSpeakerActivationData = flattenDataArray(filteredSpeakerActivationAgentData);

      const filteredSpeechCharacteristicData = filteredRealtimeAgentsData.filter(
        (item: any) => item.agentType == 'speech-characteristics-ai-agent'
      );
      const filteredCharacteristicData = filteredRealtimeAgentsData.filter(
        (item: any) => item.agentType == 'characteristic-ai-agent'
      );
      const filteredConversationAnalysisData = filteredRealtimeAgentsData.filter(
        (item: any) => item.agentType == 'conversation-analysis-ai-agent'
      );
      const filteredFrequentKeywordsData = filteredRealtimeAgentsData.filter(
        (item: any) => item.agentType == 'frequent-keywords-ai-agent'
      );

      const filteredSummaryData = filteredRealtimeAgentsData.filter(
        (item) => item.agentType == 'history-ai-agent'
      );
      const filteredSuggestionData = filteredRealtimeAgentsData.filter(
        (item) => item.agentType == 'suggestion-ai-agent'
      );

      // find the closest timestamp item to display
      const latestSummaryData = getLatestItemByTimestamp(filteredSummaryData);
      const latestSuggestionData = getLatestItemByTimestamp(filteredSuggestionData);
      const latestCharacteristicData = getLatestItemByTimestamp(filteredCharacteristicData);
      const latestSpeechCharacteristicData = getLatestItemByTimestamp(
        filteredSpeechCharacteristicData
      );
      const latestConversationAnalysisData = getLatestItemByTimestamp(
        filteredConversationAnalysisData
      );

      if (!_.isEqual(filteredSpeakerActivationData, speakerActivationData)) {
        setSpeakerActivationData(filteredSpeakerActivationData || speakerActivationData);
      }

      if (!_.isEqual(latestSummaryData?.data, summaryData)) {
        setSummaryData(latestSummaryData?.data || summaryData);
      }

      if (!_.isEqual(latestSuggestionData?.data, suggestionData)) {
        setSuggestionData(latestSuggestionData?.data || suggestionData);
      }

      if (!_.isEqual(latestCharacteristicData?.data, characteristicData)) {
        setCharacteristicData(latestCharacteristicData?.data || characteristicData);
      }

      if (!_.isEqual(latestSpeechCharacteristicData?.data, speechCharacteristics)) {
        setSpeechCharacteristics(latestSpeechCharacteristicData?.data || speechCharacteristics);
      }

      if (!(latestConversationAnalysisData?.data?.activeTopic == activeTopic)) {
        setActiveTopic(latestConversationAnalysisData?.data?.activeTopic || '');
      }

      let keywordsData: FrequentKeywordsLibrary = {};
      filteredFrequentKeywordsData.length > 0 &&
        filteredFrequentKeywordsData.forEach((item) => {
          if (item.data.keyword == 'N/A') return;

          const isKeywordPresent = keywordsData[item.data.keyword];

          if (isKeywordPresent) {
            keywordsData = {
              ...keywordsData,
              [item.data.keyword]: frequentKeywords[item.data.keyword] + 1,
            };
          } else {
            keywordsData = { ...keywordsData, [item.data.keyword]: 1 };
          }
        });

      if (!_.isEqual(keywordsData, frequentKeywords)) {
        setFrequentKeywords(keywordsData);
      }
    }
  }, [sessionInfoData, currentSelfReviewTime]);

  // Find the closest data point and corresponding frame
  useEffect(() => {
    const closestData =
      filteredMxlabsData.find((el: ShamefulAny) => el.timestamp >= currentSelfReviewTime) || null;
    setCurrentDataPoint(closestData);

    if (filteredFrames) {
      const closestFrame =
        filteredFrames.find((frame: ShamefulAny) => frame.timestamp >= currentSelfReviewTime) ||
        null;
      setCurrentFrame(closestFrame);
    }
  }, [currentSelfReviewTime, filteredMxlabsData, filteredFrames]);

  return (
    <div className="grid grid-cols-[25%_50%_25%] gap-4 p-6 w-full text-left">
      <div className="bg-transparent rounded-lg">
        <SelfReviewVisionAIModel
          key="review-vision-ai-model"
          currentDataPoint={currentDataPoint}
          currentSelfReviewTime={currentSelfReviewTime}
          dataFromMxlabs={filteredMxlabsData}
        />
      </div>
      <div className="bg-transparent p-4 rounded-lg col-span-1.5">
        <SelfReviewMediaDisplayBlock
          frame={currentFrame}
          recording={recording}
          ref={videoRef}
          sessionReviewLink={sessionReviewUrl}
        />
        <SelfReviewTimeline
          startTime={sessionInfoData?.sessionStartTime || 0}
          endTime={sessionInfoData?.sessionStopTime || 0}
          videoRef={videoRef}
          setCurrentSelfReviewTime={setCurrentSelfReviewTime}
          currentSelfReviewTime={currentSelfReviewTime}
        />
        <SelfReviewConversationData
          startTime={sessionInfoData?.sessionStartTime.toString() || '0'}
          transcripts={realtimeTranscripts}
          events={realtimeEvents}
          prompt={sessionSettings.instructions}
        />
        <SelfReviewMeasurementGraphs
          filteredDataFromMxlabs={filteredMxlabsData}
          currentDataPoint={currentDataPoint}
          currentSelfReviewTime={currentSelfReviewTime}
        />
        <div>
          <SelfReviewSummaryData summaryData={summaryData} />
          <SelfReviewSuggestionData suggestionData={suggestionData} />
        </div>
      </div>
      <div>
        <div className="flex flex-col gap-4">
          <SelfReviewConversationalModelProperties
            initialMaxTokens={sessionSettings.maxTokens}
            initialPrefixPadding={sessionSettings.prefixPadding}
            initialSilenceDuration={sessionSettings.silenceDuration}
            initialTemperature={sessionSettings.temperature}
            initialThreshold={sessionSettings.threshold}
            initialVoice={sessionSettings.voice}
          />
          <SelfReviewSpeakerActivationData speakerActivationData={speakerActivationData} />
          <SelfReviewFrequentKeywordsData frequentKeywords={frequentKeywords} />
          <SelfReviewTopicDetectionAgent speechCharacteristics={speechCharacteristics} />
          <SelfReviewConversationAnalysisData activeTopic={activeTopic} />
          <SelfReviewCharacteristicData characteristicData={characteristicData} />
        </div>
      </div>
    </div>
  );
};

export default SelfWorkReview;
