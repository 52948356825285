import Button from '@components/Button';
import { dashboardActor } from '@components/RealtimeDashboard/model/xstate/dashboardMachine';
import SelectedPatientOption from '@components/SessionComponents/InvitePatientStep/ui/SelectedPatientOption';
import { refaelaTherapistActor } from '@components/xState/machines/refaelaTherapistMachine';
import {
  AI_SESSION_PROMPT_BY_SESSION_TYPE,
  AI_SESSION_TYPES_NORMALISED,
  SESSION_PROMPTS,
} from '../../../../../interfaces/constants';
import {
  SessionPrompts,
  NormalisedSessionPromptsBySessionType,
} from '../../../../../interfaces/types';
import { SelectOption, SimpleSelect } from '@shared/ui/input/select/SimpleSelect';
import { langfuseWeb } from '@utils/llm';
import { useEffect, useState } from 'react';

type AISessionPresetProps = {
  onCancel: () => void;
  currentUser: any;
};

export const AISessionPresetModal = ({ onCancel, currentUser }: AISessionPresetProps) => {
  const [promptsBySessionType, setPromptsBySessionType] = useState({
    [SESSION_PROMPTS.AI_BIO_FEEDBACK_1]: '',
    [SESSION_PROMPTS.AI_BIO_FEEDBACK_2]: '',
    [SESSION_PROMPTS.AI_BIO_FEEDBACK_3]: '',
    [SESSION_PROMPTS.AI_FOLLOW_UP_CONVERSATION_1]: '',
    [SESSION_PROMPTS.AI_FOLLOW_UP_CONVERSATION_2]: '',
    [SESSION_PROMPTS.AI_FOLLOW_UP_CONVERSATION_3]: '',
    [SESSION_PROMPTS.AI_MEMORY_INTERVIEW_1]: '',
    [SESSION_PROMPTS.AI_MEMORY_INTERVIEW_2]: '',
    [SESSION_PROMPTS.AI_MEMORY_INTERVIEW_3]: '',
  });

  const [selectedSessionType, setSelectedSessionType] = useState('');
  const [selectedSessionPrompt, setSelectedSessionPrompt] = useState('');
  const [promptText, setPromptText] = useState('');

  useEffect(() => {
    const fetchPrompts = async () => {
      const sessionTypes = Object.keys(promptsBySessionType);

      const fetchPromises = sessionTypes.map(async (type) => {
        const prompt = (await langfuseWeb.getPrompt(type)).compile({
          user_name: 'Artsem',
        });

        return { type, prompt };
      });

      const results = await Promise.all(fetchPromises);

      setPromptsBySessionType((prevState) => {
        const updatedState = { ...prevState };
        results.forEach(({ type, prompt }) => {
          updatedState[type as keyof SessionPrompts] = prompt;
        });
        return updatedState;
      });
    };

    fetchPrompts();
  }, []);

  const handlePromptSave = () => {
    onCancel();

    dashboardActor.send({
      type: 'SESSION_PROMPT_UPDATE',
      sessionPrompt: promptText,
    });

    refaelaTherapistActor.send({
      type: 'goToAIDashboard',
      currentUser: currentUser,
    });
  };

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center">
      <div className="sm:mx-auto sm:w-full">
        <div className="flex flex-col gap-4 mb-4 h-[35rem]">
          <p className="font-medium">Select session type</p>
          <SimpleSelect
            placeholder="Select session type"
            isOpen={false}
            selectClass="w-full"
            selectOptionClass="h-6 text-text-subtext"
            selectDropdownClass=""
            withSearch={false}
            selectedOption={{ label: '', value: '' }}
            renderValue={(placeholder) => (
              <SelectedPatientOption
                label={selectedSessionType}
                placeholder={placeholder}
              />
            )}
            onChange={({ value }) => {
              setSelectedSessionType(value);
              setSelectedSessionPrompt('');
              setPromptText('');
            }}
          >
            {AI_SESSION_TYPES_NORMALISED.map((type, index) => (
              <SelectOption
                key={index}
                value={type}
              >
                {type}
              </SelectOption>
            ))}
          </SimpleSelect>
          {selectedSessionType && (
            <div className="flex flex-col gap-4">
              <p className="font-medium">Select prompt</p>
              <SimpleSelect
                placeholder="Choose session prompt"
                isOpen={false}
                withSearch={false}
                selectClass="w-full"
                selectOptionClass="h-6 text-text-subtext"
                selectDropdownClass=""
                selectedOption={{ label: '', value: '' }}
                renderValue={(placeholder) => (
                  <SelectedPatientOption
                    label={selectedSessionPrompt}
                    placeholder={placeholder}
                  />
                )}
                onChange={({ value }) => {
                  setSelectedSessionPrompt(value);
                  setPromptText(promptsBySessionType[value as keyof SessionPrompts]);
                }}
              >
                {selectedSessionType &&
                  AI_SESSION_PROMPT_BY_SESSION_TYPE[
                    selectedSessionType as keyof NormalisedSessionPromptsBySessionType
                  ].map((type, index) => (
                    <SelectOption
                      key={index}
                      value={type}
                    >
                      {type}
                    </SelectOption>
                  ))}
              </SimpleSelect>
            </div>
          )}
          {selectedSessionPrompt && (
            <div className="flex flex-col gap-4">
              <label
                htmlFor="main_prompt"
                className="block font-medium leading-6 text-gray-900"
              >
                Prompt text
              </label>
              <textarea
                id="main_prompt"
                className="block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-button-hover placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 h-[15rem]"
                value={promptText}
                onChange={(event) => {
                  setPromptText(event.target.value)
                }}
              />
              <div className="flex justify-center pt-5 gap-4">
                <Button
                  variant="outline"
                  text="Cancel"
                  onClick={onCancel}
                  className="w-[50%]"
                />
                <Button
                  onClick={handlePromptSave}
                  text="Start AI Session"
                  className="w-[50%]"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
