import React, { useContext, useEffect, useState } from 'react';
import { useUser } from '@clerk/clerk-react';
// API
import { createDailySession, generateSessionLink, updatePatientsList } from '@api/userAPI';

// Hooks
import { useUserMetadata } from '@hooks/useUserMetadata';
// Components
import { SimpleSelect, SelectOption } from '@shared/ui/input/select/SimpleSelect';
import { InvitePatientForm } from '@components/Forms/InvitePatient/InvitePatientForm';
import Button from '@components/Button';
import { Modal } from '@features/Modal/ui/Modal';
import { AppContext } from '../../../contextApp';
import { Loader } from '@shared/ui/loader/Loader';
import { TextInputController } from '@components/Forms/ui/TextInputController';
import CopyButton from './ui/CopyButton';
import PatientOption from './ui/PatientOption';
import SelectedPatientOption from './ui/SelectedPatientOption';
import { getUpdatedPatientId } from './model/utils/getUpdatedPatientId';
import { refaelaTherapistActor } from '@components/xState/machines/refaelaTherapistMachine';
import { ShamefulAny } from '@interfaces/index';
import { sendSms } from '@api/twilio';
import { sendEmail } from '@api/email';
import { useSelector } from '@xstate/react';
import { findPatientById } from '@utils/patient';
import { linkToSessionEmailTemplate } from '@utils/emailTemplate';
import { SESSION_TYPES } from '@interfaces/constants';

// TODO: change when calendar development kicks in
const THERAPIST_SCHEDULE_URL = 'marshmallow/therapy';

type InvitePatientStepProps = {
  setRoomUrl: React.Dispatch<React.SetStateAction<string>>;
  setSessionId: React.Dispatch<React.SetStateAction<string>>;
  onBlockStep: (flag: boolean) => void;
  sessionsCount: number;
};

const InvitePatientStep: React.FC<InvitePatientStepProps> = ({
  setRoomUrl,
  setSessionId,
  onBlockStep,
  sessionsCount = 1,
}) => {
  const { setPatientToMeetId } = useContext(AppContext);
  const [selectedOption, setSelectedOption] = useState({ value: '', label: '' });
  const [isPatientDropdownOpen, setIsPatientDropdownOpen] = useState(false);
  const [isInviteFormLoading, setIvinteFormLoading] = useState(false);
  const [isTokenGenerating, setIsGeneratingToken] = useState(false);
  const [isPatientFormOpen, setIsPatientFormOpen] = useState(false);
  const [inviteFormError, setInviteFormError] = useState('');
  const [generatedLink, setGeneratedLink] = useState('');

  const [shortUrl, setShortUrl] = useState<string | null>(null);

  const { user } = useUser();
  const { id: userId } = user!;
  const { userMetadata, isLoading, refetch } = useUserMetadata(userId);
  let therapistPatients: any[] = [];

  if (userMetadata && 'patients' in userMetadata) {
    therapistPatients = userMetadata.patients as [];
  }

  const snapshot = useSelector(refaelaTherapistActor, (state) => state);

  const { currentUser, currentPatientId } = useSelector(
    refaelaTherapistActor,
    (snapshot) => snapshot.context
  );

  const handleSelectPatientChange = ({ value, label }: { value: string; label: string }) => {
    setPatientToMeetId(value);

    refaelaTherapistActor.send({
      type: 'currentPatientId.update',
      currentPatientId: value,
    });

    setSelectedOption({ value, label });
    setRoomUrl('');
    setGeneratedLink('');
  };

  const handleGenerateToken = async () => {
    setIsGeneratingToken(true);
    try {
      const selectedPatientId = selectedOption.value;

      const shortUrl = await generateSessionLink(
        selectedPatientId,
        snapshot.value == 'AssignAISession'
          ? SESSION_TYPES.AI_BIO_FEEDBACK
          : SESSION_TYPES.THERAPIST,
        sessionsCount
      );

      if (snapshot.value !== 'AssignAISession') {
        const { roomUrl, sessionId } = await createDailySession(userId, selectedPatientId);
        setRoomUrl(roomUrl);
        setSessionId(sessionId);
      }
      setIsPatientDropdownOpen(false);
      setShortUrl(shortUrl);
      setGeneratedLink(shortUrl);
    } catch (e) {
      console.error('Issue with token generation or short link:', JSON.stringify(e));
    } finally {
      setIsGeneratingToken(false);
    }
  };

  const handlePatientFormSubmit = async ({ newPatientData }: ShamefulAny) => {
    setIvinteFormLoading(true);
    const { firstName, lastName, patientEmail, phoneNumber } = newPatientData;

    try {
      if (
        Array.isArray(therapistPatients) &&
        therapistPatients.some((e: ShamefulAny) => e.email == patientEmail)
      ) {
        setInviteFormError('User with this email is already added');
        return;
      }

      await updatePatientsList(
        userId,
        THERAPIST_SCHEDULE_URL,
        patientEmail,
        phoneNumber,
        firstName,
        lastName,
        false
      );

      const { patients: updatedPatients } = await refetch();

      const updatedPatientId = getUpdatedPatientId(
        updatedPatients,
        therapistPatients as ShamefulAny
      );

      setGeneratedLink('');
      setIsPatientFormOpen(false);
      setSelectedOption({ value: updatedPatientId, label: `${firstName} ${lastName}` });
      setPatientToMeetId(updatedPatientId);

      refaelaTherapistActor.send({
        type: 'currentPatientId.update',
        currentPatientId: updatedPatientId,
      });
    } catch (e: ShamefulAny) {
      if (e.longMessage) {
        setInviteFormError(e.longMessage);
      } else {
        setInviteFormError(e.message);
      }
    } finally {
      setIvinteFormLoading(false);
    }
  };

  const handleInvitePatientModal = () => {
    setIsPatientDropdownOpen(false);
    setIsPatientFormOpen((prev) => !prev);
  };

  const handleInvitePatientModalClose = () => {
    setIsPatientFormOpen(false);
  };

  useEffect(() => {
    if (selectedOption.value && generatedLink) {
      onBlockStep(false);
    } else {
      onBlockStep(true);
    }
  }, [selectedOption.value, generatedLink]);
  const [status, setStatus] = useState('');
  const onEmailSend = async () => {
    if (!currentPatientId) {
      setStatus('No current patient ID available.');
      return;
    }

    const patient = findPatientById(currentUser?.patientList ?? [], currentPatientId);

    const emailHtml = linkToSessionEmailTemplate({
      recipientName: patient?.name,
      activationLink: shortUrl!,
      companyName: 'Betteryou',
    });

    const subject = 'Session Notification';
    const html = emailHtml;
    const text = 'test';
    const to = patient?.email;
    if (!to) {
      setStatus('No email, try SMS or copy link and send directly');
      return;
    }

    try {
      await sendEmail(to, subject, text, html);
      setStatus('Email sent successfully!');
    } catch {
      setStatus('Failed to send email.');
    }
  };

  const onSmsSend = async () => {
    if (!currentPatientId) {
      setStatus('No current patient ID available.');
      return;
    }

    const patient = findPatientById(currentUser?.patientList ?? [], currentPatientId);
    const message = `Remind you about BetterYou session. Link to join: ${shortUrl} `;
    const phoneNumber = patient?.phoneNumber;
    if (!phoneNumber) {
      setStatus('No phone number, try email or copy link and send directly');
      return;
    }

    try {
      await sendSms(phoneNumber, message);
      setStatus('SMS sent successfully!');
    } catch {
      setStatus('Failed to send SMS.');
    }
  };

  return (
    <>
      {isLoading ? (
        <div className="flex w-full justify-center">
          <Loader label="Loading list of patients..." />
        </div>
      ) : (
        <div className="w-full text-left mb-8">
          <div className="flex">
            <div className="flex flex-col gap-y-3">
              <p
                className={snapshot.value == 'AssignAISession' ? 'font-bold' : 'text-text-subtext'}
              >
                Select a patient
              </p>
              <div className="flex space-x-4 items-center ">
                <SimpleSelect
                  placeholder="Choose patient"
                  dropdownEmptyListPlaceholder="No patients invited yet"
                  isOpen={isPatientDropdownOpen}
                  selectClass="w-60"
                  selectOptionClass="h-6 text-text-subtext"
                  selectDropdownClass="w-60 overflow-y-auto max-h-[20rem] max-w-[15rem]"
                  selectedOption={selectedOption}
                  renderValue={(placeholder) => (
                    <SelectedPatientOption
                      label={selectedOption.label}
                      placeholder={placeholder}
                    />
                  )}
                  onChange={handleSelectPatientChange}
                >
                  {Array.isArray(therapistPatients) &&
                    therapistPatients.map((patient, index) => {
                      return (
                        <SelectOption
                          key={index}
                          value={patient.id}
                        >
                          <PatientOption patient={patient}>
                            {patient.firstName && patient.lastName
                              ? `${patient.firstName} ${patient.lastName}`
                              : 'Anonymous'}
                          </PatientOption>
                        </SelectOption>
                      );
                    })}
                </SimpleSelect>
                <span>or</span>
                <Button
                  className="w-fit"
                  onClick={handleInvitePatientModal}
                  text="Create a new patient"
                />
              </div>
              {generatedLink && (
                <div className="w-60">
                  <TextInputController
                    id="generated-link"
                    value={generatedLink}
                    disabled
                  />
                </div>
              )}
              <div className="flex flex-col gap-9">
                <div className="flex mt-1 space-x-4 items-center">
                  <Button
                    disabled={!selectedOption.value}
                    text="Generate a link"
                    className="meeting-button"
                    onClick={handleGenerateToken}
                  />
                  <div className="flex items-center">
                    {isTokenGenerating && (
                      <Loader
                        label="Generating link..."
                        className="justify-between w-48"
                      />
                    )}
                    {!isTokenGenerating && generatedLink && (
                      <div className="flex flex-col">
                        <CopyButton
                          label={'Copy Joining Info'}
                          value={shortUrl}
                        />
                      </div>
                    )}
                  </div>
                </div>
                {!isTokenGenerating && generatedLink && currentPatientId && (
                  <div className="flex flex-col gap-3">
                    <span className="text-gray-900 text-base font-medium font-['Inter'] leading-normal">
                      Send this link instantly
                    </span>
                    <div className="flex gap-3">
                      <Button
                        variant="primary"
                        text="Send email"
                        onClick={onEmailSend}
                        disabled={
                          !findPatientById(currentUser?.patientList ?? [], currentPatientId)?.email
                        }
                      />
                      <Button
                        variant="primary"
                        text="Send SMS"
                        onClick={onSmsSend}
                        disabled={
                          !findPatientById(currentUser?.patientList ?? [], currentPatientId)
                            ?.phoneNumber
                        }
                      />
                    </div>
                    <span className="text-slate-500 text-sm font-medium font-['Inter'] ">
                      {status}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          {isPatientFormOpen && (
            <Modal
              isOpen={isPatientFormOpen}
              title="Invite patient"
              onCancel={handleInvitePatientModalClose}
            >
              <InvitePatientForm
                isLoading={isInviteFormLoading}
                onCancel={handleInvitePatientModalClose}
                onInvite={handlePatientFormSubmit}
                error={inviteFormError}
              />
            </Modal>
          )}
        </div>
      )}
    </>
  );
};

export default InvitePatientStep;
