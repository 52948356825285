import { useRef } from 'react';
// Hooks
import useResizable from '@hooks/useResizable';
import { useScrollTop } from '@hooks/useScrollTop';
import ResizeHandle from '../../../modules/Patient/AISession/ui/ResizeHandler';
import { processTranscriptToUrl } from '../utils/processTranscript';

type ConversationModelTranscriptsProps = {
  transcripts: any;
};

const SelfReviewRealtimeTranscripts = ({ transcripts = [] }: ConversationModelTranscriptsProps) => {
  const eventsScrollRef = useRef<HTMLDivElement>(null);
  const eventsScrollHeightRef = useRef(0);

  useScrollTop(eventsScrollRef, eventsScrollHeightRef, transcripts);

  const { height, handleMouseDown } = useResizable(101);

  const filteredTranscripts = transcripts.filter((item: any) => {
    const text = item.formatted.text;
    return !(text?.startsWith('user_') || text?.startsWith('session_'));
  });

  return (
    <div
      style={{ height: `${height}px` }}
      className="w-full bg-white rounded-[5px] border border-gray-300 text-[#6e6e7f] relative flex-grow pt-1 leading-[1.2em]"
      data-conversation-content
    >
      <div
        ref={eventsScrollRef}
        className="p-2 overflow-x-hidden overflow-y-auto h-full"
      >
        {!filteredTranscripts.length && 'awaiting connection...'}
        {filteredTranscripts.map((transcriptItem: any, index: number) => {
          const audioData: number[] =
            transcriptItem.formatted?.audio &&
            (Object.values(transcriptItem.formatted?.audio) as number[]);
          const audioUrl = processTranscriptToUrl(audioData);

          return (
            <div
              className="conversation-item"
              key={index}
            >
              <div className={`speaker ${transcriptItem.role || ''}`}>
                <div>
                  {((transcriptItem.role as string) || (transcriptItem.type as string)).replace(
                    /_/g,
                    ' '
                  )}
                </div>
              </div>
              <div className={`speaker-content`}>
                {/* tool response */}
                {transcriptItem.type === 'function_call_output' && (
                  <div>{transcriptItem.formatted.output}</div>
                )}
                {/* tool call */}
                {!!transcriptItem.formatted.tool && (
                  <div>
                    {transcriptItem.formatted.tool.name}({transcriptItem.formatted.tool.arguments})
                  </div>
                )}
                {!transcriptItem.formatted.tool && transcriptItem.role === 'user' && (
                  <div>
                    {transcriptItem.formatted.transcript ||
                      (transcriptItem.formatted.audio?.length
                        ? '(awaiting transcript)'
                        : transcriptItem.formatted.text || '(item sent)')}
                  </div>
                )}
                {!transcriptItem.formatted.tool && transcriptItem.role === 'assistant' && (
                  <div>
                    {transcriptItem.formatted.transcript ||
                      transcriptItem.formatted.text ||
                      '(truncated)'}
                  </div>
                )}
                {audioUrl && (
                  <audio
                    src={audioUrl}
                    controls
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
      <ResizeHandle
        className="sticky bottom-0"
        onMouseDown={handleMouseDown}
      />
    </div>
  );
};

export default SelfReviewRealtimeTranscripts;
