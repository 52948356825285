import { memo, useMemo, useRef } from 'react';
import Uplot from 'uplot';
import UplotReact from 'uplot-react';
import groupBy from 'lodash/groupBy';

import {
  calculateDurations,
  renderRectangles,
} from './../../../features/Plot/model/utils/speakerActivation';
import useResizeObserver from './../../../features/Plot/model/hooks/useResizeObserver';

// TODO: figure out how to stream ws data into components not by props drilling / or state machine
const SelfReviewSpeakerActivationComponent = ({ speakerActivationData }: any) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const { width = 400, height = 150 } = useResizeObserver(containerRef);

  // Group and process events
  const userData = groupBy(
    speakerActivationData?.filter(
      (ev: any) =>
        ((ev.event.item_id || ev.event.item?.item_id) &&
          ev.event.type === 'input_audio_buffer.speech_started') ||
        ev.event.type === 'input_audio_buffer.speech_stopped'
    ),
    (ev: any) => ev.event.item_id
  );

  const AIData = groupBy(
    speakerActivationData?.filter(
      (ev: any) =>
        ev.role === 'assistant' &&
        ev.source === 'server' &&
        (ev.event.item_id || ev.event.item?.item_id)
    ),
    (ev: any) => ev.event.item_id || ev.event.item?.id
  );
  const chartData = useMemo(() => {
    return {
      userDurations: calculateDurations(userData),
      assistantDurations: calculateDurations(AIData),
    };
  }, [userData, AIData]);

  const mergedTimestamps = useMemo(
    () => [
      ...chartData.userDurations.flatMap((d: any) => [d.start, d.end]),
      ...chartData.assistantDurations.flatMap((d: any) => [d.start, d.end]),
    ],
    [chartData]
  );

  const data = useMemo(() => {
    return [
      mergedTimestamps?.length ? mergedTimestamps : [0], // x-axis timestamps
      mergedTimestamps?.length ? Array(mergedTimestamps.length).fill(null) : [null], // y-axis placeholder
    ] as Uplot.AlignedData;
  }, [mergedTimestamps]);

  // Chart options
  const options: Uplot.Options = useMemo(
    () => ({
      width,
      height,
      padding: [0, 0, 0, 0],
      scales: {
        x: { time: true, auto: true },
        y: { auto: true, min: 0.5, max: 2.5 },
      },
      series: [{}, { stroke: 'transparent' }],
      axes: [
        {
          stroke: '#9ca3af',
          grid: { show: true, stroke: '#e5e7eb' },
          values: (_, ticks) => ticks.map((tick) => new Date(tick).toLocaleTimeString()),
        },
        {
          grid: { show: true, stroke: '#e5e7eb' },
          scale: 'y',
          values: (_, ticks) => ticks.map((val) => (val === 1 ? 'User' : val === 2 ? 'AI' : '')),
        },
      ],
      legend: { show: false },
      hooks: {
        draw: [
          (u) => {
            const ctx = u.ctx;
            renderRectangles(ctx, chartData.assistantDurations, u, 2, '#3b82f6', -7); // AI rectangles
            renderRectangles(ctx, chartData.userDurations, u, 1, '#9333ea', -7); // User rectangles
          },
        ],
      },
    }),
    [width, height, chartData]
  );

  return (
    <div
      ref={containerRef}
      className="w-full h-[150px]"
    >
      <UplotReact
        data={data}
        options={options}
      />
    </div>
  );
};

export default memo(SelfReviewSpeakerActivationComponent);
