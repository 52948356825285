import { USER_ROLES } from '@interfaces/user';
import { apiClient } from '..';

export const fetchPatientRecordingData = async (
  patientId: string,
  sessionId: string,
  therapistId: string,
  maxRetries: number = 10,
  retryDelay: number = 10000,
  withRefetch: boolean,
  setIsLoadingState?: React.Dispatch<React.SetStateAction<Record<string, any>>>
): Promise<any> => {
  setIsLoadingState?.((prev) => ({
    ...prev,
    recording: {
      isLoading: true,
      isFailed: false,
      error: null,
      data: null,
    },
  }));
  for (let attempt = 1; attempt <= maxRetries; attempt++) {
    try {
      const response = await apiClient.get(`/video_session/patient/${sessionId}/recording`, {
        params: {
          patientId: patientId,
          therapistId: therapistId,
          cacheBuster: new Date().getTime(),
          role: USER_ROLES.PATIENT,
          withRefetch: withRefetch,
        },
      });

      if (response.status === 200) {
        const data = response.data;
        setIsLoadingState?.((prev) => ({
          ...prev,
          recording: { isLoading: false, isFailed: false, error: null, data },
        }));
      } else if (response.status === 503) {
        if (attempt < maxRetries) {
          setIsLoadingState?.((prev) => ({
            ...prev,
            recording: {
              isLoading: true,
              isFailed: true,
              info: `Attempt ${attempt}: Video not yet available. Retrying in ${retryDelay}ms...`,
            },
          }));
          await new Promise((resolve) => setTimeout(resolve, retryDelay));
        } else {
          const data = response.data;
          setIsLoadingState?.((prev) => ({
            ...prev,
            recording: {
              isLoading: false,
              isFailed: true,
              info: `Attempt ${attempt}: Video not yet available. Retrying in ${retryDelay}ms...`,
              error: `Max retries reached. Video is still not fully available ${JSON.stringify(data)}`,
            },
          }));
        }
      } else {
        console.log(`Unexpected response status: ${response.status}`);
        // throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error: any) {
      if (attempt === maxRetries) {
        console.error('Final error after retries:', error.message);
        setIsLoadingState?.((prev) => ({
          ...prev,
          recording: {
            isLoading: false,
            isFailed: true,
            error: `Final error after retries: ${error.message}; ${JSON.stringify(error.details)}`,
          },
        }));
        console.log('Failed to fetch patient session data after multiple attempts.');
        // throw new Error('Failed to fetch patient session data after multiple attempts.');
      } else {
        setIsLoadingState?.((prev) => ({
          ...prev,
          recording: {
            isLoading: true,
            isFailed: false,
            info: `Retrying... ${attempt}/${maxRetries}`,
            error: null,
          },
        }));

        await new Promise((resolve) => setTimeout(resolve, retryDelay));
      }
    }
  }
};
export const fetchTherapistRecordingData = async (
  patientId: string,
  sessionId: string,
  therapistId: string,
  maxRetries: number = 10,
  retryDelay: number = 10000,
  withRefetch: boolean,
  setIsLoadingState?: React.Dispatch<React.SetStateAction<Record<string, any>>>
): Promise<any> => {
  setIsLoadingState?.((prev) => ({
    ...prev,
    therapistRecording: {
      isLoading: true,
      isFailed: false,
      error: null,
      data: null,
    },
  }));
  for (let attempt = 1; attempt <= maxRetries; attempt++) {
    try {
      const response = await apiClient.get(`/video_session/therapist/${sessionId}/recording`, {
        params: {
          patientId: patientId,
          therapistId: therapistId,
          cacheBuster: new Date().getTime(),
          role: USER_ROLES.THERAPIST,
          withRefetch: withRefetch,
        },
      });

      if (response.status === 200) {
        const data = response.data;
        setIsLoadingState?.((prev) => ({
          ...prev,
          therapistRecording: { isLoading: false, isFailed: false, error: null, data },
        }));
      } else if (response.status === 503) {
        if (attempt < maxRetries) {
          setIsLoadingState?.((prev) => ({
            ...prev,
            therapistRecording: {
              isLoading: true,
              isFailed: true,
              info: `Attempt ${attempt}: Video not yet available. Retrying in ${retryDelay}ms...`,
            },
          }));
          await new Promise((resolve) => setTimeout(resolve, retryDelay));
        } else {
          const data = response.data;
          setIsLoadingState?.((prev) => ({
            ...prev,
            therapistRecording: {
              isLoading: false,
              isFailed: true,
              info: `Attempt ${attempt}: Video not yet available. Retrying in ${retryDelay}ms...`,
              error: `Max retries reached. Video is still not fully available ${JSON.stringify(data)}`,
            },
          }));
        }
      } else {
        console.log(`Unexpected response status: ${response.status}`);
        // throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error: any) {
      if (attempt === maxRetries) {
        console.error('Final error after retries:', error.message);
        setIsLoadingState?.((prev) => ({
          ...prev,
          therapistRecording: {
            isLoading: false,
            isFailed: true,
            error: `Final error after retries: ${error.message}; ${JSON.stringify(error.details)}`,
          },
        }));
        console.log('Failed to fetch patient session data after multiple attempts.');
        // throw new Error('Failed to fetch patient session data after multiple attempts.');
      } else {
        setIsLoadingState?.((prev) => ({
          ...prev,
          therapistRecording: {
            isLoading: true,
            isFailed: false,
            info: `Retrying... ${attempt}/${maxRetries}`,
            error: null,
          },
        }));

        await new Promise((resolve) => setTimeout(resolve, retryDelay));
      }
    }
  }
};
