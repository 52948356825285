import { ShamefulAny } from '@interfaces/index';
import { apiClient } from '..';

export async function getAiSessionRealtimeData(
  userId: string,
  sessionId: string
): Promise<ShamefulAny> {
  try {
    const params = {
      userId,
      sessionId,
    };

    const { data }: { data: { events: any[]; agents: any[]; transcripts: any[] } } =
      await apiClient.get('/ai/realtimeData', { params });

    return data;
  } catch (e: ShamefulAny) {
    console.error('Error fetching realtime data:', e.message, e.details);

    return {
      error: true,
      message: `${e.message}: ${e.details || 'No additional details'}`,
    };
  }
}
