import { useEffect, useState } from 'react';
import { Mic } from 'lucide-react';
import { SelectOption, SimpleSelect } from '@shared/ui/input/select/SimpleSelect';
import { useMediaDevicePermissionGranted } from '@hooks/useMediaDevicePermissionGranted';

const MicrophoneSelector = ({
  setMicrophone,
  microphone,
  selectClass,
  selectDropdownClass,
  manualDropdownPosition,
}: {
  setMicrophone: (x: string) => void;
  microphone: string | null;
  selectClass?: string;
  selectDropdownClass?: string;
  manualDropdownPosition?: boolean;
}) => {
  const [audioDevicesList, setAudioDevicesList] = useState<MediaDeviceInfo[]>([]);
  const [selectedMicOption, setSelectedMicOption] = useState({ value: '', label: '' });

  const permissionGranted = useMediaDevicePermissionGranted('microphone');

  useEffect(() => {
    if (permissionGranted) {
      navigator.mediaDevices.enumerateDevices().then((res) => {
        const audioDeviceList = res?.filter((el) => {
          return el.kind === 'audioinput';
        });
        setAudioDevicesList(audioDeviceList);
      });
    }
  }, [permissionGranted]);

  useEffect(() => {
    if (audioDevicesList.length > 0) {
      const currentDevice = audioDevicesList.find(
        (device: MediaDeviceInfo) => device.deviceId === microphone
      );
      microphone
        ? setSelectedMicOption({
            value: microphone,
            label: currentDevice?.label || 'Default microphone device',
          })
        : setSelectedMicOption({
            value: audioDevicesList[0].deviceId,
            label: audioDevicesList[0].label,
          });
    }
  }, [audioDevicesList]);

  const handleMicrophoneChange = ({ value, label }: Record<string, string>) => {
    setSelectedMicOption({ value, label });
    setMicrophone(value);
    localStorage.setItem('microphoneId', value);
  };

  return (
    <SimpleSelect
      withSearch={false}
      selectClass={`w-[13rem] ${selectClass}`}
      selectDropdownClass={`w-[15rem] overflow-y-auto max-h-[20rem] max-w-[15rem] ${selectDropdownClass}`}
      selectedOption={selectedMicOption}
      manualDropdownPosition={manualDropdownPosition}
      renderValue={(placeholder) => {
        return (
          <div className="flex overflow-hidden items-center">
            <div className="mr-2 w-5">
              <Mic
                size={20}
                strokeWidth={1.5}
              />
            </div>
            <div className="text-ellipsis truncate">{selectedMicOption.label || placeholder}</div>
          </div>
        );
      }}
      placeholder="Select microphone device"
      onChange={handleMicrophoneChange}
    >
      {audioDevicesList.map((device: MediaDeviceInfo, index) => {
        return (
          <SelectOption
            key={index}
            value={device.deviceId}
          >
            {device.label}
          </SelectOption>
        );
      })}
    </SimpleSelect>
  );
};

export default MicrophoneSelector;
