import { useRef, useState } from 'react';
import { ArrowDown, ArrowUp } from 'react-feather';
import { formatEventTime } from '../../../modules/Patient/AISession/model/utils/formatTime';
import { useScrollTop } from '@hooks/useScrollTop';
import useResizable from '@hooks/useResizable';
import ResizeHandle from '../../../modules/Patient/AISession/ui/ResizeHandler';

type RealtimeEventsProps = {
  events: any;
  startTime: string;
};

const SelfReviewConversationModelEvents = ({
  events = [],
  startTime = '',
}: RealtimeEventsProps) => {
  const [expandedEvents, setExpandedEvents] = useState<{ [key: string]: boolean }>({});

  const eventsScrollRef = useRef<HTMLDivElement>(null);
  const eventsScrollHeightRef = useRef(0);

  const { height, handleMouseDown } = useResizable(101);

  useScrollTop(eventsScrollRef, eventsScrollHeightRef, events);

  return (
    <div
      className="w-full bg-white rounded-[5px] border border-gray-300 text-[#6e6e7f] relative flex-grow pt-1 leading-[1.2em]"
      style={{ height: `${height}px` }}
    >
      <div
        ref={eventsScrollRef}
        className="p-2 overflow-x-hidden overflow-y-auto h-full"
      >
        {!events?.length && 'awaiting connection...'}
        {events?.map((modelEvent: any, i: number) => {
          const count = modelEvent.count;
          const event = { ...modelEvent.event };

          if (event.type === 'input_audio_buffer.append') {
            event.audio = `[trimmed: ${event.audio.length} bytes]`;
          } else if (event.type === 'response.audio.delta') {
            event.delta = `[trimmed: ${event.delta.length} bytes]`;
          }

          return (
            <div
              key={`${event.event_id}-${i}`}
              className="event"
            >
              <div className="event-timestamp">
                {formatEventTime(
                  new Date(modelEvent.timestamp).toISOString(),
                  new Date(Number(startTime)).toISOString()
                )}
              </div>
              <div className="event-details">
                <div
                  className="event-summary"
                  onClick={() => {
                    const id = event.event_id;
                    const expanded = { ...expandedEvents };
                    if (expanded[id]) {
                      delete expanded[id];
                    } else {
                      expanded[id] = true;
                    }
                    setExpandedEvents(expanded);
                  }}
                >
                  <div
                    className={`event-source ${event.type === 'error' ? 'error' : modelEvent.role}`}
                  >
                    {modelEvent.role === 'user' ? <ArrowUp /> : <ArrowDown />}
                    <span>{event.type === 'error' ? 'error!' : modelEvent.role}</span>
                  </div>
                  <div className="event-type">
                    {event.type}
                    {count && ` (${count})`}
                  </div>
                </div>
                {!!expandedEvents[event.event_id] && (
                  <div className="event-payload">{JSON.stringify(event, null, 2)}</div>
                )}
              </div>
            </div>
          );
        })}
      </div>
      <ResizeHandle
        className="sticky bottom-0"
        onMouseDown={handleMouseDown}
      />
    </div>
  );
};

export default SelfReviewConversationModelEvents;
