import { Loader } from '@shared/ui/loader/Loader';
import FramesComponent from '../FramesComponent/FramesComponent';

import { useState, useEffect } from 'react';
import { CirclePauseIcon, CirclePlayIcon } from 'lucide-react';
import { ShamefulAny } from '@interfaces/index';
import Checkbox from '@shared/ui/input/checkbox/Checkbox';

interface VideoPlayerComponentProps {
  showFrames: boolean;
  framesState?: Record<string, unknown>;
  framesData: {
    timestamp: number;
    link: string;
  }[];

  recordingTimeFrame: {
    recordingStartTime: number | undefined;
    recordingStopTime: number | undefined;
  };
  currentVideoTime: number;
  isPlaying: boolean;
  setIsPlaying: (x: boolean) => void;
  startOrPauseTimeUpdate: () => void;
  isLoading: boolean;
  handleSwitchToVideo: () => void;
  videoSrc: string | null;
  videoDifference?: {
    recordingStartsFirst: 'therapistRecording' | 'patientRecording';
    differenceBetweenStarts: number;
  } | null;
  intervalId: ShamefulAny;
  isFailed: boolean;
  error: string | null;

  videoRef: React.RefObject<HTMLVideoElement>;
}

const VideoPlayerComponent: React.FC<VideoPlayerComponentProps> = ({
  showFrames,
  framesState,
  videoDifference,
  framesData,
  recordingTimeFrame,
  currentVideoTime,
  isPlaying,
  startOrPauseTimeUpdate,
  intervalId,
  isLoading,
  handleSwitchToVideo,
  setIsPlaying,
  videoSrc,

  isFailed,
  error,

  videoRef,
}) => {
  const [showVideo, setShowVideo] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const [isPatientVideoPrivate, setIsPatientVideoPrivate] = useState<boolean>(false);

  const handleMakePatientVideoPrivate = (value: boolean) => {
    setIsPatientVideoPrivate(value);
  };
  useEffect(() => {
    if (!isLoading && videoSrc) {
      setShowVideo(true);

      // Clear the frame-based interval when switching to video
      if (intervalId.current !== null) {
        clearInterval(intervalId.current);
        intervalId.current = null;
      }

      setIsPlaying(false);
      handleSwitchToVideo();
    }
  }, [isLoading, videoSrc]);

  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  useEffect(() => {
    const handleResize = () => setViewportHeight(window.innerHeight);
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const containerStyle = {
    maxHeight: viewportHeight <= 950 ? '300px' : '330px',
  };

  return (
    <div className="flex  rounded-[7px]">
      <div className="flex relative">
        {!showVideo && showFrames && (
          <>
            {framesState?.isLoading ? (
              <div className="flex flex-col">
                <Loader
                  flexDirection="flex-col"
                  label="Session video is loading..."
                  className="w-full h-full self-center items-center justify-center"
                />
              </div>
            ) : (
              <div
                className="h-full w-full max-w-[700px] max-h-[330px]  flex relative"
                style={containerStyle}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                <FramesComponent
                  framesData={framesData}
                  recordingTimeFrame={recordingTimeFrame}
                  currentTime={currentVideoTime}
                />

                {isHovered && (
                  <div
                    className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center cursor-pointer z-20"
                    onClick={startOrPauseTimeUpdate}
                  >
                    <button className="text-white text-2xl p-4 bg-opacity-60 bg-black rounded-full">
                      {isPlaying ? (
                        <CirclePauseIcon
                          color="white"
                          width={'100px'}
                          height={'100px'}
                          strokeWidth={'1px'}
                        />
                      ) : (
                        <CirclePlayIcon
                          color="white"
                          width={'100px'}
                          height={'100px'}
                          strokeWidth={'1px'}
                        />
                      )}
                    </button>
                  </div>
                )}
              </div>
            )}
          </>
        )}

        {showVideo && videoSrc && !isLoading && (
          <div
            className="relative h-full w-full max-w-[700px] max-h-[330px] "
            style={containerStyle}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <div
              className={`  h-full w-full ${isPatientVideoPrivate ? 'blur-md bg-white/30 z-10' : 'blur-none bg-none'}`}
            >
              <video
                preload="auto"
                controls={false}
                className="h-full w-full"
                ref={videoRef}
                onLoadedData={() => {
                  if (videoRef.current && videoDifference) {
                    videoRef.current.currentTime = videoDifference.differenceBetweenStarts / 1000;
                  }
                }}
              >
                <source
                  src={videoSrc}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="absolute w-[137px] h-8 bg-neutral-50 rounded-md flex z-30 bottom-3 left-3 justify-center">
              <Checkbox
                key="therapist-checkbox"
                className="mt-0 self-center text-gray-800 text-xs"
                label="Make private"
                checked={isPatientVideoPrivate}
                onChange={handleMakePatientVideoPrivate}
              />
            </div>
            {isHovered && (
              <div
                className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center cursor-pointer z-20"
                onClick={startOrPauseTimeUpdate}
              >
                <button className="text-white text-2xl p-4 bg-opacity-60 bg-black rounded-full">
                  {isPlaying ? (
                    <CirclePauseIcon
                      color="white"
                      width={'100px'}
                      height={'100px'}
                      strokeWidth={'1px'}
                    />
                  ) : (
                    <CirclePlayIcon
                      color="white"
                      width={'100px'}
                      height={'100px'}
                      strokeWidth={'1px'}
                    />
                  )}
                </button>
              </div>
            )}
          </div>
        )}

        {!videoSrc && !isLoading && isFailed === false && (
          <div
            className="h-full flex flex-col items-center justify-center w-full bg-slate-200 rounded-lg max-w-[504px] max-h-[330px]"
            style={containerStyle}
          >
            No video loaded. {error && <span className="text-rose-400">{error}</span>}
          </div>
        )}
      </div>
    </div>
  );
};

export default VideoPlayerComponent;
