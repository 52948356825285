type ProgressBarProps = {
  color?: string;
  progress: number;
};

const ProgressBar = ({ color = '#9333EA', progress = 0 }: ProgressBarProps) => {
  return (
    <div className="p-4">
      <div className="w-full h-2 bg-gray-200 rounded-full overflow-hidden">
        <div
          className="h-full"
          style={{
            width: `${progress}%`,
            backgroundColor: progress === 100 ? '#9CA3AF' : color,
            transition: 'width 1s ease-out',
          }}
        />
      </div>
      <div className="mt-2 text-sm text-gray-600 w-16"></div>
    </div>
  );
};

export default ProgressBar;
