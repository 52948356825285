import AssignAISession from '../AssignAISession/AssignAISession';

export const AssignAISessionWrapper = () => {
  return (
    <div className="w-full h-full text-left">
      <h1 className="font-bold text-4xl my-8">Assign AI Session</h1>
      <AssignAISession />
    </div>
  );
};
