import { apiClient } from '.';
import { Base64 } from '../hooks/useExtractFaceEmotions';

export interface ISendCameraFrameResponseBody {
  frame_url: string;
  message: string;
  timestamp: number;
}

const frameFallback = { frame_url: '', message: '', timestamp: 0 };

export const sendCameraFrame = async (
  userId: string,
  base64: Base64,
  timestamp: number | null = null
): Promise<ISendCameraFrameResponseBody> => {
  try {
    const response = await apiClient.post<ISendCameraFrameResponseBody>(
      `/frame/${userId}`,
      {
        base64,
        timestamp,
      },
      {
        headers: {
          authorization: import.meta.env.VITE_VF_DM_API_KEY,
        },
      }
    );

    return response.data;
  } catch (e) {
    (e as any).sentryHandled = true;
    console.error('Error in method sendCameraFrame', JSON.stringify(e));
    //TODO: valid fallback that doesn't break calculations
    return frameFallback;
  }
};

export const sendAICameraFrame = async (
  userId: string,
  sessionId: string,
  base64: Base64,
  timestamp: number | null = null,
  signal?: AbortSignal
): Promise<ISendCameraFrameResponseBody> => {
  try {
    const response = await apiClient.post<ISendCameraFrameResponseBody>(
      `/frame/ai/${userId}`,
      {
        base64,
        timestamp,
        sessionId,
      },
      {
        headers: {
          authorization: import.meta.env.VITE_VF_DM_API_KEY,
        },
        signal,
      }
    );

    return response.data;
  } catch (e) {
    (e as any).sentryHandled = true;
    console.error('Error in method sendAICameraFrame', JSON.stringify(e));
    //TODO: valid fallback that doesn't break calculations
    return frameFallback;
  }
};

export const sendCameraFrameTherapist = async (
  userId: string,
  patientId: string,
  sessionId: string,
  base64: Base64,
  timestamp: number | null = null
): Promise<ISendCameraFrameResponseBody> => {
  try {
    const response = await apiClient.post<ISendCameraFrameResponseBody>(
      `/frame/therapist/${userId}`,
      {
        base64,
        timestamp,
        patientId,
        sessionId,
      },
      {
        headers: {
          authorization: import.meta.env.VITE_VF_DM_API_KEY,
        },
      }
    );

    return response.data;
  } catch (e) {
    (e as any).sentryHandled = true;
    console.error('Error in method sendCameraFrameTherapist', JSON.stringify(e));
    //TODO: valid fallback that doesn't break calculations
    return frameFallback;
  }
};

export const sendCameraFramePatient = async (
  userId: string,
  sessionId: string,
  base64: Base64,
  timestamp: number | null = null
): Promise<ISendCameraFrameResponseBody> => {
  try {
    const response = await apiClient.post<ISendCameraFrameResponseBody>(
      `/frame/patient/${userId}`,
      {
        base64,
        timestamp,
        sessionId,
      },
      {
        headers: {
          authorization: import.meta.env.VITE_VF_DM_API_KEY,
        },
      }
    );

    return response.data;
  } catch (e) {
    (e as any).sentryHandled = true;
    console.error('Error in method sendCameraFramePatient', JSON.stringify(e));
    //TODO: valid fallback that doesn't break calculations
    return frameFallback;
  }
};

export async function getFrames(
  role: string,
  patientId: string,
  therapistId: string,
  sessionId: string
): Promise<any> {
  try {
    const params = {
      role,
      sessionId,
      patientId,
      therapistId,
    };

    const { data } = await apiClient.get('/frame', { params });

    return data;
  } catch (e: any) {
    (e as any).sentryHandled = true;
    console.error('Error fetching frames:', e.message, e.details);

    return {
      error: true,
      message: `${e.message}: ${e.details || 'No additional details'}`,
    };
  }
}
