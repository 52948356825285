import Button from '@components/Button';
import { CopyIcon } from 'lucide-react';
import { useState } from 'react';

interface CopyButtonProps {
  label: string;
  value: string | null;
  variant?: 'default' | 'button';
}

const CopyButton = ({ label, value, variant = 'default' }: CopyButtonProps) => {
  const [isCopyClicked, setIsCopyClicked] = useState(false);

  const copySessionReviewLink = () => {
    navigator.clipboard.writeText(value || '');
    setIsCopyClicked(true);
    setTimeout(() => {
      setIsCopyClicked(false);
    }, 3000);
  };

  return (
    <>
      {variant === 'default' && (
        <div
          className="cursor-pointer font-bold"
          onClick={copySessionReviewLink}
        >
          <CopyIcon className="inline mr-2" />
          {isCopyClicked ? 'Copied!' : label}
        </div>
      )}
      {variant === 'button' && (
        <Button
          variant="outlinedPrimary"
          onClick={copySessionReviewLink}
          text={isCopyClicked ? 'Copied!' : label}
        />
      )}
    </>
  );
};

export default CopyButton;
