import { setup, assign, createActor } from 'xstate';

interface Context {
  cameraId: string;
  microphoneId: string;
  isDefaultHeaderHidden?: boolean;
}

type Events =
  | { type: 'CAMERA_ID_UPDATE'; id: string }
  | { type: 'MICROPHONE_ID_UPDATE'; id: string }
  | { type: 'HEADER_HIDDEN_UPDATE'; isHeaderHidden: boolean };

const appMachine = setup({
  types: {
    context: {} as Context,
    events: {} as Events,
  },
}).createMachine({
  id: 'APP_MACHINE',
  initial: 'default',
  context: {
    cameraId: '',
    microphoneId: '',
    isDefaultHeaderHidden: false,
  },
  states: {
    default: {
      on: {
        CAMERA_ID_UPDATE: {
          actions: assign({
            cameraId: ({ event }) => (event as { id: string }).id,
          }),
        },
        MICROPHONE_ID_UPDATE: {
          actions: assign({
            microphoneId: ({ event }) => (event as { id: string }).id,
          }),
        },
        HEADER_HIDDEN_UPDATE: {
          actions: assign({
            isDefaultHeaderHidden: ({ event }) =>
              (event as { isHeaderHidden: boolean }).isHeaderHidden,
          }),
        },
      },
    },
  },
});

export const appActor = createActor(appMachine, {});
