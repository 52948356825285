import throttle from 'lodash/throttle';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { InitializationSettings, ShenaiSDK } from '../../../../../public/shenai-sdk/index'; // import from local
import { useShenaiSdk } from '@hooks/useShenaiSdk';
import { delay } from '@utils/helpers';
import { AppContext } from '../../../../contextApp';
import { EventLogType } from '@models/eventLog';
import { dashboardActor } from '../xstate/dashboardMachine';
import { ShenaiSdkBaseState } from '@shared/types/shenai/sdk';
import { rateFacePosition } from '@components/RealtimeDashboard/agents/utils';
import { ShamefulAny } from '@interfaces/index';
import { useWebSocket } from './../../../../websocket';
import { getCameraFrame } from '@services/media';
import { blobToBase64 } from '@utils/blob';

const SHENAI_API_KEY = import.meta.env.VITE_VF_SHENAI_SDK_API_KEY;

const getCameraFrameData = async () => {
  const blob = await getCameraFrame({ video: true });

  const base64 = await blobToBase64(blob as Blob);
  const timestamp = Date.now();
  return { base64, timestamp };
};

const getIsFaceOk = (saState?: ShenaiSdkBaseState | null, saSDK?: ShenaiSDK | null): boolean => {
  if (!saState || !saSDK) {
    return false;
  }

  switch (saState.faceState) {
    case saSDK.FaceState.OK:
    case saSDK.FaceState.TOO_CLOSE:
    case saSDK.FaceState.TOO_FAR:
    case saSDK.FaceState.NOT_CENTERED:
      return true;
    default:
      return false;
  }
};

export const useShenai = (
  componentName: string,
  isLogsSending?: boolean,
  //@ts-ignore
  userId?: string,
  sessionId?: string
) => {
  const { addEventLog } = useContext(AppContext);
  const shenaiSDKRef = useRef<any | null>(null);

  const [sdkData, setSdkData] = useState<any>({});

  const [cameraId, setCameraId] = useState('');

  const [isSDKInitialized, setIsSDKInitialized] = useState(false);
  const [isShenAICalibrated, setIsShenAICalibrated] = useState(false);
  const [isShenAIMeasurementStarted, setShenAIMeasurementStarted] = useState(false);
  const [initializationSettings, setInitializationSettings] = useState<InitializationSettings>();

  const shenaiSDK = useShenaiSdk();

  shenaiSDKRef.current = shenaiSDK;

  const { socket } = useWebSocket();

  const fetchData = async () => {
    if (isSDKInitialized && shenaiSDKRef.current) {
      const sdk = shenaiSDKRef.current;
      const timestamp = Date.now();
      const newData = {
        heartRate: sdk.getRealtimeHeartRate(),
        signalQuality: sdk.getCurrentSignalQualityMetric(),
        measurementProgress: sdk.getMeasurementProgressPercentage(),
        isInitialized: sdk.isInitialized(),
        faceState: sdk.getFaceState(),
        hr4s: sdk.getHeartRate4s(),
        hr10s: sdk.getHeartRate10s(),
        cardiacStressRT: sdk.getRealtimeCardiacStress(),
        hrvSDNN: sdk.getRealtimeHrvSdnn(),
        bbox: sdk.getNormalizedFaceBbox(),
        measurementState: sdk.getMeasurementState(),
        badSignal: sdk.getTotalBadSignalSeconds(),
        facePose: sdk.getFacePose(),
        results: sdk.getMeasurementResults(),
        realtimeMetrics: sdk.getRealtimeMetrics(10),
        realtimeHeartbeats: sdk.getRealtimeHeartbeats(100),
        visibilityState: document.visibilityState == 'visible' ? 1 : 0,
        movementRate: rateFacePosition(sdk.getNormalizedFaceBbox()),
      };

      setSdkData(newData);

      addEventLog([
        {
          type: EventLogType.SHENAI_DATA,
          timestamp,
          data: {
            ...newData,
            faceState: newData.faceState?.value,
            measurementState: newData.measurementState?.value,
          },
        },
      ]);
    }
  };

  const updateCanvas = () => {
    const oldCanvas = document.getElementById('mxcanvas1') as HTMLCanvasElement;
    const aiSimplifiedCanvas = document.getElementById(
      'mxcanvas-ai-simplified'
    ) as HTMLCanvasElement;

    if (oldCanvas) {
      const newCanvas = document.createElement('canvas');
      newCanvas.width = oldCanvas.width;
      newCanvas.height = oldCanvas.height;

      newCanvas.style.width = '100%';
      newCanvas.style.height = '100%';
      newCanvas.style.maxWidth = '600px';
      newCanvas.style.maxHeight = '300px';
      newCanvas.style.aspectRatio = '5 / 4';
      newCanvas.id = 'mxcanvas1';

      // Using requestAnimationFrame for smooth replacement
      requestAnimationFrame(() => {
        oldCanvas.replaceWith(newCanvas);
        console.log('Canvas updated after deinitialization');
      });
    }
    if (aiSimplifiedCanvas) {
      const aiCanvas = document.createElement('canvas');
      aiCanvas.width = aiSimplifiedCanvas.width;
      aiCanvas.height = aiSimplifiedCanvas.height;

      aiCanvas.style.width = '100%';
      aiCanvas.style.height = '100%';
      aiCanvas.id = 'mxcanvas-ai-simplified';
    }
  };

  const initializeShenAiSdk = useCallback(
    (settings: InitializationSettings, onSuccess?: () => void) => {
      if (!shenaiSDKRef.current || isSDKInitialized) return;

      shenaiSDKRef.current.initialize(SHENAI_API_KEY, '', settings, (res: ShamefulAny) => {
        if (res === shenaiSDKRef.current.InitializationResult.OK) {
          console.log('Shen.AI License result: ', res);
          shenaiSDKRef.current.setRecordingEnabled(false);
          componentName === 'ai_session'
            ? shenaiSDKRef.current.attachToCanvas('#mxcanvas-ai-simplified')
            : shenaiSDKRef.current.attachToCanvas('#mxcanvas1');
          console.log('initialised successfully with version', shenaiSDKRef.current.getVersion());
          setIsSDKInitialized(true);

          shenaiSDKRef.current?.selectCameraByDeviceId(cameraId, true);

          shenaiSDKRef.current?.setShowSignalTile(false); // remove signal quality(stars) and signal plot from user interface

          onSuccess?.();
        } else {
          console.error('Shen.AI SDK initialization failed or canvas not found.');
        }
      });
    },
    [shenaiSDKRef.current, isSDKInitialized]
  );

  const deinitializeShenAiSdk = useCallback(async () => {
    if (isSDKInitialized && shenaiSDKRef.current) {
      try {
        shenaiSDKRef.current.deinitialize();
        setIsSDKInitialized(false);
        console.log('Shen.AI SDK deinitialized successfully');

        // Wait before updating the canvas
        await delay(500);

        updateCanvas();

        // Add delay to stabilize the process
        await delay(1000);
      } catch (error) {
        console.error('Error during Shen.AI SDK deinitialization:', JSON.stringify(error));
      }
    }
  }, [shenaiSDKRef.current, isSDKInitialized]);

  useEffect(() => {
    let isMounted = true;

    const startSdkDataPolling = async () => {
      try {
        if (isMounted && isSDKInitialized) {
          //isSDKInitialized
          await fetchData();
        }
      } catch (e) {
        console.error('Error fetching shenai data', JSON.stringify(e));
      } finally {
        if (isMounted && isSDKInitialized) {
          setTimeout(startSdkDataPolling, 1000);
        }
      }
    };

    startSdkDataPolling();

    return () => {
      isMounted = false;
      if (isSDKInitialized && shenaiSDKRef.current) {
        //isSDKInitialized
        shenaiSDKRef.current.deinitialize();
        setIsSDKInitialized(false);
      }
    };
  }, [shenaiSDK?.isInitialized()]);

  const getDeviceId = async (retryInterval = 1000, maxRetries = 10) => {
    let retries = 0;

    while (retries < maxRetries) {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter((device) => device.kind === 'videoinput');

      if (videoDevices.length > 0 && videoDevices[0].deviceId != '') {
        localStorage.setItem('cameraId', `${videoDevices[0].deviceId}`);
        return setCameraId(videoDevices[0].deviceId); // Return the first available video device ID
      }

      retries++;
      await new Promise((resolve) => setTimeout(resolve, retryInterval));
    }

    throw new Error('No media devices found after retries.');
  };

  useEffect(() => {
    const initializeCamera = async () => {
      try {
        await getDeviceId();
      } catch (error: any) {
        console.error('Error getting device ID:', error.message);
      }

      if (cameraId && shenaiSDKRef.current) {
        const settings: InitializationSettings = {
          precisionMode: shenaiSDKRef.current.PrecisionMode.RELAXED,
          operatingMode: shenaiSDKRef.current.OperatingMode.MEASURE,
          measurementPreset: shenaiSDKRef.current.MeasurementPreset.INFINITE_METRICS,
          cameraMode: shenaiSDKRef.current.CameraMode.DEVICE_ID,
          onboardingMode: shenaiSDKRef.current.OnboardingMode.HIDDEN,
          // showUserInterface: componentName == 'ai_session' ? true : false,
          showUserInterface: false,

          showFacePositioningOverlay: true,
          showVisualWarnings: false,
          enableCameraSwap: false,
          showFaceMask: true,
          showBloodFlow: true,
          hideShenaiLogo: true,
          enableStartAfterSuccess: true,
          enableSummaryScreen: false,
          enableHealthRisks: false,
          showOutOfRangeResultIndicators: true,
          showTrialMetricLabels: false,
          enableFullFrameProcessing: false,
        };
        setInitializationSettings(settings);
        initializeShenAiSdk(settings);
      }
    };

    initializeCamera();
  }, [shenaiSDKRef.current, cameraId]);

  useEffect(() => {
    // scenario works only if actor started
    // TODO: make actor generic

    const throttledFn = throttle((data) => {
      dashboardActor.send({
        type: 'SDK_DATA_UPDATE',
        data,
      });
    }, 2000);

    if (dashboardActor.getSnapshot()) {
      throttledFn(sdkData);

      return () => {
        throttledFn.cancel();
      };
    }
  }, [sdkData]);

  useEffect(() => {
    //TODO: find a way not to trigger actor if no need or pass actor as an argument
    // Steps

    if (sdkData.heartRate && !isShenAICalibrated) {
      dashboardActor.send({
        type: 'MARK_ACTIVE_CHECK',
        stepIndex: 0,
        checkIndex: 0,
      });
      setIsShenAICalibrated(true);
    }
    if (
      sdkData.heartRate &&
      sdkData.cardiacStressRT &&
      sdkData.hrvSDNN &&
      !isShenAIMeasurementStarted
    ) {
      dashboardActor.send({
        type: 'MARK_ACTIVE_CHECK',
        stepIndex: 0,
        checkIndex: 1,
      });
      dashboardActor.send({
        type: 'NEXT_STEP',
      });
      setShenAIMeasurementStarted(true);
    }
  }, [sdkData, isShenAICalibrated, isShenAIMeasurementStarted]);

  useEffect(() => {
    if (socket?.connected) {
      if (!sessionId || !getIsFaceOk(sdkData, shenaiSDKRef.current) || !isLogsSending) {
        return;
      }
      const sendCameraFrameInterval = setInterval(async () => {
        const { base64, timestamp } = await getCameraFrameData();
        socket.emit('send-camera-frame', { userId, sessionId, base64, timestamp });
      }, 500);

      return () => {
        clearInterval(sendCameraFrameInterval);
      };
    }
  }, [sdkData.faceState, socket?.connected, userId, isLogsSending, sessionId]);

  return {
    sdkData,
    isSDKInitialized,
    initializationSettings,
    setInitializationSettings,
    initializeShenAiSdk,
    deinitializeShenAiSdk,
    shenaiSDKRef,
  };
};
