import { useRef, useCallback, useState } from 'react';
import debounce from 'lodash/debounce';
import { RealtimeClient as RealtimeClientType } from '../../../modules/Patient/AISession/RealtimeAISession';
import { ClientConfig, UseClientResult, VadConfig } from '../types';
import { BASE_WS_URL } from '@api/base';
import { RealtimeClient } from '@openai/realtime-api-beta';

interface UseClientInitialConfig extends ClientConfig {
  turnDetectionType: string;
}

export const useRealtimeClient = (initialConfig: UseClientInitialConfig): UseClientResult => {
  const voiceBotInstanceRef = useRef<RealtimeClientType>(
    new RealtimeClient({ url: `${BASE_WS_URL}/azure-rt` })
  );
  const [isConnected, setIsConnected] = useState(false);

  const sendUpdateConfig = (config: Partial<UseClientInitialConfig>) => {
    if (voiceBotInstanceRef.current.isConnected()) {
      voiceBotInstanceRef?.current?.realtime.send('session.update', {
        session: config,
      });
    }
  };

  const updateConfig = useCallback(
    (config: Partial<UseClientInitialConfig>) => {
      if (!voiceBotInstanceRef.current) return;
      //VAD config
      voiceBotInstanceRef.current.defaultServerVadConfig.threshold =
        config.threshold ?? voiceBotInstanceRef.current.defaultServerVadConfig.threshold;
      voiceBotInstanceRef.current.defaultServerVadConfig.prefix_padding_ms =
        config.prefixPadding ??
        voiceBotInstanceRef.current.defaultServerVadConfig.prefix_padding_ms;
      voiceBotInstanceRef.current.defaultServerVadConfig.silence_duration_ms =
        config.silenceDuration ??
        voiceBotInstanceRef.current.defaultServerVadConfig.silence_duration_ms;

      voiceBotInstanceRef.current.defaultServerVadConfig.type =
        config.turnDetectionType ?? voiceBotInstanceRef.current.defaultServerVadConfig.type;

      const newConfig = {
        ...voiceBotInstanceRef.current.defaultSessionConfig,
        voice: config.voice ?? voiceBotInstanceRef.current.defaultSessionConfig.voice,
        instructions:
          config.instructions ?? voiceBotInstanceRef.current.defaultSessionConfig.instructions,
        temperature:
          config.temperature ?? voiceBotInstanceRef.current.defaultSessionConfig.temperature,
        max_response_output_tokens:
          config.maxTokens ??
          voiceBotInstanceRef.current.defaultSessionConfig.max_response_output_tokens,
        input_audio_transcription: { model: 'whisper-1' },
        turn_detection: voiceBotInstanceRef.current.defaultServerVadConfig,
      };

      voiceBotInstanceRef.current.defaultSessionConfig = {
        ...voiceBotInstanceRef.current.defaultSessionConfig,
        ...newConfig,
      };

      voiceBotInstanceRef.current.sessionConfig = {
        ...voiceBotInstanceRef.current.sessionConfig,
        ...newConfig,
      };

      sendUpdateConfig(newConfig);
    },
    [voiceBotInstanceRef.current]
  );

  const updateVadConfig = useCallback(
    (config: Partial<VadConfig>) => {
      if (!voiceBotInstanceRef.current) return;

      const currentVadConfig = voiceBotInstanceRef.current.defaultServerVadConfig;

      const newVadConfig = {
        type: config.type ?? currentVadConfig.type,
        threshold: config.threshold ?? currentVadConfig.threshold ?? 0.5,
        prefix_padding_ms: config.prefixPadding ?? currentVadConfig.prefix_padding_ms ?? 300,
        silence_duration_ms: config.silenceDuration ?? currentVadConfig.silence_duration_ms ?? 500,
      };

      if ((voiceBotInstanceRef.current.sessionConfig as any).turn_detection === null) {
        voiceBotInstanceRef.current.defaultServerVadConfig = newVadConfig;
      } else {
        // Update turn detection on the server with debounce
        if (voiceBotInstanceRef.current.isConnected()) {
          debounce(() => {
            voiceBotInstanceRef?.current?.realtime.send('session.update', {
              session: {
                turn_detection: newVadConfig,
              },
            });
          }, 1000)();
        }
      }
    },
    [voiceBotInstanceRef.current]
  );

  const handleClientReady = useCallback(
    (client: RealtimeClient) => {
      voiceBotInstanceRef.current = client;
      updateConfig(initialConfig);
    },
    [initialConfig, updateConfig]
  );

  const handleConnectionChange = useCallback(
    (connected: boolean) => {
      setIsConnected(connected);
      if (connected && voiceBotInstanceRef.current) {
        updateConfig(initialConfig);
      }
    },
    [initialConfig, updateConfig]
  );

  return {
    voiceBotInstanceRef: voiceBotInstanceRef.current,
    isConnected,
    updateConfig,
    updateVadConfig,
    handleClientReady,
    handleConnectionChange,
  };
};
