import { HeartIcon } from 'lucide-react';
import { ShenaiSdkDataI } from '@shared/types/shenai/sdk';
import { MEASUREMENT_STATE } from '@features/Plot/model/constants/states';

type PulseValueProps = {
  sdkData: ShenaiSdkDataI | null;
};

const PulseValue = ({ sdkData }: PulseValueProps) => {
  const isSignalGood = sdkData?.measurementState?.value === MEASUREMENT_STATE.RUNNING_SIGNAL_GOOD;

  return (
    <div
      className={`flex gap-2 h-5 text-lg font-bold font-['Inter'] leading-[27px] ${
        isSignalGood ? 'text-purple-600' : 'text-gray-500'
      }`}
    >
      <HeartIcon
        fill={isSignalGood ? '#9333EA' : '#6b7280'}
        stroke={isSignalGood ? '#9333EA' : '#6b7280'}
      />
      {sdkData?.hr10s && <span>{sdkData.hr10s}</span>}
    </div>
  );
};

export default PulseValue;
