import { useEffect, useState } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { RedirectToSignIn, SignedIn, SignedOut, useSignIn } from '@clerk/clerk-react';
import { DailyProvider } from '@daily-co/daily-react';
import { AppProvider } from './contextApp';
import { getTokenFromURL } from '@utils/getTokenFromURL';
import { getFullToken } from '@api/user/me';
import { WebSocketProvider } from './websocket';
import App from './App';
import { Loader } from '@shared/ui/loader/Loader';

const router = createBrowserRouter(
  [
    {
      path: '/',
      element: <App />,
    },
  ],
  {
    future: {
      v7_fetcherPersist: true,
      v7_normalizeFormMethod: true,
      v7_partialHydration: true,
      v7_relativeSplatPath: true,
      v7_skipActionErrorRevalidation: true,
    },
  }
);

const AppWrapper = () => {
  const [isSigningIn, setIsSigningIn] = useState(true);
  const [isExecuted, setIsExecuted] = useState(false);
  const { signIn } = useSignIn();

  useEffect(() => {
    const processToken = async () => {
      try {
        if (signIn && !isExecuted) {
          const tokenFromUrl = getTokenFromURL(window.location.href);
          if (tokenFromUrl) {
            await signIn?.create({ strategy: 'ticket', ticket: tokenFromUrl });
          } else {
            const shortId = new URLSearchParams(window.location.search).get('shortId');
            if (shortId) {
              const fetchedFullToken = await getFullToken(shortId);

              if (fetchedFullToken) {
                setIsExecuted(true);
                await signIn.create({ strategy: 'ticket', ticket: fetchedFullToken });
              }
            }
          }
        }
        setIsSigningIn(false);
      } catch (err: any) {
        err.sentryHandled = true;
        console.error('Error during token handling:', JSON.stringify(err));
        setIsSigningIn(false);
      }
    };

    processToken();
  }, [signIn]);

  if (isSigningIn) {
    return <Loader />;
  }

  return (
    <>
      <SignedIn>
        <AppProvider>
          <WebSocketProvider>
            <DailyProvider>
              <RouterProvider
                router={router}
                future={{ v7_startTransition: true }}
              />
            </DailyProvider>
          </WebSocketProvider>
        </AppProvider>
      </SignedIn>
      <SignedOut>
        <RedirectToSignIn signUpFallbackRedirectUrl={import.meta.env.VITE_VF_SITE_FE_URL} />
      </SignedOut>
    </>
  );
};

export default AppWrapper;
