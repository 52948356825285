import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { shallowEqual, useSelector } from '@xstate/react';
// xState
import { dashboardActor } from '@components/RealtimeDashboard/model/xstate/dashboardMachine';

import MemoAISimplifiedShenaiCanvas from './AISimplifiedShenaiCanvas';

import MemoMeasurementGroupSection from './MeasurementSection/MeasurementGroupSection';

import MicrophoneSelector from '@features/Select/Microphone/MicrophoneSelector';
import CameraSelector from '@features/Select/Camera/CameraSelector';
import { appActor } from '@machines/shared/appMachine';

import MicTestVisualizer, {
  AUDIO_SOURCE,
  MicTestVisualizerRef,
} from '@components/Visualizer/MicTestVisualizer';
import { ShamefulAny } from '@interfaces/index';
import ConversationItems from './ConversationItems';

import AISimplifiedGraphsTool from './AITools/AISimplifiedGraphsTool';
import { StartSessionButton } from './StartSessionButton';
import { useRealtimeChecks } from '../model/hooks/useRealtimeChecks';

interface RealtimeEvent {
  time: string;
  source: 'client' | 'server';
  count?: number;
  event: { [key: string]: any };
}
interface RealtimeAIProps {
  startTimeRef?: React.MutableRefObject<number | undefined>;
  wavRecorderRef?: ShamefulAny; //React.MutableRefObject<WavRenderer | null>;
  wavStreamPlayerRef?: ShamefulAny; //React.MutableRefObject<WavRenderer | null>;
  isSDKInitialized: boolean;
  shenaiSDK: any;
  onSetInitializationSettings: any;
  onConnectConversation: any;
  isConnected: boolean;
  sessionId: string;
  realtimeClientInstance: any;
}

const AISimplifiedRealtimeAI = ({
  wavRecorderRef,
  wavStreamPlayerRef,
  isSDKInitialized,
  shenaiSDK,
  sessionId,
  onSetInitializationSettings,
  onConnectConversation,
  isConnected,
  realtimeClientInstance,
}: RealtimeAIProps) => {
  const tools = useSelector(dashboardActor, (state) => state.context.tools, shallowEqual);
  const showTranscription = tools.find((tool) => tool.label === 'Captions')?.flag.isInUse || false;
  const showGraphs = tools.find((tool) => tool.label === 'Graphs')?.flag.isInUse || false;

  const { microphoneId, cameraId } = useSelector(appActor, (state) => state.context);

  const [isMicTestRunning, setMicTestIsRunning] = useState<boolean>(true);

  const [, setClientSpeaking] = useState(false);
  const [isServerSpeaking, setServerSpeaking] = useState(false);

  const clientVisualizerRef = useRef<MicTestVisualizerRef | null>(null);
  const serverVisualizerRef = useRef<MicTestVisualizerRef | null>(null);
  const micTestVisualizerRef = useRef<MicTestVisualizerRef | null>(null);

  const { isShowCanvas, isShowWarning } = useRealtimeChecks({ isConnected });

  useEffect(() => {
    isConnected == false ? setMicTestIsRunning(true) : setMicTestIsRunning(false);
  }, [isConnected]);

  const handleStartSessionClick = () => {
    onConnectConversation?.(sessionId);
    document.documentElement.requestFullscreen().catch((err) => {
      console.error(`Error attempting to enable full-screen mode: ${err.message}`);
    });
  };

  useEffect(() => {
    let isLoaded = true;
    let audioContext: AudioContext | null = null;
    let stream: MediaStream | null = null;

    const startMicTest = async () => {
      try {
        stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        audioContext = new AudioContext();
        const analyser = audioContext.createAnalyser();
        const source = audioContext.createMediaStreamSource(stream);
        source.connect(analyser);

        const bufferLength = analyser.frequencyBinCount;
        const dataArray = new Float32Array(bufferLength);
        const lastDataArray = new Float32Array(bufferLength);
        const threshold = 10;
        const updateInterval = 100;
        let lastUpdateTime = performance.now();

        const updateData = () => {
          if (!isLoaded) return;
          analyser.getFloatFrequencyData(dataArray); //TODO think about moving it inside the throttling condition now - lastUpdateTime >= updateInterval

          const now = performance.now();

          if (now - lastUpdateTime >= updateInterval) {
            const hasSignificantChange = dataArray.some(
              (value, index) => Math.abs(value - lastDataArray[index]) > threshold
            );

            if (hasSignificantChange) {
              lastDataArray.set(dataArray);

              if (micTestVisualizerRef.current) {
                micTestVisualizerRef.current.updateVisualizer(dataArray, AUDIO_SOURCE.MICROPHONE);
              }
            }

            lastUpdateTime = now;
          }

          requestAnimationFrame(updateData);
        };

        updateData();
      } catch (err) {
        console.error('Microphone access denied or error:', err);
      }
    };

    if (isMicTestRunning) {
      startMicTest();
    }

    return () => {
      isLoaded = false;
      if (audioContext) {
        audioContext.close();
      }
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }
    };
  }, [isMicTestRunning]);

  useEffect(() => {
    let isLoaded = true;
    const frameInterval = 1000 / 30; // 30 FPS = ~33.33ms per frame
    let lastRender = performance.now();

    const wavRecorder = wavRecorderRef?.current;
    const wavStreamPlayer = wavStreamPlayerRef?.current;

    const isWavRecorderValid = wavRecorder && typeof wavRecorder.getFrequencies === 'function';
    const isWavStreamPlayerValid =
      wavStreamPlayer && typeof wavStreamPlayer.getFrequencies === 'function';

    if (!isWavRecorderValid && !isWavStreamPlayerValid) {
      console.warn('Both WavRecorder and WavStreamPlayer are not properly initialized.');
      return;
    }

    const render = (time: number) => {
      if (!isLoaded) return;

      if (time - lastRender >= frameInterval) {
        // Handle client frequencies
        if (isWavRecorderValid && wavRecorder.recording) {
          const result = wavRecorder.getFrequencies('voice');
          const isActive = result.values.some((value: number) => value > 0.1);
          setClientSpeaking(isActive);

          // // Update visualizer dynamically
          if (clientVisualizerRef.current) {
            clientVisualizerRef.current.updateVisualizer(result.values, AUDIO_SOURCE.CLIENT);
          }
        } else {
          setClientSpeaking(false);
        }

        // Handle server frequencies
        if (isWavStreamPlayerValid && wavStreamPlayer.analyser) {
          const result = wavStreamPlayer.getFrequencies('voice');
          const isActive = result.values.some((value: number) => value > 0.1);
          setServerSpeaking(isActive);

          // // Update visualizer dynamically
          if (serverVisualizerRef.current) {
            serverVisualizerRef.current.updateVisualizer(result.values, AUDIO_SOURCE.SERVER);
          }
        } else {
          setServerSpeaking(false);
        }

        lastRender = time;
      }

      window.requestAnimationFrame(render);
    };

    window.requestAnimationFrame(render);

    return () => {
      isLoaded = false;
    };
  }, [wavRecorderRef, wavStreamPlayerRef]);

  const handleSetCameraId = useCallback((id: string) => {
    appActor.send({ type: 'CAMERA_ID_UPDATE', id });
  }, []);

  const handleSetMicrophoneId = useCallback((id: string) => {
    appActor.send({ type: 'MICROPHONE_ID_UPDATE', id });
  }, []);

  return (
    <div
      data-component="SimplifiedAIPage"
      className="w-full"
      style={{ height: `calc(100% - 180px + 48px)` }}
    >
      {/* 180px - is the height of topbar ; 48px - is the height of app padding top(24px) and marging top realtime dashboard */}
      <div className="flex flex-col w-full h-full items-center ">
        <div
          className={`w-full flex ${isConnected ? 'justify-start mb-8' : 'justify-center h-full'} flex-col items-center`}
        >
          <div
            className={`flex transition-all duration-500 ease-in-out ${
              isConnected && !isShowCanvas ? 'opacity-0 pointer-events-none' : 'opacity-100'
            } ${isConnected ? 'absolute bottom-0 right-0' : 'w-full h-full justify-center items-center'}`}
            style={{
              transform: isConnected ? (isShowCanvas ? 'translateY(0)' : 'translateY(20px)') : '',
              maxHeight: isConnected ? (isShowCanvas ? '300px' : '0') : '100%',
              overflow: isConnected && !isShowCanvas ? 'hidden' : 'visible',
            }}
          >
            <MemoAISimplifiedShenaiCanvas
              key="AI-simplified-shenai-canvas"
              isSDKInitialized={isSDKInitialized}
            />
            {!isConnected && (
              <div className="text-center text-gray-800 text-base absolute w-[183px] top-[30%] h-[79px] left-[130%]">
                Center your face, try not to move and look at the camera until HRV finish is loading
              </div>
            )}
          </div>
          <div className="w-[105px] h-[105px] mt-6">
            <MicTestVisualizer
              key="ai-visualizer"
              ref={
                isMicTestRunning
                  ? micTestVisualizerRef
                  : isServerSpeaking
                    ? serverVisualizerRef
                    : clientVisualizerRef
              }
              wrapperClassName={isConnected ? 'justify-start' : ''}
              width={105}
              height={105}
              isWarning={isShowWarning}
            />
          </div>

          {!showGraphs && (
            <div className="relative flex items-end justify-center w-full mt-4 mb-7">
              <MemoMeasurementGroupSection />
            </div>
          )}

          {!isConnected && (
            <>
              <StartSessionButton onClick={handleStartSessionClick} />

              <div className="flex  w-full h-full items-end justify-center relative">
                <div className="flex gap-5  w-fit h-fit relative overflow-visible">
                  <MicrophoneSelector
                    key="simple-ai-1"
                    setMicrophone={handleSetMicrophoneId}
                    manualDropdownPosition
                    microphone={microphoneId}
                    selectClass="w-full max-w-[240px] relative"
                    selectDropdownClass="top-unset left-0 bottom-[60px]"
                  />

                  <CameraSelector
                    key="simple-a-2"
                    selectClass="w-full max-w-[240px] relative"
                    selectDropdownClass="top-unset left-0 bottom-[60px]"
                    manualDropdownPosition
                    setShenaiInitSettings={onSetInitializationSettings}
                    setCamera={handleSetCameraId}
                    camera={cameraId}
                    updateShenai={shenaiSDK}
                  />
                </div>
              </div>
            </>
          )}
        </div>

        {isConnected && showTranscription && (
          <ConversationItems realtimeClientInstance={realtimeClientInstance} />
        )}
        {showGraphs && <AISimplifiedGraphsTool />}
      </div>
    </div>
  );
};

const MemoAISimplifiedRealtimeAI = memo(AISimplifiedRealtimeAI);
MemoAISimplifiedRealtimeAI.displayName = 'AISimplifiedRealtimeAI';
export { MemoAISimplifiedRealtimeAI };
export type { RealtimeEvent };
