import { useState } from 'react';
import Button from '@components/Button';
import { CircleAlertIcon } from 'lucide-react';
import { SessionPromptsByType } from '@components/SessionComponents/AssignAISession/types';

type EditPromptFormProps = {
  onCancel: () => void;
  error?: string | null;
  sessionType: string;
  sessionPrompt: string;
  langfusePrompt: string;
  setSessionPromptByType: React.Dispatch<React.SetStateAction<SessionPromptsByType>>;
};

export const EditPromptForm = ({
  onCancel,
  error,
  sessionType,
  sessionPrompt,
  langfusePrompt,
  setSessionPromptByType,
}: EditPromptFormProps) => {
  const [editedSessionPrompt, setEditedSessionPrompt] = useState<string>(sessionPrompt);

  const handlePromptSave = () => {
    setSessionPromptByType((prev: SessionPromptsByType) => ({
      ...prev,
      [sessionType]: editedSessionPrompt,
    }));
    onCancel();
  };

  const handlePromptRestore = () => {
    setEditedSessionPrompt(langfusePrompt);
  };

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center">
      <p className="text-gray-500">You can edit the main prompt for this session.</p>
      <div className="sm:mx-auto sm:w-full">
        <div className="space-y-4">
          <span
            onClick={handlePromptRestore}
            className="font-medium text-primary3 cursor-pointer"
          >
            Restore Prompt
          </span>
          <label
            htmlFor='main_prompt'
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Main prompt
          </label>
          <textarea
            id="main_prompt"
            className="block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-button-hover placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 h-[15rem]"
            value={editedSessionPrompt}
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
              setEditedSessionPrompt(event.target.value);
            }}
          />
          <div
            className={`text-rose-600 text-sm ${error ? 'opacity-100' : 'opacity-0'} flex gap-1`}
          >
            <CircleAlertIcon
              color="#dc2626"
              height="24px"
              width="24px"
            />
            <span>{error}</span>
          </div>
          <div className="flex justify-center pt-5 gap-4">
            <Button
              variant="outline"
              text="Cancel"
              onClick={onCancel}
              className="w-[50%]"
            />
            <Button
              onClick={handlePromptSave}
              text="Save"
              className="w-[50%]"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
