import { RealtimeEventHandler } from '@openai/realtime-api-beta/dist/lib/event_handler';
import { useEffect, useState } from 'react';
import { askChatGPT } from '@api/userAPI';
import ToggleableTabs from '@features/Tab/ToggleableTabs/ToggleableTabs';
import { TOGGLEABLE_SECTIONS } from '../model/constants/togglableSections';
import { useSelector } from '@xstate/react';
import { refaelaPatientActor } from '@components/xState/machines/refaelaPatientMachine';
import { refaelaTherapistActor } from '@components/xState/machines/refaelaTherapistMachine';
import { isUserTherapist } from '@utils/helpers';
import { Patient } from '@interfaces/patient';
import { dashboardActor } from '../model/xstate/dashboardMachine';
import { KeywordChart } from './barChart';

type FrequentKeywordsDataProps = {
  sessionId: string;
  actor: typeof refaelaPatientActor | typeof refaelaTherapistActor;
  realtime: RealtimeEventHandler;
  onSectionToggleByKey: (key: string) => void;
};

export type FrequentKeywordsLibrary = {
  [keyword: string]: number;
};

const FrequentKeywordsData = ({
  sessionId,
  actor,
  realtime,
  onSectionToggleByKey,
}: FrequentKeywordsDataProps) => {
  const [frequentKeywords, setFrequentKeywords] = useState<FrequentKeywordsLibrary>({});
  const [speech, setSpeech] = useState<string[]>([]);

  const sections = useSelector(dashboardActor, (state) => state.context.sections);
  const currentUser = useSelector(actor, (state) => state.context.currentUser)!;

  // TODO: seems like we can pass userId as prop not to import actor
  const userId = isUserTherapist(currentUser.role!)
    ? currentUser.therapistId!
    : (currentUser as Patient).patientId;

  useEffect(() => {
    const sessionCreateHandler = async () => {
      while (true) {
        const transcript_evt: any = await realtime.waitForNext(
          'server.conversation.item.input_audio_transcription.completed'
        );

        setSpeech(transcript_evt.transcript);
      }
    };
    realtime.on('server.session.created', sessionCreateHandler);
    return () => {
      if ('server.session.created' in realtime) {
        try {
          realtime.off('server.session.created', sessionCreateHandler);
        } catch (e) {
          console.log('error removing event listener: ', e);
        }
      }
    };
  }, [realtime, realtime.eventHandlers]);

  useEffect(() => {
    const askAI = async () => {
      try {
        const { result } = await askChatGPT(
          userId,
          sessionId,
          speech,
          'frequent-keywords-ai-agent'
        );

        if (result.keyword == 'N/A') return;

        const isKeywordPresent = frequentKeywords[result?.keyword];

        if (isKeywordPresent) {
          setFrequentKeywords((prev) => ({
            ...prev,
            [result.keyword]: frequentKeywords[result.keyword] + 1,
          }));
        } else {
          setFrequentKeywords((prev) => ({ ...prev, [result.keyword]: 1 }));
        }
      } catch (e) {
        console.log('error during llm request: ', e);
      }
    };

    speech.length && askAI();
  }, [speech]);

  const handleSectionToggle = (key: string) => {
    onSectionToggleByKey(key);
  };

  return (
    <div className="mb-4 space-y-4">
      <div className="flex flex-col gap-4 w-full">
        <ToggleableTabs
          isShown={sections[TOGGLEABLE_SECTIONS.FREQUENT_KEYWORDS]}
          onToggle={() => handleSectionToggle(TOGGLEABLE_SECTIONS.FREQUENT_KEYWORDS)}
          tabs={[
            {
              label: 'Frequent Keywords',
              content: (
                <div className="w-full">
                  <KeywordChart keywords={frequentKeywords} />
                </div>
              ),
            },
          ]}
        />
      </div>
    </div>
  );
};

export default FrequentKeywordsData;
