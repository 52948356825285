import useResizable from '@hooks/useResizable';
import { useScrollTop } from '@hooks/useScrollTop';
import { useEffect, useRef, useState } from 'react';
import ResizeHandle from './ResizeHandler';

type ConversationModelEventsProps = {
  realtimeClientInstance: any;
};

const ConversationModelEvents = ({ realtimeClientInstance }: ConversationModelEventsProps) => {
  const [items, setItems] = useState<any[]>([]);

  const eventsScrollRef = useRef<HTMLDivElement>(null);
  const eventsScrollHeightRef = useRef(0);

  useEffect(() => {
    let timeoutId;

    timeoutId = setInterval(() => {
      const items = realtimeClientInstance.conversation.getItems();
      const lightItems = [];

      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        const [content] = item.content;

        if (content?.text?.includes('user_') || content?.text?.includes('session_')) {
          continue;
        }

        const { formatted, ...rest } = item;
        lightItems.push({
          ...rest,
          formatted: {
            ...formatted,
            audio: [],
            audioBuffer: {},
            file: undefined,
          },
        });
      }
      setItems(lightItems);
    }, 100);

    return () => {
      clearInterval(timeoutId);
    };
  }, []);

  useScrollTop(eventsScrollRef, eventsScrollHeightRef, items);

  const { height, handleMouseDown } = useResizable(101);

  return (
    <div
      style={{ height: `${height}px` }}
      className="w-full bg-white rounded-[5px] border border-gray-300 text-[#6e6e7f] relative flex-grow pt-1 leading-[1.2em]"
      data-conversation-content
    >
      <div
        ref={eventsScrollRef}
        className="p-2 overflow-x-hidden overflow-y-auto h-full"
      >
        {!items.length && `Click 'Connect' to establish a connection...`}
        {items.map((conversationItem: any) => {
          return (
            <div
              className="conversation-item"
              key={conversationItem.id}
            >
              <div className={`speaker ${conversationItem.role || ''}`}>
                <div>
                  {((conversationItem.role as string) || (conversationItem.type as string)).replace(
                    /_/g,
                    ' '
                  )}
                </div>
              </div>
              <div className={`speaker-content`}>
                {/* tool response */}
                {conversationItem.type === 'function_call_output' && (
                  <div>{conversationItem.formatted.output}</div>
                )}
                {/* tool call */}
                {!!conversationItem.formatted.tool && (
                  <div>
                    {conversationItem.formatted.tool.name}(
                    {conversationItem.formatted.tool.arguments})
                  </div>
                )}
                {!conversationItem.formatted.tool && conversationItem.role === 'user' && (
                  <div>
                    {conversationItem.formatted.transcript ||
                      (Object.keys(conversationItem.formatted.audio).length > 0
                        ? '(awaiting transcript)'
                        : conversationItem.formatted?.text || '(item sent)')}
                  </div>
                )}
                {!conversationItem.formatted.tool && conversationItem.role === 'assistant' && (
                  <div>
                    {conversationItem.formatted.transcript ||
                      conversationItem.formatted?.text ||
                      '(truncated)'}
                  </div>
                )}
                {/* {conversationItem.formatted.file && (
                  <audio
                    src={conversationItem.formatted.file.url}
                    controls
                    
                  />
                )} */}
              </div>
            </div>
          );
        })}
      </div>
      <ResizeHandle
        className="sticky bottom-0"
        onMouseDown={handleMouseDown}
      />
    </div>
  );
};

export default ConversationModelEvents;
