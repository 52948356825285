import { memo, useCallback, useState } from 'react';

import CameraSelector from '@features/Select/Camera/CameraSelector';

import Switch from '@shared/ui/switch/Switch';

import { OperatingMode, PrecisionMode } from '../../../../../public/shenai-sdk/index'; // import from local

import { getTabVisibilityStateValue } from '@components/Plot/utils';
import ToggleableTabs from '@features/Tab/ToggleableTabs/ToggleableTabs';
import SwitchButtons from '@features/Switch/SwitchButtons';
import { useSelector } from '@xstate/react';
import { dashboardActor } from '../../model/xstate/dashboardMachine';
import { appActor } from '@machines/shared/appMachine';
import MicrophoneSelector from '@features/Select/Microphone/MicrophoneSelector';
import { TOGGLEABLE_SECTIONS } from '@components/RealtimeDashboard/model/constants/togglableSections';

import MovementRatePlot from '@features/Plot//MovementRatePlot';
import TabVisibilityPlot from '@features/Plot/TabVisibilityPlot';
import { getFaceStateValue } from '@features/Plot/model/utils/getFaceStateValue';
import { getMeasurementStateValue } from '@features/Plot/model/utils/getMeasurementStateValue';
import { FaceStatePlot, MeasurementStatePlot } from '@features/Plot';
import InfoRow from '@shared/ui/caption/InfoRow';

type VisionAIModelProps = {
  shenaiSDK: any;
  initializationSettings: any;
  onSetInitializationSettings: any;
  isSDKInitialized?: boolean;
  onInitializeShenAiSdk: any;
  onDeinitializeShenAiSdk: any;
  onSectionToggleByKey: (key: string) => void;
};

const VisionAIModel = ({
  shenaiSDK,
  initializationSettings,
  onSetInitializationSettings,
  isSDKInitialized,
  onInitializeShenAiSdk,
  onDeinitializeShenAiSdk,
  onSectionToggleByKey,
}: VisionAIModelProps) => {
  const sdkData = useSelector(dashboardActor, (state) => state.context.sdkData);
  const sections = useSelector(dashboardActor, (state) => state.context.sections);

  const handleSectionToggle = (key: string) => {
    onSectionToggleByKey(key);
  };

  const { microphoneId, cameraId } = useSelector(appActor, (state) => state.context);

  const [isFullFrameProcessing, setIsFullFrameProcessing] = useState(
    initializationSettings?.enableFullFrameProcessing ?? false
  );

  const setOperatingMode = (operatingMode?: OperatingMode) => {
    if (!operatingMode) return;
    shenaiSDK?.setOperatingMode(operatingMode);
    onSetInitializationSettings((s: any) => ({ ...s, operatingMode }));
  };

  const setPrecisionMode = (precisionMode?: PrecisionMode) => {
    if (!precisionMode) return;
    shenaiSDK?.setPrecisionMode(precisionMode);
    onSetInitializationSettings((s: any) => ({ ...s, precisionMode }));
  };

  const handleToggleChange = (checked: boolean) => {
    if (!isSDKInitialized) {
      setIsFullFrameProcessing(checked);
      console.log('Full frame processing:', checked);
    }
  };

  const handleSetCameraId = useCallback((id: string) => {
    appActor.send({ type: 'CAMERA_ID_UPDATE', id });
  }, []);

  const handleSetMicrophoneId = useCallback((id: string) => {
    appActor.send({ type: 'MICROPHONE_ID_UPDATE', id });
  }, []);

  const handleInitializationChange = ({ value }: Record<string, string | number>) => {
    const settings = {
      ...initializationSettings,
      enableFullFrameProcessing: isFullFrameProcessing,
    };

    if (value === 'Initialize') {
      onInitializeShenAiSdk(settings);
    } else if (value === 'Deinitialize') {
      onDeinitializeShenAiSdk();
    }
  };

  const handleModeChange = ({ value }: Record<string, string | number>) => {
    if (!shenaiSDK) return;

    const mode =
      value === 'Positioning'
        ? shenaiSDK?.OperatingMode.POSITIONING
        : shenaiSDK?.OperatingMode.MEASURE;

    setOperatingMode(mode);
  };

  const handlePrecisionChange = ({ value }: Record<string, string | number>) => {
    if (!shenaiSDK) return;

    const precisionMode =
      value === 'Strict' ? shenaiSDK?.PrecisionMode.STRICT : shenaiSDK?.PrecisionMode.RELAXED;

    setPrecisionMode(precisionMode);
  };

  return (
    <div className="p-4 flex flex-col gap-4">
      <h2 className="text-xl font-bold mb-4">Vision AI Model</h2>
      <ToggleableTabs
        key={'settings'}
        isShown={sections[TOGGLEABLE_SECTIONS.SETTINGS]}
        onToggle={() => handleSectionToggle(TOGGLEABLE_SECTIONS.SETTINGS)}
        tabs={[
          {
            label: 'Settings',
            content: (
              <>
                <div className="mb-4 text-black text-[13px] font-medium font-['Inter'] leading-normal">
                  <SwitchButtons
                    containerClasses="!w-[10.25rem] text-[11px]"
                    options={[
                      { label: 'Initialize', value: 'Initialize' },
                      { label: 'Deinitialize', value: 'Deinitialize' },
                    ]}
                    initialOption={{ label: 'Initialize', value: 'Initialize' }}
                    onChange={handleInitializationChange}
                  />
                </div>
                <div className="mb-4 text-black text-[13px] font-medium font-['Inter'] leading-normal">
                  <Switch
                    label="Full frame processing"
                    checked={isFullFrameProcessing}
                    onChange={handleToggleChange}
                    containerClasses="justify-between w-full"
                    disabled={isSDKInitialized}
                  />
                </div>
                <div className="mb-4 flex items-center justify-between text-black text-[13px] font-medium font-['Inter'] leading-normal ">
                  <span>Camera</span>
                  <CameraSelector
                    selectClass="!w-full max-w-[10.25rem]"
                    selectDropdownClass="!max-w-[12.5rem]"
                    setShenaiInitSettings={onSetInitializationSettings}
                    setCamera={handleSetCameraId}
                    camera={cameraId}
                    updateShenai={shenaiSDK}
                  />
                </div>
                <div className="mb-4 flex items-center justify-between text-black text-[13px] font-medium font-['Inter'] leading-normal ">
                  <span>Microphone</span>
                  <MicrophoneSelector
                    setMicrophone={handleSetMicrophoneId}
                    microphone={microphoneId}
                    selectClass="!w-full max-w-[10.25rem]"
                  />
                </div>
                <InfoRow
                  key="operating-mode-switch"
                  label={'Operating Mode'}
                  value={
                    <SwitchButtons
                      containerClasses="!w-[10.25rem]"
                      options={[
                        { label: 'Positioning', value: 'Positioning' },
                        { label: 'Measure', value: 'Measure' },
                      ]}
                      initialOption={{ label: 'Measure', value: 'Measure' }}
                      onChange={handleModeChange}
                    />
                  }
                  additionalStyle={'mb-4'}
                />
                <InfoRow
                  key="precision-mode-switch"
                  label={'Precision Mode'}
                  value={
                    <SwitchButtons
                      containerClasses="!w-[10.25rem]"
                      options={[
                        { label: 'Strict', value: 'Strict' },
                        { label: 'Relaxed', value: 'Relaxed' },
                      ]}
                      initialOption={{ label: 'Relaxed', value: 'Relaxed' }}
                      onChange={handlePrecisionChange}
                    />
                  }
                  additionalStyle={'mb-4'}
                />
              </>
            ),
          },
        ]}
        titleFont="font-bold text-[13px]"
      />

      <ToggleableTabs
        isShown={sections[TOGGLEABLE_SECTIONS.REALTIME_OUTPUTS]}
        onToggle={() => handleSectionToggle(TOGGLEABLE_SECTIONS.REALTIME_OUTPUTS)}
        tabs={[
          {
            label: 'Realtime Outputs',
            content: (
              <>
                <div className="flex flex-col gap-4  w-full">
                  <InfoRow
                    key="face-state"
                    label={'Face State:'}
                    value={
                      <div>
                        {sdkData?.faceState !== undefined && sdkData.faceState !== null
                          ? `${sdkData.faceState.value} (${getFaceStateValue(sdkData.faceState.value)})`
                          : 'N/A'}
                      </div>
                    }
                  />

                  {sdkData?.faceState !== undefined && sdkData.faceState !== null ? (
                    <FaceStatePlot
                      faceStateValue={sdkData.faceState.value}
                      sdkData={sdkData}
                    />
                  ) : (
                    <FaceStatePlot
                      faceStateValue={0}
                      sdkData={sdkData}
                    />
                  )}
                </div>
                <div className="flex flex-col gap-4 w-full">
                  <InfoRow
                    key="measurement-state"
                    label={'Measurement State:'}
                    value={
                      <div>
                        {sdkData?.measurementState !== undefined &&
                        sdkData.measurementState !== null
                          ? `${sdkData.measurementState.value} (${getMeasurementStateValue(sdkData.measurementState.value)})`
                          : 'N/A'}
                      </div>
                    }
                  />

                  {sdkData?.measurementState !== undefined && sdkData.measurementState !== null ? (
                    <MeasurementStatePlot
                      measurementStateValue={sdkData.measurementState.value}
                      sdkData={sdkData}
                    />
                  ) : (
                    <MeasurementStatePlot
                      measurementStateValue={0}
                      sdkData={sdkData}
                    />
                  )}
                </div>

                <div className="flex flex-col gap-4 w-full">
                  <InfoRow
                    key="tab-visibility"
                    label={'Tab Visibility:'}
                    value={
                      <div>{`${sdkData?.visibilityState} (${getTabVisibilityStateValue(sdkData?.visibilityState!)})`}</div>
                    }
                  />

                  <TabVisibilityPlot
                    tabVisibilityValue={sdkData?.visibilityState!}
                    sdkData={sdkData}
                  />
                </div>

                <div className="flex flex-col gap-4 w-full">
                  <InfoRow
                    key="movement-rate"
                    label={'Movement Rate:'}
                    value={<div>{`${sdkData?.movementRate}`}</div>}
                  />

                  <MovementRatePlot
                    movementRate={sdkData?.movementRate!}
                    sdkData={sdkData}
                  />
                </div>

                <InfoRow
                  key="face-bbox"
                  label={'Face bbox:'}
                  value={
                    <span className="text-xs">
                      {sdkData?.bbox
                        ? `[x: ${sdkData.bbox.x.toFixed(2)}, y: ${sdkData.bbox.y.toFixed(2)}, w: ${sdkData.bbox.width.toFixed(2)}, h: ${sdkData.bbox.height.toFixed(2)}]`
                        : 'N/A'}
                    </span>
                  }
                />
                <InfoRow
                  key="bad-signal"
                  label={'Bad signal:'}
                  value={
                    <span>
                      {sdkData?.badSignal !== undefined && sdkData.badSignal !== null
                        ? sdkData.badSignal.toFixed(2)
                        : 'N/A'}
                    </span>
                  }
                />
                <InfoRow
                  key="face-direction"
                  label={'Face Direction:'}
                  value={
                    <div className="flex flex-col items-end">
                      <span className="text-xs">
                        {sdkData?.facePose
                          ? `(x: ${sdkData.facePose.position.x.toFixed(2)}, y: ${sdkData.facePose.position.y.toFixed(2)}, z: ${sdkData.facePose.position.z.toFixed(2)})`
                          : 'N/A'}
                      </span>
                      <span className="text-xs">
                        {sdkData?.facePose
                          ? `(pitch: ${sdkData.facePose.rotation.pitch.toFixed(2)}, roll: ${sdkData.facePose.rotation.roll.toFixed(2)}, yaw: ${sdkData.facePose.rotation.yaw.toFixed(2)})`
                          : 'N/A'}
                      </span>
                    </div>
                  }
                />
              </>
            ),
          },
        ]}
        key={'rtOutput'}
        titleFont="font-bold text-[13px]"
      />

      <ToggleableTabs
        isShown={sections[TOGGLEABLE_SECTIONS.REALTIME_MEASUREMENTS]}
        onToggle={() => handleSectionToggle(TOGGLEABLE_SECTIONS.REALTIME_MEASUREMENTS)}
        tabs={[
          {
            label: 'Realtime measurements:',
            content: (
              <>
                {' '}
                <InfoRow
                  key="heart-rate-4s"
                  label="Heart Rate (4 seconds)"
                  value={
                    sdkData?.hr4s !== undefined && sdkData.hr4s !== null
                      ? `${sdkData.hr4s} bpm`
                      : 'N/A'
                  }
                />
                <InfoRow
                  key="heart-rate-10s"
                  label="Heart Rate (10 seconds)"
                  value={
                    sdkData?.hr10s !== undefined && sdkData.hr10s !== null
                      ? `${sdkData.hr10s} bpm`
                      : 'N/A'
                  }
                />
                <InfoRow
                  key="hrv-sdnn"
                  label="HRV SDNN (real-time)"
                  value={
                    sdkData?.hrvSDNN !== undefined && sdkData.hrvSDNN !== null
                      ? `${sdkData.hrvSDNN} ms`
                      : 'N/A'
                  }
                />
                <InfoRow
                  key="cardiac-stress"
                  label="Cardiac stress"
                  value={
                    sdkData?.cardiacStressRT !== undefined && sdkData.cardiacStressRT !== null
                      ? sdkData.cardiacStressRT
                      : 'N/A'
                  }
                />
              </>
            ),
          },
        ]}
        key={'rtMeasurements'}
        titleFont="font-bold text-[13px]"
      />
    </div>
  );
};
const MemoVisionAIModel = memo(VisionAIModel);
MemoVisionAIModel.displayName = 'VisionAIModel';

export default MemoVisionAIModel;
