import { useRef } from 'react';
import * as d3 from 'd3';

type KeywordLibrary = Record<string, number>;
type FrequentKeywordsProps = {
  keywords: KeywordLibrary;
};

export const KeywordChart = ({ keywords }: FrequentKeywordsProps) => {
  const chartRef = useRef(null);

  const labels = Object.keys(keywords);
  const data = Object.values(keywords);

  const width = 600;
  const height = 400;
  const margin = { top: 50, right: 20, bottom: 20, left: 100 };

  // Remove any existing SVG
  d3.select(chartRef.current).select('svg').remove();

  // Create the SVG container
  const svg = d3.select(chartRef.current).append('svg').attr('width', width).attr('height', height);

  // Create scales
  const xScale = d3
    .scaleLinear()
    .domain([0, 100])
    .range([margin.left, width - margin.right]);

  const yScale = d3
    .scaleBand()
    .domain(labels)
    .range([margin.top, height - margin.bottom])
    .padding(0.2);

  // Add X-axis at the top
  svg
    .append('g')
    .attr('transform', `translate(0, ${margin.top})`)
    .call(d3.axisTop(xScale).ticks(5))
    .attr('font-size', '12px');

  // Add Y-axis
  svg
    .append('g')
    .attr('transform', `translate(${margin.left}, 0)`)
    .call(d3.axisLeft(yScale))
    .attr('font-size', '12px');

  // Create bars
  svg
    .selectAll('rect')
    .data(data)
    .enter()
    .append('rect')
    .attr('x', margin.left)
    .attr('y', (_, i) => yScale(labels[i]) || 0) // Ensure fallback for undefined values
    .attr('width', (d) => xScale(d) - margin.left)
    .attr('height', yScale.bandwidth())
    .attr('fill', 'rgba(128, 0, 128, 0.7)');

  // Add horizontal grid lines
  svg
    .selectAll('.grid-line-horizontal')
    .data(labels)
    .enter()
    .append('line')
    .attr('class', 'grid-line-horizontal')
    .attr('x1', margin.left)
    .attr('x2', width - margin.right)
    .attr('y1', (d, _) => yScale(d)! + yScale.bandwidth() / 2 || 0)
    .attr('y2', (d, _) => yScale(d)! + yScale.bandwidth() / 2 || 0)
    .attr('stroke', '#ccc')
    .attr('stroke-dasharray', '4');

  // Add vertical grid lines
  svg
    .selectAll('.grid-line-vertical')
    .data(xScale.ticks(5))
    .enter()
    .append('line')
    .attr('class', 'grid-line-vertical')
    .attr('x1', (d) => xScale(d))
    .attr('x2', (d) => xScale(d))
    .attr('y1', margin.top)
    .attr('y2', height - margin.bottom)
    .attr('stroke', '#ccc')
    .attr('stroke-dasharray', '4');

  return <div ref={chartRef}></div>;
};
