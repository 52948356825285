import uPlot from 'uplot';

export const calculateDurations = (
  events: Record<string, any[]>
): { start: number; end: number }[] => {
  if (!events) return [{ start: 0, end: 0 }];
  return Object.values(events).map((eventData) => {
    const firstEvent = eventData[0] || {};
    const lastEvent = eventData[eventData.length - 1] || {};

    return {
      start: Math.floor(new Date(firstEvent.time).getTime()) || 0,
      end: Math.floor(new Date(lastEvent.time).getTime()) || 0,
    };
  });
};

export const renderRectangles = (
  ctx: CanvasRenderingContext2D,
  durations: { start: number; end: number }[],
  u: uPlot,
  yValue: number,
  color: string,
  yOffset: number
) => {
  if (!durations) return;
  durations.forEach(({ start, end }) => {
    const xStart = u.valToPos(start, 'x', true);
    const xEnd = Math.min(u.valToPos(end, 'x', true));
    const y = u.valToPos(yValue, 'y', true);

    if (xStart < xEnd) {
      ctx.fillStyle = color;
      // 250 is xOffset from left edge
      ctx.fillRect(xStart, y + yOffset, xEnd - xStart, 14);
    }
  });
};
