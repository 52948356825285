import { ReactNode, useEffect, useState } from 'react';
import { ShamefulAny } from '@interfaces/index';

type HeaderProps = {
  navigation: ReactNode;
  breadcrumbs: ReactNode;
  notification: ReactNode;
  dynamic?: {
    afterNavigation: ReactNode;
    afterBreadcrumbs: ReactNode;
  };
  ignoreInputList?: string[];
  ignoreNotificationList?: string[];
  ignoreBreadcrumpsList?: string[];
  stateValue: ShamefulAny;
  disabledMic?: boolean;
};

const Header = ({
  navigation,
  breadcrumbs,
  notification,
  dynamic,
  // ignoreInputList,
  ignoreNotificationList,
  ignoreBreadcrumpsList,
  stateValue,
  // disabledMic,
}: HeaderProps) => {
  const [isNotificationBar, setIsNotificationBar] = useState<boolean>(false);

  useEffect(() => {
    setIsNotificationBar(!ignoreNotificationList?.includes(stateValue['TherapistSession']));
  }, [stateValue]);

  return (
    <div className="grid grid-cols-[62px_minmax(0,1fr)_minmax(0,1fr)_minmax(0,1fr)] items-center w-full">
      <div className="col-span-1">{navigation}</div>
      <div className="col-span-1 flex justify-start">
        <input
          className="w-[50%] ml-0.5 rounded-lg"
          type="text"
          onChange={() => {}}
          placeholder='type something...'
        />
      </div>
      {dynamic?.afterNavigation}
      <div className="col-span-1 flex justify-center">
        {!ignoreBreadcrumpsList?.includes(stateValue) && (
          <div className="w-full flex justify-center items-center max-w-[100%]">{breadcrumbs}</div>
        )}
      </div>

      {dynamic?.afterBreadcrumbs}
      {isNotificationBar && <div className="col-span-1 flex justify-end">{notification}</div>}
    </div>
  );
};

export default Header;
