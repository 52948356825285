export const TOGGLEABLE_SECTIONS = {
  SETTINGS: 'settings',
  FREQUENT_KEYWORDS: 'rt-frequent-keywords',
  CONVERSATION_ANALYSIS: 'rt-conversation-analysis',
  SPEECH_CHARACTERISTICS: 'rt-speech-characteristics',
  CHARACTERISTIC_ANALYSIS: 'rt-characteristic-analysis',
  REALTIME_OUTPUTS: 'rt-outputs',
  REALTIME_MEASUREMENTS: 'rt-measurements',
  SESSION_SETTINGS: 'session-settings',
  CONVERSATION_MODEL_EVENTS: 'conversation-model-events',
  REALTIME_TRANSCRIPTS: 'rt-events',
  SYSTEM_INSTRUCTIONS: 'rt-prompt',
  REALTIME_MEASUREMENT_GRAPHS_HEARTRATE: 'heartrate',
  REALTIME_MEASUREMENT_GRAPHS_HRVSDNN: 'heartrateSDNN',
  REALTIME_MEASUREMENT_GRAPHS_CARDIAC_STRESS: 'cardiac-stress',
  REALTIME_MEASUREMENT_GRAPHS_TACHOGRAM: 'tachogram',
  AI_SIMPLIFIED_MEASUREMENT_GRAPHS_HEARTRATE: 'aiHeartrate',
  AI_SIMPLIFIED_MEASUREMENT_GRAPHS_HRVSDNN_CARDIAC_STRESS: 'aiSDNNCardiacStress',

  VOICEAI_SETTINGS: 'voice-ai-settings',
  SUMMARY: 'rt-summary',
  SUGGESTIONS: 'rt-suggestions',
} as const;

export const DEFAULT_COLLAPSED_SECTIONS = [
  TOGGLEABLE_SECTIONS.CONVERSATION_MODEL_EVENTS,
  TOGGLEABLE_SECTIONS.REALTIME_TRANSCRIPTS,
  TOGGLEABLE_SECTIONS.SESSION_SETTINGS,
];
