import { Loader } from '@shared/ui/loader/Loader';
import { memo } from 'react';

type AISimplifiedShenaiCanvas = {
  isSDKInitialized?: boolean;
};

const AISimplifiedShenaiCanvas = ({ isSDKInitialized }: AISimplifiedShenaiCanvas) => {
  return (
    <div
      style={{
        position: 'relative',
        width: '147px', // ratio 2/3
        height: '221px',
        borderRadius: '7px',
        overflow: 'hidden',
      }}
    >
      <canvas
        style={{
          opacity: !isSDKInitialized ? 0 : 1,
          aspectRatio: '2',
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        }}
        id="mxcanvas-ai-simplified"
      />

      {!isSDKInitialized && (
        <Loader className="absolute flex min-w-[600px] min-h-[300px] self-center items-center justify-center" />
      )}
    </div>
  );
};

const MemoAISimplifiedShenaiCanvas = memo(AISimplifiedShenaiCanvas);
MemoAISimplifiedShenaiCanvas.displayName = 'AISimplifiedShenaiCanvas';

export default MemoAISimplifiedShenaiCanvas;
