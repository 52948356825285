import ToggleableTabs from '@features/Tab/ToggleableTabs/ToggleableTabs';
import ValueTimePlot from '@features/Plot/ValueTimePlot';
import { TOGGLEABLE_SECTIONS } from '@components/RealtimeDashboard/model/constants/togglableSections';
import { shallowEqual, useSelector } from '@xstate/react';
import { dashboardActor } from '@components/RealtimeDashboard/model/xstate/dashboardMachine';

interface AISimplifiedGraphsToolProps {}

const AISimplifiedGraphsTool = ({}: AISimplifiedGraphsToolProps) => {
  const sections = useSelector(dashboardActor, (state) => state.context.sections, shallowEqual);
  const shenAIdata = useSelector(dashboardActor, (state) => state.context.sdkData, shallowEqual);
  const handleSectionToggle = (key: string) => {
    dashboardActor.send({ type: 'TOGGLE_SECTION', key });
  };

  return (
    <div className="flex flex-col items-center gap-3 w-full max-w-[640px]">
      <ToggleableTabs
        isShown={sections[TOGGLEABLE_SECTIONS.AI_SIMPLIFIED_MEASUREMENT_GRAPHS_HEARTRATE]}
        onToggle={() =>
          handleSectionToggle(TOGGLEABLE_SECTIONS.AI_SIMPLIFIED_MEASUREMENT_GRAPHS_HEARTRATE)
        }
        withCardStyle
        tabs={[
          {
            label: 'HR4',
            content: (
              <ValueTimePlot
                key="hr4"
                sdkData={shenAIdata}
                valueForPlot={shenAIdata?.hr4s}
                label="hr4"
                height={100}
              />
            ),
          },
          {
            label: 'HR10',
            content: (
              <ValueTimePlot
                key="hr10"
                sdkData={shenAIdata}
                valueForPlot={shenAIdata?.hr10s}
                label="hr10"
                height={100}
              />
            ),
          },
        ]}
        titleClasses="max-w-[200px]"
        additionalData={shenAIdata?.hr10s && `${shenAIdata.hr10s} bpm`}
      />

      <ToggleableTabs
        isShown={
          sections[TOGGLEABLE_SECTIONS.AI_SIMPLIFIED_MEASUREMENT_GRAPHS_HRVSDNN_CARDIAC_STRESS]
        }
        onToggle={() =>
          handleSectionToggle(
            TOGGLEABLE_SECTIONS.AI_SIMPLIFIED_MEASUREMENT_GRAPHS_HRVSDNN_CARDIAC_STRESS
          )
        }
        withCardStyle
        titleClasses="max-w-[200px]"
        tabs={[
          {
            label: 'SDNN',
            content: (
              <ValueTimePlot
                key="hrv-plot"
                sdkData={shenAIdata}
                valueForPlot={shenAIdata?.hrvSDNN}
                height={100}
              />
            ),
          },
          {
            label: 'Cardiac stress',
            content: (
              <ValueTimePlot
                key="cardiacStress-plot"
                sdkData={shenAIdata}
                valueForPlot={shenAIdata?.cardiacStressRT}
                height={100}
              />
            ),
          },
        ]}
      />
    </div>
  );
};

export default AISimplifiedGraphsTool;
