interface IGetCameraFrame {
  video: boolean;
  audio?: boolean;
}

export const getCameraFrame = async (constraints: IGetCameraFrame) => {
  const initialCameraId = localStorage.getItem('cameraId');
  try {
    const mediaStream = initialCameraId
      ? await navigator.mediaDevices.getUserMedia({
          video: { deviceId: { exact: initialCameraId } },
        })
      : await navigator.mediaDevices.getUserMedia(constraints);
    const mediaStreamTrack = mediaStream!.getVideoTracks()[0];
    const imageCapture = new ImageCapture(mediaStreamTrack);
    const imageBitmap: ImageBitmap = await imageCapture.grabFrame();

    const canvas = document.createElement('canvas');
    canvas.width = imageBitmap.width;
    canvas.height = imageBitmap.height;
    const ctx = canvas.getContext('bitmaprenderer');
    ctx?.transferFromImageBitmap(imageBitmap);
    return await new Promise<Blob | null>((res) => canvas.toBlob(res, 'image/jpeg'));
  } catch (error: any) {
    console.error('getCameraFrame error', JSON.stringify(error));
    return new Blob([], { type: 'image/jpeg' }); // fallback
  }
};
