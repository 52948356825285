import { RealtimeEventHandler } from '@openai/realtime-api-beta/dist/lib/event_handler';
import { memo, useEffect, useState } from 'react';
import { askChatGPT } from '@api/userAPI';

import InfoRow from '@shared/ui/caption/InfoRow';
import CharacteristicPlot from '@features/Plot/CharacteristicPlot';
import ToggleableTabs from '@features/Tab/ToggleableTabs/ToggleableTabs';
import { TOGGLEABLE_SECTIONS } from '../model/constants/togglableSections';
import { useSelector } from '@xstate/react';
import { dashboardActor } from '../model/xstate/dashboardMachine';
import { isUserTherapist } from '@utils/helpers';
import { Patient } from '@interfaces/patient';
import { refaelaPatientActor } from '@components/xState/machines/refaelaPatientMachine';
import { refaelaTherapistActor } from '@components/xState/machines/refaelaTherapistMachine';

type SuggestionDataProps = {
  sessionId: string;
  actor: typeof refaelaPatientActor | typeof refaelaTherapistActor;
  realtime: RealtimeEventHandler;
  onSectionToggleByKey: (key: string) => void;
};

const CharacteristicData = ({
  actor,
  sessionId,
  realtime,
  onSectionToggleByKey,
}: SuggestionDataProps) => {
  const [characteristicData, setCharacteristicData] = useState({
    openness: 0,
    introspection: 0,
    depthOfSharing: 0,
  });
  const [conversation, setConversation] = useState<string[]>([]);

  const sections = useSelector(dashboardActor, (state) => state.context.sections);
  const currentUser = useSelector(actor, (state) => state.context.currentUser)!;

  const userId = isUserTherapist(currentUser.role!)
    ? currentUser.therapistId!
    : (currentUser as Patient).patientId;

  useEffect(() => {
    const sessionCreateHandler = async () => {
      while (true) {
        const transcript_evt: any = await realtime.waitForNext(
          'server.conversation.item.input_audio_transcription.completed'
        );

        setConversation((prev) => [...prev, transcript_evt.transcript]);
      }
    };
    realtime.on('server.session.created', sessionCreateHandler);
    return () => {
      if ('server.session.created' in realtime) {
        try {
          realtime.off('server.session.created', sessionCreateHandler);
        } catch (e) {
          console.log('error removing event listener: ', e);
        }
      }
    };
  }, [realtime, realtime.eventHandlers]);

  useEffect(() => {
    const askAI = async () => {
      const { result = 0 } = await askChatGPT(
        userId,
        sessionId,
        conversation,
        'characteristic-ai-agent'
      );

      setCharacteristicData((prev) => ({
        openness: result?.openness || prev.openness,
        introspection: result?.introspection || prev.introspection,
        depthOfSharing: result?.depthOfSharing || prev.depthOfSharing,
      }));
    };

    conversation.length && askAI();
  }, [conversation]);

  const handleSectionToggle = (key: string) => {
    onSectionToggleByKey(key);
  };

  return (
    <div className="mb-4 space-y-4">
      <div className="flex flex-col gap-4 w-full">
        <ToggleableTabs
          isShown={sections[TOGGLEABLE_SECTIONS.CHARACTERISTIC_ANALYSIS]}
          onToggle={() => handleSectionToggle(TOGGLEABLE_SECTIONS.CHARACTERISTIC_ANALYSIS)}
          tabs={[
            {
              label: 'Characteristic analysis',
              content: (
                <div>
                  <div className="flex flex-col gap-4 w-full">
                    <InfoRow
                      key="openness"
                      label={'Openness'}
                      value={''}
                    />
                    <CharacteristicPlot
                      characteristicValue={characteristicData.openness}
                      label="Openness"
                    />
                  </div>
                  <div className="flex flex-col gap-4 w-full">
                    <InfoRow
                      key="introspection"
                      label={'Introspection'}
                      value={''}
                    />
                    <CharacteristicPlot
                      characteristicValue={characteristicData.introspection}
                      label="Introspection"
                    />
                  </div>
                  <div className="flex flex-col gap-4 w-full">
                    <InfoRow
                      key="depth-of-sharing"
                      label={'Depth Of Sharing'}
                      value=""
                    />
                    <CharacteristicPlot
                      characteristicValue={characteristicData.depthOfSharing}
                      label="Depth Of Sharing"
                    />
                  </div>
                </div>
              ),
            },
          ]}
        />
      </div>
    </div>
  );
};

export default memo(CharacteristicData);
