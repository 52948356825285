import { assignAISession, createAISession, getUserData } from '@api/userAPI';
import Button from '@components/Button';
import { EditPromptForm } from '@components/Forms/EditPrompt/EditPromptForm';
import { refaelaTherapistActor } from '@components/xState/machines/refaelaTherapistMachine';
import { Modal } from '@features/Modal/ui/Modal';
import { useSelector } from '@xstate/react';
import React, { useContext, useEffect, useState } from 'react';
import InvitePatient from '../InvitePatientStep';
import { AppContext } from '../../../contextApp';
import { AI_SESSION_PROMPT_BY_TYPE, SESSION_TYPES } from '@interfaces/constants';
import { SessionInscruction, SessionPromptsByType, SessionType } from './types';
import { SessionSettings } from '@components/RealtimeDashboard/types';
import { langfuseWeb } from '@utils/llm';

const AssignAISession: React.FC = () => {
  const snapshot: any = useSelector(refaelaTherapistActor, (state) => state);
  const patientId = snapshot.context.currentPatientId;

  const { patientToMeetId } = useContext(AppContext);

  const [sessionsCount, setSessionsCount] = useState<number>(1);
  const [notificationDays, setNotificationDays] = useState<number>(1);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isAssignSuccessfull, setIsAssignSuccessfull] = useState(false);
  const [isAssignInProgress, setIsAssignInProgress] = useState(false);
  const [userData, setUserData] = useState<any>(null);

  const [sessionType, setSessionType] = useState<SessionType>(SESSION_TYPES.AI_BIO_FEEDBACK);

  const [selectedLanguage, setSelectedLanguage] = useState('Hebrew');

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedLanguage(event.target.value);
  };

  const [langfusePromptByType, setLangfusePromptByType] = useState<SessionPromptsByType>({
    [SESSION_TYPES.AI_MEMORY_INTERVIEW]: '',
    [SESSION_TYPES.AI_FOLLOW_UP_CONVERSATION]: '',
    [SESSION_TYPES.AI_BIO_FEEDBACK]: '',
  });

  const [sessionPromptByType, setSessionPromptByType] = useState<SessionPromptsByType>({
    [SESSION_TYPES.AI_MEMORY_INTERVIEW]: '',
    [SESSION_TYPES.AI_FOLLOW_UP_CONVERSATION]: '',
    [SESSION_TYPES.AI_BIO_FEEDBACK]: '',
  });

  const [sessionInstructions, setSessionInstructions] = useState<SessionInscruction>({
    [SESSION_TYPES.AI_MEMORY_INTERVIEW]: { topic: '', thingsToAvoid: '', alertMeIf: '' },
    [SESSION_TYPES.AI_FOLLOW_UP_CONVERSATION]: {
      topic: '',
      thingsToAvoid: '',
      thingsToNote: '',
      alertMeIf: '',
    },
    [SESSION_TYPES.AI_BIO_FEEDBACK]: {
      topic: '',
      thingsToAvoid: '',
      thingsToNote: '',
      alertMeIf: '',
    },
  });

  const [isDataCollectionSubmitted, setIsDataCollectionSubmitted] = useState(false);
  const [isIncludeTranscriptSubmitted, setIsIncludeTranscriptSubmitted] = useState(false);

  const [sessionSettings] = useState<SessionSettings>({
    instructions: 'No instructions found',
    temperature: 0.8,
    voice: 'alloy',
    maxTokens: 1400,
    threshold: 0.7,
    prefixPadding: 300,
    silenceDuration: 1300,
  });

  // Handles radio button selection
  const handleRadioChange = (event: any) => {
    setSessionType(event.target.value);
    setSessionInstructions({
      [SESSION_TYPES.AI_MEMORY_INTERVIEW]: { topic: '', thingsToAvoid: '', alertMeIf: '' },
      [SESSION_TYPES.AI_FOLLOW_UP_CONVERSATION]: {
        topic: '',
        thingsToAvoid: '',
        thingsToNote: '',
        alertMeIf: '',
      },
      [SESSION_TYPES.AI_BIO_FEEDBACK]: {
        topic: '',
        thingsToAvoid: '',
        thingsToNote: '',
        alertMeIf: '',
      },
    });
  };

  const handleHomeworkAnnotationChange = <
    T extends keyof typeof sessionInstructions,
    F extends keyof (typeof sessionInstructions)[T],
  >(
    sessionType: T,
    field: F,
    value: string
  ) => {
    setSessionInstructions((prev) => ({
      ...prev,
      [sessionType]: {
        ...prev[sessionType],
        [field]: value,
      },
    }));
  };

  const handleDataCollectionSubmit = () => {
    setIsDataCollectionSubmitted((event) => !event);
  };

  const handleIncludeTranscriptSubmit = () => {
    setIsIncludeTranscriptSubmitted((event) => !event);
  };

  const handleAssignHomeworkSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsAssignInProgress(true);
    try {
      const response = await assignAISession(
        snapshot.value == 'AssignAISession' ? patientToMeetId: patientId,
        sessionType,
        sessionsCount,
        sessionInstructions[sessionType]
      );

      const createSessionPromises = Array.from({ length: sessionsCount }, () =>
        createAISession(
          `${patientToMeetId}_P`,
          sessionType,
          sessionInstructions[sessionType],
          sessionSettings,
          sessionPromptByType[sessionType]
        )
      );

      await Promise.all(createSessionPromises);
      if (response) {
        setIsAssignSuccessfull(true);
        setIsAssignInProgress(false);
        setTimeout(() => {
          setIsAssignSuccessfull(false);
        }, 3000);
      }
    } catch (error) {
      console.error('Error during AI session assign or creation: ', JSON.stringify(error));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (patientToMeetId) {
        try {
          const data = await getUserData(patientToMeetId);

          setUserData(data);
        } catch (e) {
          console.error('Error during fetchin user data: ', JSON.stringify(e));
        }
      }
    };

    fetchData();
  }, [patientToMeetId]);

  useEffect(() => {
    const fetchAllPrompts = async () => {
      if (userData) {
        const sessionTypes = Object.entries(AI_SESSION_PROMPT_BY_TYPE);

        const fetchPromises = sessionTypes.map(async (type) => {
          const prompt = (await langfuseWeb.getPrompt(type[1])).compile({
            user_name: userData.firstName,
          });
          const typeWithoutPostfix = type[0];
          return { typeWithoutPostfix, prompt };
        });

        const results = await Promise.all(fetchPromises);

        // Langfuse unedited prompts to restore prompt to initial value 
        setLangfusePromptByType((prevState) => {
          const updatedState = { ...prevState };
          results.forEach(({ typeWithoutPostfix, prompt }) => {
            updatedState[typeWithoutPostfix as keyof SessionPromptsByType] = prompt;
          });
          return updatedState;
        });

        setSessionPromptByType((prevState) => {
          const updatedState = { ...prevState };
          results.forEach(({ typeWithoutPostfix, prompt }) => {
            updatedState[typeWithoutPostfix as keyof SessionPromptsByType] = prompt;
          });
          return updatedState;
        });
      }
    };

    fetchAllPrompts();
  }, [userData]);

  return (
    <div className="w-full h-full">
      <p className="text-left mb-8">
        Here are the types of AI session you can assign to your patient so that they would progress
        in therapy between sessions.
      </p>
      {snapshot.value == 'AssignAISession' && (
        <InvitePatient
          setSessionId={() => {}}
          setRoomUrl={() => {}}
          onBlockStep={() => {}}
          sessionsCount={sessionsCount}
        />
      )}
      <form onSubmit={handleAssignHomeworkSubmit}>
        <div className="flex gap-4 text-left justify-between pl-12 pr-32">
          <div className="w-[50%]">
            <p className="font-bold">Select session type</p>
            <div className="my-4">
              <label>
                <input
                  type="radio"
                  value={SESSION_TYPES.AI_BIO_FEEDBACK}
                  checked={sessionType === SESSION_TYPES.AI_BIO_FEEDBACK}
                  onChange={handleRadioChange}
                />
                <span className="ml-4">Bio feedback session</span>
                {sessionType === SESSION_TYPES.AI_BIO_FEEDBACK && (
                  <span
                    onClick={() => setIsEditModalOpen(true)}
                    className="ml-4 font-bold text-primary3 cursor-pointer"
                  >
                    Edit
                  </span>
                )}
              </label>
              {sessionType === SESSION_TYPES.AI_BIO_FEEDBACK && (
                <div className="ml-8 my-4">
                  <div className="flex flex-col my-4 gap-2">
                    <p className="font-bold">What to focus on</p>
                    <input
                      className="placeholder-gray-400 border border-gray-200 rounded-lg w-[60%]"
                      type="text"
                      value={sessionInstructions[SESSION_TYPES.AI_BIO_FEEDBACK].topic}
                      onChange={(e) =>
                        handleHomeworkAnnotationChange(
                          SESSION_TYPES.AI_BIO_FEEDBACK,
                          'topic',
                          e.target.value
                        )
                      }
                      placeholder="Write a topic"
                    />
                  </div>
                  <div className="flex flex-col my-4 gap-2">
                    <p className="font-bold">Things to avoid during the session</p>
                    <div className="flex">
                      <textarea
                        className="placeholder-gray-400 border border-gray-200 rounded-lg w-[60%] h-20 resize-none"
                        value={sessionInstructions[SESSION_TYPES.AI_BIO_FEEDBACK].thingsToAvoid}
                        onChange={(e) =>
                          handleHomeworkAnnotationChange(
                            SESSION_TYPES.AI_BIO_FEEDBACK,
                            'thingsToAvoid',
                            e.target.value
                          )
                        }
                        placeholder="Things to avoid"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col my-4 gap-2">
                    <p className="font-bold">Things to note during the session</p>
                    <textarea
                      className="placeholder-gray-400 border border-gray-200 rounded-lg w-[60%] h-20 resize-none"
                      value={sessionInstructions[SESSION_TYPES.AI_BIO_FEEDBACK].thingsToNote}
                      onChange={(e) =>
                        handleHomeworkAnnotationChange(
                          SESSION_TYPES.AI_BIO_FEEDBACK,
                          'thingsToNote',
                          e.target.value
                        )
                      }
                      placeholder="Things to note"
                    />
                  </div>
                  <div className="flex flex-col my-4 gap-2">
                    <p className="font-bold">Alert me if...</p>
                    <textarea
                      className="placeholder-gray-400 border border-gray-200 rounded-lg w-[60%] h-20 resize-none"
                      value={sessionInstructions[SESSION_TYPES.AI_BIO_FEEDBACK].alertMeIf}
                      onChange={(e) =>
                        handleHomeworkAnnotationChange(
                          SESSION_TYPES.AI_BIO_FEEDBACK,
                          'thingsToNote',
                          e.target.value
                        )
                      }
                      placeholder="Things to note..."
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="my-4">
              <label>
                <input
                  type="radio"
                  value={SESSION_TYPES.AI_MEMORY_INTERVIEW}
                  checked={sessionType === SESSION_TYPES.AI_MEMORY_INTERVIEW}
                  onChange={handleRadioChange}
                />
                <span className="ml-4">Memory Interview</span>
                {sessionType === SESSION_TYPES.AI_MEMORY_INTERVIEW && (
                  <span
                    onClick={() => setIsEditModalOpen(true)}
                    className="ml-4 font-bold text-primary3 cursor-pointer"
                  >
                    Edit
                  </span>
                )}
              </label>
              {sessionType === SESSION_TYPES.AI_MEMORY_INTERVIEW && (
                <div className="ml-8 my-4 gap-4">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua.
                  </p>
                  <div className="flex flex-col my-4 gap-2">
                    <p className="font-bold">Topic of the Memory</p>
                    <input
                      className="placeholder-gray-400 border border-gray-200 rounded-lg w-[60%]"
                      type="text"
                      value={sessionInstructions[SESSION_TYPES.AI_MEMORY_INTERVIEW].topic}
                      onChange={(e) =>
                        handleHomeworkAnnotationChange(
                          SESSION_TYPES.AI_MEMORY_INTERVIEW,
                          'topic',
                          e.target.value
                        )
                      }
                      placeholder="Write a topic"
                    />
                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        id="personalDataCollection"
                        name="personalDataCollection"
                        checked={isDataCollectionSubmitted}
                        onChange={handleDataCollectionSubmit}
                      />
                      <span className="ml-4">Enable personal data collection</span>
                    </label>
                  </div>
                  <div className="flex flex-col my-4 gap-2">
                    <p className="font-bold">Things to avoid during the session</p>
                    <textarea
                      className="placeholder-gray-400 border border-gray-200 rounded-lg w-[60%] h-20 resize-none"
                      value={sessionInstructions[SESSION_TYPES.AI_MEMORY_INTERVIEW].thingsToAvoid}
                      onChange={(e) =>
                        handleHomeworkAnnotationChange(
                          SESSION_TYPES.AI_MEMORY_INTERVIEW,
                          'thingsToAvoid',
                          e.target.value
                        )
                      }
                      placeholder="Things to avoid"
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="my-4">
              <label>
                <input
                  type="radio"
                  value={SESSION_TYPES.AI_FOLLOW_UP_CONVERSATION}
                  checked={sessionType === SESSION_TYPES.AI_FOLLOW_UP_CONVERSATION}
                  onChange={handleRadioChange}
                />
                <span className="ml-4">Follow up Conversation</span>
                {sessionType === SESSION_TYPES.AI_FOLLOW_UP_CONVERSATION && (
                  <span
                    onClick={() => setIsEditModalOpen(true)}
                    className="ml-4 font-bold text-primary3 cursor-pointer"
                  >
                    Edit
                  </span>
                )}
              </label>
              {sessionType === 'follow_up_conversation' && (
                <div className="ml-8 my-4">
                  <div className="flex flex-col my-4 gap-2">
                    <p className="font-bold">What to focus on</p>
                    <input
                      className="placeholder-gray-400 border border-gray-200 rounded-lg w-[60%]"
                      type="text"
                      value={sessionInstructions[SESSION_TYPES.AI_FOLLOW_UP_CONVERSATION].topic}
                      onChange={(e) =>
                        handleHomeworkAnnotationChange(
                          SESSION_TYPES.AI_FOLLOW_UP_CONVERSATION,
                          'topic',
                          e.target.value
                        )
                      }
                      placeholder="Write a topic"
                    />
                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        id="includeTranscript"
                        name="includeTranscript"
                        checked={isIncludeTranscriptSubmitted}
                        onChange={handleIncludeTranscriptSubmit}
                      />
                      <span className="ml-4">
                        Include previous session transcripts in the context
                      </span>
                    </label>
                  </div>
                  <div className="flex flex-col my-4 gap-2">
                    <p className="font-bold">Things to avoid during the session</p>
                    <textarea
                      className="placeholder-gray-400 border border-gray-200 rounded-lg w-[60%] h-20 resize-none"
                      value={
                        sessionInstructions[SESSION_TYPES.AI_FOLLOW_UP_CONVERSATION].thingsToAvoid
                      }
                      onChange={(e) =>
                        handleHomeworkAnnotationChange(
                          SESSION_TYPES.AI_FOLLOW_UP_CONVERSATION,
                          'thingsToAvoid',
                          e.target.value
                        )
                      }
                      placeholder="Things to avoid"
                    />
                  </div>
                  <div className="flex flex-col my-4 gap-2">
                    <p className="font-bold">Things to note during the session</p>
                    <textarea
                      className="placeholder-gray-400 border border-gray-200 rounded-lg w-[60%] h-20 resize-none"
                      value={
                        sessionInstructions[SESSION_TYPES.AI_FOLLOW_UP_CONVERSATION].thingsToNote
                      }
                      onChange={(e) =>
                        handleHomeworkAnnotationChange(
                          SESSION_TYPES.AI_FOLLOW_UP_CONVERSATION,
                          'thingsToNote',
                          e.target.value
                        )
                      }
                      placeholder="Things to note"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col gap-4 w-[25%]">
            <div>
              <div className="font-bold">Number of sessions</div>
              <div>
                <select
                  className="border border-gray-200 rounded-lg"
                  id="sessionCountDropdown"
                  value={sessionsCount}
                  onChange={(e) => setSessionsCount(Number(e.target.value))}
                >
                  {[1, 2, 3, 4, 5].map((num) => (
                    <option
                      key={num}
                      value={num}
                    >
                      {num}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div>
              <div className="flex flex-col font-bold">Session Language</div>
              <div>
                <label>
                  <input
                    type="radio"
                    value="English"
                    checked={selectedLanguage == 'English'}
                    onChange={handleOptionChange}
                  />
                  <span className="ml-4">English</span>
                </label>
              </div>
              <div>
                <label>
                  <input
                    type="radio"
                    value="Hebrew"
                    checked={selectedLanguage == 'Hebrew'}
                    onChange={handleOptionChange}
                  />
                  <span className="ml-4">Hebrew</span>
                </label>
              </div>
            </div>
            <div>
              <div className="font-bold">Notify about Self-work every day</div>
              <div>
                <span>For next</span>
                <select
                  className="border border-gray-200 rounded-lg mx-2"
                  id="notificationDaysDropdown"
                  value={notificationDays}
                  onChange={(e) => setNotificationDays(Number(e.target.value))}
                >
                  {[1, 2, 3, 4, 5].map((num) => (
                    <option
                      key={num}
                      value={num}
                    >
                      {num}
                    </option>
                  ))}
                </select>
                <span>days</span>
              </div>
            </div>
            <Button
              className="w-[60%]"
              type="submit"
              text={isAssignInProgress ? 'Assigning Self-work...' : 'Assign Self-work'}
              disabled={!patientToMeetId || isAssignInProgress}
            />
            {isAssignSuccessfull && (
              <p className="overflow-auto">Session are assigned and created successfully</p>
            )}
          </div>
        </div>
      </form>
      <Modal
        isOpen={isEditModalOpen}
        title="Edit Prompt Template"
        onCancel={() => setIsEditModalOpen(false)}
      >
        {isEditModalOpen && (
          <EditPromptForm
            onCancel={() => setIsEditModalOpen(false)}
            error={''}
            sessionType={sessionType}
            langfusePrompt={langfusePromptByType[sessionType]}
            sessionPrompt={sessionPromptByType[sessionType]}
            setSessionPromptByType={setSessionPromptByType}
          />
        )}
      </Modal>
    </div>
  );
};

export default AssignAISession;
