import { useState, useEffect, useCallback, useRef, useContext, useMemo } from 'react';
import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';
//xState
import { useSelector } from '@xstate/react';
import { dashboardActor } from './model/xstate/dashboardMachine';
import { refaelaTherapistActor } from '@components/xState/machines/refaelaTherapistMachine';
import { refaelaPatientActor } from '@components/xState/machines/refaelaPatientMachine';
// Hooks
import { useRealtimeClient } from './hooks/useClient';
import { useShenai } from './model/hooks/useShenai';
import { useSentryForPage } from '@hooks/sentry/useSentryForPage';
import useSocketIOLogging from './hooks/useWebSocketLogging';
import { useWebSocket } from './../../websocket';
// Constants
import { PATIENT_MACHINE_EVENTS } from '@machines/patient/constants';
import {
  END_TYPE_OPTIONS,
  END_TYPE_OPTIONS_MAP,
} from '@modules/Patient/AISession/model/constants/options';
// Types
import { SESSION_TYPES } from '@interfaces/constants';
import { TherapistUser } from '@interfaces/therapist';
import { Patient } from '@interfaces/patient';
import { RealtimeEventHandler, SessionSettings } from './types';
import { RealtimeEventState } from './model/types/realtimeEvents';
import { STEPS_MAP } from './model/constants/stepsMap';
// Utils
import { SessionInfo, ConversationalModelProperties, MemoVisionAIModel } from './ui';
import RealtimeMeasurementGraphs from './ui/RealtimeMeasurementGraphs';
import { MemoRealtimeAi } from '../../modules/Patient/AISession/RealtimeAISession';
import { RealtimeClient } from '@openai/realtime-api-beta/dist/lib/client';
import { isUserTherapist } from '@utils/helpers';
import { getIdWithoutPrefix } from '@utils/prefixes';

import { WavRecorder, WavStreamPlayer } from '@modules/Patient/AISession/lib/wavtools';

import InfoRow from '@shared/ui/caption/InfoRow';
import MemoizedConnectSettingsSection from './ui/ConnectSettingsBlock';
import {
  cleanAuthToken,
  createAISession,
  endAISession,
  getAISession,
  startAISession,
} from '@api/userAPI';
import { fetchCurrentUserDetails } from '@api/user/me';
import AiSession from '@modules/Patient/AISession';
import { AppContext } from '../../contextApp';
import TopicDetectionAgent from './agents/TopicDetectionAgent';
import SummaryData from './ui/SummaryData';
import SuggestionData from './ui/SuggestionData';
import CharacteristicData from './ui/CharacteristicData';
import ConversationAnalysisData from './ui/ConversationAnalysisData';
import FrequentKeywordsData from './ui/FrequentKeywordsData';
import SpeakerActivationPlot from '@features/Plot/SpeakerActivationPlot';
import { ShamefulAny } from '@interfaces/index';
import Button from '@components/Button';
import MemoAISessionSimplified from '@components/AISession/AISessionSimplified';
import { defaultSessionSettings } from '@shared/constants/sessionSettings';

const RealtimeDashboard = ({
  actor,
}: {
  actor: typeof refaelaPatientActor | typeof refaelaTherapistActor;
}) => {
  useSentryForPage('RealtimeDashboardPage');

  const currentUser = useSelector(actor, (state) => state.context.currentUser);
  const sessionPrompt = useSelector(dashboardActor, (state) => state.context.sessionPrompt);
  const sesionType = useSelector(dashboardActor, (state) => state.context.sessionType);

  const userId =
    currentUser && isUserTherapist(currentUser.role!)
      ? currentUser.therapistId!
      : (currentUser as Patient).patientId;

  // Session settings
  const [sessionSettings, setSessionSettings] = useState<SessionSettings>(defaultSessionSettings);

  const [agentState, setAgentState] = useState({});
  const [isConnected, setIsConnected] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [canPushToTalk, setCanPushToTalk] = useState(true);
  const [, setRecordingStatus] = useState('Starting recording...');

  const [isSimplifiedSessionOpened, setSimplifiedSessionOpenned] = useState(true);

  const [roomUrl, setRoomUrl] = useState('');
  const [meetingToken, setMeetingToken] = useState('');
  const sessionId = roomUrl ? roomUrl.split('/').pop()! : '';

  const startTimeRef = useRef<string>(new Date().toISOString());

  const wavRecorderRef = useRef<WavRecorder>(new WavRecorder({ sampleRate: 24000 }));
  const wavStreamPlayerRef = useRef<WavStreamPlayer>(new WavStreamPlayer({ sampleRate: 24000 }));

  const agentStateBuffer = useRef<any[]>([]);
  const realtimeEventsBuffer = useRef<any[]>([]);

  const [selectedEndType, setSelectedEndType] = useState<Record<string, string>>({
    label: END_TYPE_OPTIONS_MAP[END_TYPE_OPTIONS.VAD],
    value: END_TYPE_OPTIONS.VAD,
  });

  const { isLogsSending } = useContext(AppContext);

  const realtimeClientConfig = useMemo(() => {
    return {
      voice: sessionSettings.voice,
      instructions: sessionPrompt,
      temperature: sessionSettings.temperature,
      maxTokens: sessionSettings.maxTokens,
      threshold: sessionSettings.threshold,
      prefixPadding: sessionSettings.prefixPadding,
      silenceDuration: sessionSettings.silenceDuration,
      turnDetectionType: selectedEndType.value,
    };
  }, [sessionSettings, sessionPrompt]);

  const { voiceBotInstanceRef, updateConfig, handleClientReady, handleConnectionChange } =
    useRealtimeClient(realtimeClientConfig);

  useSocketIOLogging(roomUrl, userId);
  const { socket } = useWebSocket();

  //FIXME: cause rerender whole layer each second
  const {
    isSDKInitialized,
    initializationSettings,
    setInitializationSettings,
    initializeShenAiSdk,
    deinitializeShenAiSdk,
    shenaiSDKRef,
  } = useShenai('ai_session', isLogsSending, userId, sessionId);

  const shenaiSDK = shenaiSDKRef.current;

  const saveAISession = useCallback(async () => {
    await handleDisconnectConversation();
    if (currentUser && userId) {
      await endAISession(userId, roomUrl, currentUser.role!, sessionSettings);
    }
  }, [currentUser, userId, roomUrl, sessionSettings]);

  useEffect(() => {
    if (roomUrl) {
      window.addEventListener('beforeunload', saveAISession);
    }

    return () => {
      if (roomUrl) {
        window.removeEventListener('beforeunload', saveAISession);
      }
    };
  }, [roomUrl]);

  const clearAuthToken = useCallback(async () => {
    try {
      if (!userId) return;

      await cleanAuthToken(getIdWithoutPrefix(userId));
    } catch (e) {
      console.error('Auth token clean issue');
    }
  }, [userId]);

  useEffect(() => {
    clearAuthToken();
    const createAndStartAISession = async () => {
      setSessionSettings((prev: any) => ({ ...prev, instructions: sessionPrompt }));
      try {
        if (!userId) return;
        const type =
          sesionType || currentUser?.assignedHomeworks?.type || SESSION_TYPES.AI_BIO_FEEDBACK;
        const { roomUrl, token } = await createAISession(
          userId,
          type,
          currentUser?.assignedHomeworks?.annotation || {},
          sessionSettings,
          sessionPrompt
        );

        setRoomUrl(roomUrl);
        setMeetingToken(token);
        if (roomUrl) {
          await startAISession(roomUrl, userId);
          setRecordingStatus('Recording...');
        }
      } catch (e: ShamefulAny) {
        e.sentryHandled = true;
        console.error('Error during session creation and start: ', JSON.stringify(e));
      }
    };

    const fetchAndstartAISession = async () => {
      try {
        if (!userId) return;

        const { roomUrl, token, prompt } = await getAISession(userId);

        setRoomUrl(roomUrl);
        setMeetingToken(token);
        setSessionSettings((prev) => ({ ...prev, instructions: prompt }));
        if (roomUrl) {
          await startAISession(roomUrl, userId);
          setRecordingStatus('Recording...');
        }
      } catch (e: ShamefulAny) {
        e.sentryHandled = true;
        console.error('Error during session fetch and start: ', JSON.stringify(e));
      }
    };

    isUserTherapist(currentUser?.role!) ? createAndStartAISession() : fetchAndstartAISession();
  }, []);

  const handleRealtimeEvent = useCallback(({ event }: RealtimeEventHandler) => {
    if (event.type === 'session.updated' || event.type === 'session.created') {
      if (event.session) {
        const { instructions, temperature, voice, maxTokens } = event.session;
        if (instructions) setSessionSettings((prev) => ({ ...prev, instructions: instructions }));
        if (temperature) setSessionSettings((prev) => ({ ...prev, temperature: temperature }));
        if (voice) setSessionSettings((prev) => ({ ...prev, voice: voice }));
        if (maxTokens) setSessionSettings((prev) => ({ ...prev, maxTokens: maxTokens }));
      }
    }
  }, []);

  useEffect(() => {
    if (voiceBotInstanceRef) {
      voiceBotInstanceRef.on('realtime.event', handleRealtimeEvent);

      return () => {
        if ('realtime.event' in voiceBotInstanceRef.eventHandlers) {
          try {
            voiceBotInstanceRef.off('realtime.event', handleRealtimeEvent);
          } catch (e: ShamefulAny) {
            e.sentryHandled = true;
            console.error('realtime event error:', JSON.stringify(e));
          }
        }
      };
    }
  }, [voiceBotInstanceRef]);

  // @ts-ignore
  const handleStateChanged = useCallback((state: RealtimeEventState) => {
    const { stepIndex, substepIndex } = STEPS_MAP[state.event.data.value as keyof typeof STEPS_MAP];
    const currentStepIndex = dashboardActor.getSnapshot().context.currentStepIndex;

    if (currentStepIndex < stepIndex) {
      dashboardActor.send({ type: 'NEXT_STEP' });
    }

    dashboardActor.send({
      type: 'MARK_ACTIVE_CHECK',
      stepIndex,
      checkIndex: substepIndex,
    });
  }, []);

  // @ts-ignore
  const handleTranscript = useCallback((transcript: string) => {
    // console.log('New transcript:', transcript);
  }, []);

  // @ts-ignore
  const handleAudio = useCallback((audioBlob: Blob) => {
    // console.log('Received audio blob:', audioBlob);
  }, []);

  // @ts-ignore
  const handleError = useCallback(
    (error: any) => {
      error.sentryHandled = true;
      console.error('AI Session error:', JSON.stringify(error));
    },
    [voiceBotInstanceRef]
  );

  const handleDeinitializeShenAISDK = useCallback(() => {
    deinitializeShenAiSdk();
  }, []);

  const handleInitializeShenAISDK = useCallback((settings: ShamefulAny) => {
    initializeShenAiSdk(settings, undefined);
  }, []);

  const handleSetInitializationSettings = useCallback((settings: ShamefulAny) => {
    setInitializationSettings(settings);
  }, []);

  const handleInstructionsChange = useCallback((newInstructions: string) => {
    setSessionSettings((prev) => ({ ...prev, instructions: newInstructions }));
  }, []);

  const handleUpdateInstructionsConfirm = useCallback(() => {
    updateConfig({ instructions: sessionSettings.instructions });
  }, [updateConfig, sessionSettings.instructions]);

  const handleTemperatureChange = useCallback(
    (newTemperature: number) => {
      setSessionSettings((prev) => ({ ...prev, temperature: newTemperature }));
      updateConfig({ temperature: newTemperature });

      const debounceSendSessionUpdate = debounce(() => {
        if (voiceBotInstanceRef?.isConnected()) {
          voiceBotInstanceRef.realtime.send('session.update', {
            session: { temperature: newTemperature },
          });
        }
      }, 1000);

      debounceSendSessionUpdate();
    },
    [voiceBotInstanceRef, updateConfig]
  );

  const handleVoiceChange = useCallback(
    (newVoice: string) => {
      setSessionSettings((prev) => ({ ...prev, voice: newVoice }));
      updateConfig({ voice: newVoice });
    },
    [updateConfig]
  );

  const handleMaxTokensChange = useCallback(
    (newMaxTokens: number) => {
      setSessionSettings((prev) => ({ ...prev, maxTokens: newMaxTokens }));
      updateConfig({ maxTokens: newMaxTokens });

      const debounceSendSessionUpdate = debounce(() => {
        if (voiceBotInstanceRef?.isConnected()) {
          voiceBotInstanceRef.realtime.send('session.update', {
            session: { maxTokens: newMaxTokens },
          });
        }
      }, 1000);

      debounceSendSessionUpdate();
    },
    [voiceBotInstanceRef, updateConfig]
  );

  //#region connection
  const handleConnectConversation = useCallback(
    async (_: string) => {
      try {
        if (!isConnected) {
          const wavRecorder = wavRecorderRef.current;
          const wavStreamPlayer = wavStreamPlayerRef.current;

          startTimeRef.current = new Date().toISOString();
          setIsConnected(true);

          voiceBotInstanceRef?.reset();
          handleSetEventHandlers(voiceBotInstanceRef, wavStreamPlayer);

          await wavStreamPlayer.connect();
          await voiceBotInstanceRef?.connect();
          await wavRecorder.begin();
          // this need to provide saveRealtimeEvents fn correct way to save data to
          voiceBotInstanceRef?.realtime.send('conversation.item.create', {
            item: {
              type: 'message',
              role: 'user',
              content: [{ type: 'input_text', text: sessionId }],
            },
          });

          voiceBotInstanceRef?.realtime.send('conversation.item.create', {
            item: {
              type: 'message',
              role: 'user',
              content: [{ type: 'input_text', text: userId }],
            },
          });

          if (voiceBotInstanceRef?.getTurnDetectionType() === 'server_vad') {
            await wavRecorder.record((data: any) =>
              voiceBotInstanceRef.appendInputAudio(data.mono)
            );
          }
          setCanPushToTalk(selectedEndType.value === END_TYPE_OPTIONS.MANUAL);
          handleConnectionChange?.(true);
        } else {
          console.log('Somehow not connected');
        }
      } catch (error: ShamefulAny) {
        error.sentryHandled = true;
        console.error(JSON.stringify(error));
        handleError(error);
        setIsConnected(false);
        handleConnectionChange?.(false);
      }
      return () => {
        if (isConnected) {
          handleDisconnectConversation();
        }
      };
    },
    [voiceBotInstanceRef, selectedEndType, sessionId, isConnected]
  );

  const handleDisconnectConversation = useCallback(async () => {
    try {
      setIsConnected(false);

      voiceBotInstanceRef?.disconnect();

      await wavRecorderRef.current.end();
      await wavStreamPlayerRef.current.interrupt();

      handleConnectionChange(false);
    } catch (error: ShamefulAny) {
      error.sentryHandled = true;
      console.error(JSON.stringify(error));

      handleError(error);
    }
  }, [voiceBotInstanceRef]);

  const handleFinishSession = useCallback(async () => {
    isConnected && (await saveAISession());
    if (!currentUser || !userId) return;
    setRecordingStatus('Recording stopped');
    if (isUserTherapist(currentUser.role!)) {
      const userData = await fetchCurrentUserDetails((currentUser as TherapistUser).therapistId!);
      refaelaTherapistActor.send({
        type: 'therapistUser.update',
        currentUser: userData as TherapistUser,
      });
      refaelaTherapistActor.send({ type: 'goToTherapistOffice' });
    } else {
      const userData = await fetchCurrentUserDetails((currentUser as Patient).patientId);
      refaelaPatientActor.send({
        type: 'UPDATE_PATIENT_USER',
        currentUser: userData as Patient,
      });
      refaelaPatientActor.send({ type: PATIENT_MACHINE_EVENTS.END_SESSION });
    }
  }, [isConnected, currentUser, userId, roomUrl]);

  const handleStartRecording = useCallback(async () => {
    try {
      setIsRecording(true);

      const wavRecorder = wavRecorderRef.current;
      const wavStreamPlayer = wavStreamPlayerRef.current;

      const trackSampleOffset = await wavStreamPlayer.interrupt();
      if (trackSampleOffset?.trackId) {
        const { trackId, offset } = trackSampleOffset;
        voiceBotInstanceRef?.cancelResponse(trackId, offset);
      }

      await wavRecorder.record((data: any) => voiceBotInstanceRef?.appendInputAudio(data.mono));
    } catch (error: ShamefulAny) {
      error.sentryHandled = true;
      handleError?.(error);
      setIsRecording(false);
    }
  }, []);

  const handleStopRecording = useCallback(async () => {
    try {
      setIsRecording(false);

      const wavRecorder = wavRecorderRef.current;
      await wavRecorder.pause();
      voiceBotInstanceRef?.createResponse();
    } catch (error: ShamefulAny) {
      error.sentryHandled = true;
      handleError?.(error);
    }
  }, []);

  const handleTresholdChange = useCallback((value: number) => {
    setSessionSettings((prev) => ({ ...prev, threshold: value }));
  }, []);

  const handlePrefixPaddingChange = useCallback((value: number) => {
    setSessionSettings((prev) => ({ ...prev, prefixPadding: value }));
  }, []);

  const handleSilenceDurationChange = useCallback((value: number) => {
    setSessionSettings((prev) => ({ ...prev, silenceDuration: value }));
  }, []);

  const handleSetEventHandlers = useCallback(
    (voiceBotInstanceRef: RealtimeClient, wavStreamPlayer: WavStreamPlayer) => {
      const throttleUpdateAgentState = throttle(
        () => {
          const prev = [...agentStateBuffer.current];
          agentStateBuffer.current = [];
          setAgentState(prev[prev.length - 1]);
        },
        2000,
        { trailing: false }
      );

      const throttleUpdateRealtimeEvents = throttle(
        () => {
          const prev = [...realtimeEventsBuffer.current];
          realtimeEventsBuffer.current = [];
          dashboardActor.send({
            type: 'REALTIME_EVENTS_UPDATE',
            data: prev.map((event) => ({
              ...event,
              role: wavStreamPlayer.stream ? 'assistant' : null,
            })),
          });
        },
        2000,
        { trailing: false }
      );

      // Event handlers
      voiceBotInstanceRef.on('realtime.event', (realtimeEvent: RealtimeEventState) => {
        // Handle state updates

        if (
          realtimeEvent.event.type === 'session.updated' ||
          realtimeEvent.event.type === 'session.created'
        ) {
          if (realtimeEvent.event?.session) {
            const { temperature, voice, maxTokens } = realtimeEvent.event.session;

            if (temperature) setSessionSettings((prev) => ({ ...prev, temperature: temperature }));
            if (voice) setSessionSettings((prev) => ({ ...prev, voice: voice }));
            if (maxTokens) setSessionSettings((prev) => ({ ...prev, maxTokens: maxTokens }));
          }
        }
        if (realtimeEvent.event?.type == 'state.updated') {
          agentStateBuffer.current.push(realtimeEvent.event?.data);
          throttleUpdateAgentState();
          setAgentState(realtimeEvent.event?.data);
          handleStateChanged?.(realtimeEvent);
        }
        const lastEvent = realtimeEventsBuffer.current[realtimeEventsBuffer.current.length - 1];
        if (lastEvent?.event.type === realtimeEvent.event.type) {
          lastEvent.count = (lastEvent.count || 0) + 1;
          realtimeEventsBuffer.current = [...realtimeEventsBuffer.current.slice(0, -1), lastEvent];
        } else {
          realtimeEventsBuffer.current.push({
            ...realtimeEvent,
            role: wavStreamPlayer.stream ? 'assistant' : null,
          });
        }

        throttleUpdateRealtimeEvents();
      });

      voiceBotInstanceRef.on('error', handleError);

      voiceBotInstanceRef.on('conversation.interrupted', async () => {
        const trackSampleOffset = await wavStreamPlayer.interrupt();
        if (trackSampleOffset?.trackId) {
          const { trackId, offset } = trackSampleOffset;
          voiceBotInstanceRef.cancelResponse(trackId, offset);
        }
      });

      voiceBotInstanceRef.on('conversation.updated', async ({ item, delta }: any) => {
        // we recieve delta only when AI assistant speaks

        if (delta?.audio) {
          // await is crucial here even though add16BitPCM is sync fn
          await wavStreamPlayer.add16BitPCM(delta.audio, item.id);

          const metadata = {
            userId,
            sessionId,
            itemId: item.id,
            type: item.type,
            audioLength: delta.audio.byteLength,
            sampleRate: 24000,
          };

          socket?.emit('save-ai-session-transcript-chunk', {
            metadata,
            audioChunk: Array.from(delta.audio), //Int16Array
          });
        }

        //TODO: trace transcript data cuz now it's breaking by serialization
        if (
          item.status === 'completed' &&
          item.formatted.audio?.length &&
          item.formatted.transcript
        ) {
          if (item.role === 'user') {
            socket?.emit('save-ai-session-transcript', {
              userId,
              sessionId,
              conversationItem: {
                ...item,
                formatted: {
                  ...item.formatted,
                  audio: Array.from(item.formatted.audio), //Int16Array
                },
              },
            });
          }

          if (item.role === 'assistant') {
            socket?.emit('save-ai-session-transcript', {
              userId,
              sessionId,
              conversationItem: {
                ...item,
                formatted: {
                  audio: null,
                  text: item.formatted.text,
                  transcript: item.formatted.transcript,
                },
              },
            });
          }
        }

        // TODO: save audio into local db not to have it in RAM
        // try {
        //   const wavFile = await WavRecorder.decode(item.formatted.audio, 24000, 24000);
        //   const wavBlob = new Blob([wavFile], { type: 'audio/wav' });
        //   const db = await openIndexedDB();
        //   const transaction = db.transaction(['audioFiles'], 'readwrite');
        //   const store = transaction.objectStore('audioFiles');
        //   store.put({
        //     id: item.id,
        //     file: wavBlob,
        //   });
        // } catch (error) {
        //   console.error('Error IndexedDB:', JSON.stringify(error));
        // }
        // }
      });
    },
    [userId, sessionId]
  );

  const handleChangeTurnEndType = useCallback(
    async ({ label, value }: { label: string; value: string }) => {
      const wavRecorder = wavRecorderRef.current;
      setSelectedEndType({ label, value: value as string });
      if (voiceBotInstanceRef?.realtime.isConnected()) {
        if (value === END_TYPE_OPTIONS.MANUAL && wavRecorder.getStatus() === 'recording') {
          await wavRecorder.pause();
        }

        (voiceBotInstanceRef.sessionConfig as any).turn_detection =
          value === END_TYPE_OPTIONS.MANUAL
            ? null
            : { ...voiceBotInstanceRef.defaultServerVadConfig, type: END_TYPE_OPTIONS.VAD };
        // Update turn detection
        voiceBotInstanceRef.realtime.send('session.update', {
          session: {
            turn_detection:
              value === END_TYPE_OPTIONS.MANUAL
                ? null
                : { ...voiceBotInstanceRef.defaultServerVadConfig, type: END_TYPE_OPTIONS.VAD },
          },
        });

        if (value === END_TYPE_OPTIONS.VAD) {
          await wavRecorder.record((data: any) => voiceBotInstanceRef.appendInputAudio(data.mono));
        }
      } else {
        if (voiceBotInstanceRef) {
          voiceBotInstanceRef.defaultSessionConfig.turn_detection =
            value === END_TYPE_OPTIONS.MANUAL
              ? null
              : { ...voiceBotInstanceRef?.defaultServerVadConfig, type: END_TYPE_OPTIONS.VAD };
        }
      }

      setCanPushToTalk(value === END_TYPE_OPTIONS.MANUAL);
    },
    []
  );
  //#endregion connection

  // Initial setup
  useEffect(() => {
    // Expose client reference
    handleClientReady?.(voiceBotInstanceRef);

    // Auto-connect if enabled
    if (false) {
      handleConnectConversation(sessionId!);
    }

    return () => {
      voiceBotInstanceRef?.reset();
    };
  }, []);

  const handleSectionToggleByKey = useCallback((key: string) => {
    dashboardActor.send({ type: 'TOGGLE_SECTION', key });
  }, []);

  const handleSimplifiedSession = useCallback(() => {
    setSimplifiedSessionOpenned((prev) => !prev);
  }, []);

  return (
    <div className="flex flex-col w-full h-full mt-6 ">
      <div
        className={`absolute w-full h-full bg-transparent transition-opacity duration-300 top-[-48px] ${
          isSimplifiedSessionOpened ? 'opacity-100 relative' : 'opacity-0 pointer-events-none'
        }`}
      >
        <MemoAISessionSimplified
          isSimplifiedSessionOpened={isSimplifiedSessionOpened}
          onConnectConversation={handleConnectConversation}
          isConnected={isConnected}
          sessionId={sessionId}
          onDisconnectConversation={handleDisconnectConversation}
          startTimeRef={startTimeRef}
          wavRecorderRef={wavRecorderRef}
          wavStreamPlayerRef={wavStreamPlayerRef}
          isSDKInitialized={isSDKInitialized}
          onSetInitializationSettings={handleSetInitializationSettings}
          onFinishSession={handleFinishSession}
          shenaiSDK={shenaiSDK}
          onPauseSession={handleChangeTurnEndType}
          onOpenAdvancedMode={setSimplifiedSessionOpenned}
          realtimeClientInstance={voiceBotInstanceRef}
        />
      </div>

      <div
        className={`absolute w-full h-full transition-opacity duration-300 ${
          !isSimplifiedSessionOpened ? 'opacity-100 relative' : 'opacity-0 pointer-events-none'
        }`}
      >
        <Button
          variant="outlinedPrimary"
          text={isSimplifiedSessionOpened ? 'Close simplified session' : 'Open simplified session'}
          className="max-w-fit z-20"
          onClick={handleSimplifiedSession}
        />
        <div className="grid grid-cols-[25%_50%_25%] gap-4 p-6 w-full text-left">
          <div className="bg-transparent rounded-lg">
            <MemoVisionAIModel
              key="vision-ai-model"
              shenaiSDK={shenaiSDK}
              isSDKInitialized={isSDKInitialized}
              initializationSettings={initializationSettings}
              onSectionToggleByKey={handleSectionToggleByKey}
              onSetInitializationSettings={handleSetInitializationSettings}
              onInitializeShenAiSdk={handleInitializeShenAISDK}
              onDeinitializeShenAiSdk={handleDeinitializeShenAISDK}
            />
          </div>
          <div className="bg-transparent p-4 rounded-lg col-span-1.5">
            <SessionInfo
              key="session-info-shenai-window"
              isSDKInitialized={isSDKInitialized}
            />
            <MemoRealtimeAi
              key="realtime-ai"
              onSectionToggleByKey={handleSectionToggleByKey}
              instructions={sessionSettings.instructions}
              isConnected={isConnected}
              onInstructionsChange={handleInstructionsChange}
              onInstructionsUpdate={handleUpdateInstructionsConfirm}
              startTimeRef={startTimeRef}
              wavRecorderRef={wavRecorderRef}
              wavStreamPlayerRef={wavStreamPlayerRef}
              realtimeClientInstance={voiceBotInstanceRef}
              agentState={agentState}
              hideUI={false}
            />
            <RealtimeMeasurementGraphs onSectionToggleByKey={handleSectionToggleByKey} />
            {voiceBotInstanceRef && (
              <div>
                <SummaryData
                  key="summaryAgent"
                  actor={actor}
                  sessionId={sessionId}
                  realtime={voiceBotInstanceRef?.realtime}
                  onSectionToggleByKey={handleSectionToggleByKey}
                />
                <SuggestionData
                  key="suggestionAgent"
                  actor={actor}
                  sessionId={sessionId}
                  realtime={voiceBotInstanceRef?.realtime}
                  onSectionToggleByKey={handleSectionToggleByKey}
                />
              </div>
            )}
          </div>
          <div className="bg-transparent p-4 rounded-lg">
            <ConversationalModelProperties
              key="conversational-model-properties"
              onSectionToggleByKey={handleSectionToggleByKey}
              initialTemperature={sessionSettings.temperature}
              initialVoice={sessionSettings.voice}
              initialMaxTokens={sessionSettings.maxTokens}
              initialThreshold={sessionSettings.threshold}
              initialPrefixPadding={sessionSettings.prefixPadding}
              initialSilenceDuration={sessionSettings.silenceDuration}
              onTemperatureChange={handleTemperatureChange}
              onVoiceChange={handleVoiceChange}
              onMaxTokensChange={handleMaxTokensChange}
              onThresholdChange={handleTresholdChange}
              onPrefixPaddingChange={handlePrefixPaddingChange}
              onSilenceDurationChange={handleSilenceDurationChange}
              allowVoiceChange={!voiceBotInstanceRef?.isConnected()}
            />
            {sessionId && (
              <MemoizedConnectSettingsSection
                key="connect-settings-section"
                onStartRecording={handleStartRecording}
                onStopRecording={handleStopRecording}
                onConnectConversation={handleConnectConversation}
                onDisconnectConversation={handleDisconnectConversation}
                onChangeTurnEndType={handleChangeTurnEndType}
                onFinishSession={handleFinishSession}
                isConnected={isConnected}
                isRecording={isRecording}
                canPushToTalk={canPushToTalk}
                selectedEndType={selectedEndType}
                sessionId={sessionId}
                setRecordingStatus={setRecordingStatus}
              />
            )}
            <div className="flex flex-col space-y-4 w-full">
              <InfoRow
                key="Speaker-activation-state"
                label={'Speaker activation'}
                value={''}
              />
              <SpeakerActivationPlot
                key="speaker"
                isConnected={isConnected}
                voiceBotInstance={voiceBotInstanceRef}
                sessionId={sessionId}
                userId={userId}
              />
            </div>
            {voiceBotInstanceRef && (
              <div className="flex flex-col gap-4">
                <FrequentKeywordsData
                  sessionId={sessionId}
                  actor={actor}
                  realtime={voiceBotInstanceRef?.realtime}
                  onSectionToggleByKey={handleSectionToggleByKey}
                />
                <TopicDetectionAgent
                  sessionId={sessionId}
                  actor={actor}
                  key="topicDetectionAgent"
                  realtime={voiceBotInstanceRef?.realtime}
                  onSectionToggleByKey={handleSectionToggleByKey}
                />
                <ConversationAnalysisData
                  sessionId={sessionId}
                  actor={actor}
                  key="ConversationAnalysisData"
                  realtime={voiceBotInstanceRef?.realtime}
                  onSectionToggleByKey={handleSectionToggleByKey}
                />
                <CharacteristicData
                  sessionId={sessionId}
                  actor={actor}
                  key="CharacteristicData"
                  realtime={voiceBotInstanceRef?.realtime}
                  onSectionToggleByKey={handleSectionToggleByKey}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      {roomUrl && (
        // AI Session takes place when isSimplifiedSessionOpened, so I change position to absolute
        <div className={` ${!isSimplifiedSessionOpened ? 'absolute' : 'relative'}`}>
          <AiSession
            actor={
              currentUser && isUserTherapist(currentUser.role!)
                ? refaelaTherapistActor
                : refaelaPatientActor
            }
            roomUrl={roomUrl}
            meetingToken={meetingToken}
          />
        </div>
      )}
    </div>
  );
};

export default RealtimeDashboard;
