import React, { useState } from 'react';

interface InputRangeProps {
  label: string;
  min: number;
  max: number;
  step: number;
  defaultValue: number;
  onChange?: (value: number) => void;
  isDisabled?: boolean; 
}

const InputRange: React.FC<InputRangeProps> = ({
  label,
  min,
  max,
  step,
  defaultValue,
  onChange,
  isDisabled = false,
}) => {
  const [value, setValue] = useState(defaultValue);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseFloat(event.target.value);
    setValue(newValue);
    if (onChange) onChange(newValue);
  };

  return (
    <div className="mb-4">
      <div className="flex justify-between items-center">
        <label className="text-black text-[13px] font-bold font-['Inter'] leading-normal">
          {label}
        </label>
        <span className="text-right text-black text-[13px] font-normal font-['Inter'] leading-normal">
          {value}
        </span>
      </div>
      <input
        type="range"
        className="w-full h-[4px] rounded-full appearance-none"
        style={{
          background: `linear-gradient(to right, #9333ea ${((value - min) / (max - min)) * 100}%, #d1d5db ${((value - min) / (max - min)) * 100}%)`,
        }}
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={isDisabled ? () => {} : handleChange}
      />
      <style>
        {`
       
          /* Thumb styling for WebKit browsers */
          input[type="range"]::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 10px;
            height: 10px;
            background-color: #9333ea;
            border-radius: 50%;
            border: 1px solid #fff;
            cursor: pointer;
            position: relative;
            top: 5px;
            transform: translateY(-50%);
          }
          
          /* Thumb styling for Firefox */
          input[type="range"]::-moz-range-thumb {
            width: 10px;
            height: 10px;
            background-color: #9333ea;
            border-radius: 50%;
            border: 1px solid #fff;
            cursor: pointer;
            position: relative;
            top: 5px;
            transform: translateY(-50%);
          }
          
          /* Thumb styling for Internet Explorer and Edge */
          input[type="range"]::-ms-thumb {
            width: 10px;
            height: 10px;
            background-color: #9333ea;
            border-radius: 50%;
            border: 1px solid #fff;
            cursor: pointer;
            position: relative;
            top: 5px;
            transform: translateY(-50%);
          }
        `}
      </style>
    </div>
  );
};

export default InputRange;
