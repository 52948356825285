import { useEffect, useRef, useState } from 'react';
import { useSelector, shallowEqual } from '@xstate/react';
import { dashboardActor } from '@components/RealtimeDashboard/model/xstate/dashboardMachine';

type RealtimeChecksProps = {
  isConnected: boolean;
};

export const useRealtimeChecks = ({ isConnected }: RealtimeChecksProps) => {
  const shenAIdata = useSelector(dashboardActor, (state) => state.context.sdkData, shallowEqual);
  const [isShowWarning, setShowWarning] = useState(false);
  const [isShowCanvas, setShowCanvas] = useState(false);

  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    if (shenAIdata && (shenAIdata?.hr10s > 100 || shenAIdata?.cardiacStressRT > 4)) {
      setShowWarning(true);
    } else {
      setShowWarning(false);
    }
  }, [shenAIdata?.hr10s, shenAIdata?.hrvSDNN, shenAIdata?.cardiacStressRT]);

  useEffect(() => {
    if (!isConnected) return;
    const checkCanvasVisibility = () => {
      const x = shenAIdata?.bbox?.x;
      const isCanvasVisible = x === undefined || x > 0.34 || x < 0;

      if (isCanvasVisible) {
        setShowCanvas(true);

        timerRef.current = setTimeout(() => {
          checkCanvasVisibility(); // Re-check
        }, 3000);
      } else {
        setShowCanvas(false);

        // Schedule the next check
        timerRef.current = setTimeout(() => {
          checkCanvasVisibility();
        }, 3000);
      }
    };

    checkCanvasVisibility();

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [shenAIdata?.bbox?.x, isConnected]);

  return { isShowWarning, isShowCanvas };
};
