import { memo, useEffect, useState } from 'react';
import { useSelector } from '@xstate/react';

import ToggleableTabs from '@features/Tab/ToggleableTabs/ToggleableTabs';
import { dashboardActor } from '../model/xstate/dashboardMachine';
import { TOGGLEABLE_SECTIONS } from '../model/constants/togglableSections';
import { RealtimeEventHandler } from '@openai/realtime-api-beta/dist/lib/event_handler';
import { askChatGPT } from '@api/userAPI';
import { refaelaTherapistActor } from '@components/xState/machines/refaelaTherapistMachine';
import { refaelaPatientActor } from '@components/xState/machines/refaelaPatientMachine';
import { isUserTherapist } from '@utils/helpers';
import { Patient } from '@interfaces/patient';

type SummaryDataProps = {
  sessionId: string;
  actor: typeof refaelaPatientActor | typeof refaelaTherapistActor;
  realtime: RealtimeEventHandler;
  onSectionToggleByKey: (key: string) => void;
};

const SummaryData = ({ actor, sessionId, realtime, onSectionToggleByKey }: SummaryDataProps) => {
  const sections = useSelector(dashboardActor, (state) => state.context.sections);
  const [conversation, setConversation] = useState<string[]>([]);

  const [summaryData, setSummaryData] = useState({
    mainTopic: 'Waiting for data...',
    mostMeaningfulStatement: 'Waiting for data...',
    summary: 'Waiting for data...',
  });

  const currentUser = useSelector(actor, (state) => state.context.currentUser)!;

  const userId = isUserTherapist(currentUser.role!)
    ? currentUser.therapistId!
    : (currentUser as Patient).patientId;

  const handleSectionToggle = (key: string) => {
    onSectionToggleByKey(key);
  };

  useEffect(() => {
    const sessionCreateHandler = async () => {
      while (true) {
        const transcript_evt: any = await realtime.waitForNext(
          'server.conversation.item.input_audio_transcription.completed'
        );
        setConversation((prev) => [...prev, transcript_evt.transcript]);
      }
    };
    realtime.on('server.session.created', sessionCreateHandler);
    return () => {
      if ('server.session.created' in realtime) {
        try {
          realtime.off('server.session.created', sessionCreateHandler);
        } catch (e) {
          console.log('error removing event listener: ', e);
        }
      }
    };
  }, [realtime, realtime.eventHandlers]);

  useEffect(() => {
    const askAI = async () => {
      try {
        const { result } = await askChatGPT(userId, sessionId, conversation, 'history-ai-agent');

        setSummaryData((prev) => ({
          mainTopic: result?.mainTopic || prev.mainTopic,
          mostMeaningfulStatement: result?.mostMeaningfulStatement || prev.mostMeaningfulStatement,
          summary: result?.summary || prev.summary,
        }));
      } catch (e) {
        console.error('Error asking gpt in summary data', JSON.stringify(e));
      }
    };

    conversation.length && askAI();
  }, [conversation]);

  return (
    <div className="mb-4 space-y-4">
      <ToggleableTabs
        isShown={sections[TOGGLEABLE_SECTIONS.SUMMARY]}
        onToggle={() => handleSectionToggle(TOGGLEABLE_SECTIONS.SUMMARY)}
        withCardStyle
        tabs={[
          {
            label: 'Current Summary: ',
            content: (
              <div className="flex flex-col">
                <div className="mb-4">
                  <span className="text-black text-[13px] font-bold font-['Inter'] leading-normal">
                    Main Topic
                  </span>

                  <p>{summaryData.mainTopic}</p>
                </div>
                <div className="mb-4">
                  <span className="text-black text-[13px] font-bold font-['Inter'] leading-normal">
                    Most Meaningful Statement
                  </span>

                  <p>{summaryData.mostMeaningfulStatement}</p>
                </div>
                <div className="mb-4">
                  <span className="text-black text-[13px] font-bold font-['Inter'] leading-normal">
                    Summary
                  </span>

                  <p>{summaryData.summary}</p>
                </div>
              </div>
            ),
          },
        ]}
        key="rtSummary"
      />
    </div>
  );
};

export default memo(SummaryData);
