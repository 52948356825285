import { MEASUREMENT_STATE } from '../constants/states';

export const createRedAndOrangeLine = (
  valueStates: { value: number; stateValue?: number; timestamp: number }[],
  timeThreshold: number = 1500
) => {
  const redLine = new Array(valueStates.length).fill(null);
  const orangeLine = new Array(valueStates.length).fill(null);

  let startIndex: number | null = null;

  for (let i = 0; i < valueStates.length; i++) {
    const current = valueStates[i];
    const prev = valueStates[i - 1];
    const next = valueStates[i + 1];

    if (current.stateValue !== MEASUREMENT_STATE.RUNNING_SIGNAL_GOOD && startIndex === null) {
      startIndex = i;

      if (prev?.stateValue === MEASUREMENT_STATE.RUNNING_SIGNAL_GOOD) {
        redLine[i - 1] = prev.value;
      }
    }

    if (startIndex !== null) {
      redLine[i] = current.value;

      if (
        next?.stateValue === MEASUREMENT_STATE.RUNNING_SIGNAL_GOOD ||
        i === valueStates.length - 1
      ) {
        if (next?.stateValue === MEASUREMENT_STATE.RUNNING_SIGNAL_GOOD) {
          redLine[i + 1] = next.value;
        }
        startIndex = null;
      }
    }

    if (next && next.timestamp - current.timestamp > timeThreshold) {
      orangeLine[i] = current.value;
      orangeLine[i + 1] = next.value;
    }
  }

  return { redLine, orangeLine };
};

export const createPurpleAndOrangeLine = (
  valueStates: { value: number; stateValue?: number; timestamp: number }[],
  timeThreshold: number = 1500
) => {
  const purpleLine = valueStates.map((entry) =>
    entry.stateValue === MEASUREMENT_STATE.RUNNING_SIGNAL_GOOD ? entry.value : null
  );

  const orangeLine = new Array(valueStates.length).fill(null);

  for (let i = 0; i < valueStates.length - 1; i++) {
    const current = valueStates[i];
    const next = valueStates[i + 1];

    if (current.stateValue === MEASUREMENT_STATE.RUNNING_SIGNAL_GOOD && next) {
      if (next.timestamp - current.timestamp > timeThreshold) {
        orangeLine[i] = current.value;
        orangeLine[i + 1] = next.value;
      }
    }
  }

  return { purpleLine, orangeLine };
};
