import { ShamefulAny } from '@interfaces/index';
import { apiClient } from '..';

export const saveRealtimeAgentData = async (
  events: any,
  sessionId: string,
  userId: string,
  agentName: string
): Promise<any> => {
  try {
    const { data: response } = await apiClient.post('/ai/saveRealtimeAgentData', {
      events,
      sessionId,
      userId,
      agentName,
    });

    return { response };
  } catch (e: ShamefulAny) {
    e.sentryHandled = true;
    console.error('Error asking llm:', e.message || JSON.stringify(e));
  }
};
