import { getCameraFrame } from '../services/media';
import {
  sendCameraFrame,
  ISendCameraFrameResponseBody,
  sendCameraFrameTherapist,
  sendCameraFramePatient,
  sendAICameraFrame,
} from '../api/frame';
import { blobToBase64 } from '../utils/blob';
import { Base64 } from '../hooks/useExtractFaceEmotions';

export const postCameraFrame = async (
  userId: string
): Promise<void | [ISendCameraFrameResponseBody, Base64]> => {
  try {
    const blob = await getCameraFrame({ video: true });
    const base64 = await blobToBase64(blob as Blob);
    const timestamp = Date.now();
    const res = await sendCameraFrame(userId, base64, timestamp);

    return [res, base64];
  } catch (e: any) {
    console.error('postCameraFrame error', JSON.stringify(e));
  }
};

export const postAiCameraFrame = async (
  userId: string,
  sessionId: string,
  signal?: AbortSignal
): Promise<void | [ISendCameraFrameResponseBody, Base64]> => {
  try {
    const blob = await getCameraFrame({ video: true });

    const base64 = await blobToBase64(blob as Blob);
    const timestamp = Date.now();
    const res = await sendAICameraFrame(userId, sessionId, base64, timestamp, signal);

    return [res, base64];
  } catch (e: any) {
    console.error('postAiCameraFrame error', JSON.stringify(e));
  }
};

export const postCameraFrameTherapist = async (
  userId: string,
  patientId: string,
  sessionId: string
): Promise<void | [ISendCameraFrameResponseBody, Base64]> => {
  try {
    const blob = await getCameraFrame({ video: true });
    const base64 = await blobToBase64(blob as Blob);
    const timestamp = Date.now();
    const res = await sendCameraFrameTherapist(userId, patientId, sessionId, base64, timestamp);

    return [res, base64];
  } catch (e: any) {
    console.error('postCameraFrameTherapist error', JSON.stringify(e));
  }
};
export const postCameraFramePatient = async (
  userId: string,
  sessionId: string
): Promise<void | [ISendCameraFrameResponseBody, Base64]> => {
  try {
    const blob = await getCameraFrame({ video: true });
    const base64 = await blobToBase64(blob as Blob);
    const timestamp = Date.now();
    const res = await sendCameraFramePatient(userId, sessionId, base64, timestamp);

    return [res, base64];
  } catch (e: any) {
    console.error('postCameraFramePatient error', JSON.stringify(e));
  }
};
