import React, { memo } from 'react';
import InputRange from '@shared/ui/input/range/InputRange';
import ToggleableTabs from '@features/Tab/ToggleableTabs/ToggleableTabs';
import { useSelector } from '@xstate/react';
import { dashboardActor } from '../../RealtimeDashboard/model/xstate/dashboardMachine';
import { TOGGLEABLE_SECTIONS } from '../../RealtimeDashboard/model/constants/togglableSections';
import { defaultSessionSettings } from '@shared/constants/sessionSettings';

interface SelfReviewConversationalModelPropertiesProps {
  initialVoice: string;
  initialTemperature: number;
  initialMaxTokens: number;
  initialThreshold: number;
  initialPrefixPadding: number;
  initialSilenceDuration: number;
}

const SelfReviewConversationalModelProperties: React.FC<
  SelfReviewConversationalModelPropertiesProps
> = ({
  initialVoice = defaultSessionSettings.voice,
  initialTemperature = defaultSessionSettings.temperature,
  initialMaxTokens = defaultSessionSettings.maxTokens,
  initialThreshold = defaultSessionSettings.threshold,
  initialPrefixPadding = defaultSessionSettings.prefixPadding,
  initialSilenceDuration = defaultSessionSettings.silenceDuration,
}) => {
  const sections = useSelector(dashboardActor, (state) => state.context.sections);

  const handleSectionToggle = (key: string) => {
    dashboardActor.send({ type: 'TOGGLE_SECTION', key });
  };

  return (
    <div className="w-full">
      <h2 className="text-xl font-bold mb-4">Conversational AI Model</h2>
      <ToggleableTabs
        onToggle={() => handleSectionToggle(TOGGLEABLE_SECTIONS.VOICEAI_SETTINGS)}
        isShown={sections[TOGGLEABLE_SECTIONS.VOICEAI_SETTINGS]}
        tabs={[
          {
            label: 'Model settings',
            content: (
              <>
                <div className="mb-4 w-full relative">
                  <span className="text-black text-[13px] font-bold font-['Inter'] leading-normal">
                    Voice: {initialVoice}
                  </span>
                </div>
                <InputRange
                  label="Threshold"
                  min={0}
                  max={1}
                  step={0.01}
                  defaultValue={initialThreshold}
                  isDisabled
                />
                <InputRange
                  label="Prefix Padding"
                  min={0}
                  max={1000}
                  step={1}
                  defaultValue={initialPrefixPadding}
                  isDisabled
                />
                <InputRange
                  label="Silence Duration"
                  min={0}
                  max={5000}
                  step={10}
                  defaultValue={initialSilenceDuration}
                  isDisabled
                />
                <InputRange
                  label="Model Configuration (Temperature)"
                  min={0.6}
                  max={1}
                  step={0.01}
                  defaultValue={initialTemperature}
                  isDisabled
                />
                <InputRange
                  label="Max Tokens"
                  min={50}
                  max={1000}
                  step={10}
                  defaultValue={initialMaxTokens || 150}
                  isDisabled
                />
              </>
            ),
          },
        ]}
      />
    </div>
  );
};

export default memo(SelfReviewConversationalModelProperties);
