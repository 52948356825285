export const SESSION_TYPES = {
  THERAPIST: 'session_with_therapist',
  AI_BIO_FEEDBACK: 'bio_feedback_session',
  AI_MEMORY_INTERVIEW: 'memory_interview',
  AI_FOLLOW_UP_CONVERSATION: 'follow_up_conversation',
} as const;

export const AI_SESSION_PROMPT_BY_TYPE = {
  bio_feedback_session: 'ai-bio-feedback-1',
  memory_interview: 'ai-memory-interview-1',
  follow_up_conversation: 'ai-follow-up-conversation-1',
} as const;

export const SESSION_PROMPTS = {
  AI_BIO_FEEDBACK_1: 'ai-bio-feedback-1',
  AI_BIO_FEEDBACK_2: 'ai-bio-feedback-2',
  AI_BIO_FEEDBACK_3: 'ai-bio-feedback-3',
  AI_MEMORY_INTERVIEW_1: 'ai-memory-interview-1',
  AI_MEMORY_INTERVIEW_2: 'ai-memory-interview-2',
  AI_MEMORY_INTERVIEW_3: 'ai-memory-interview-3',
  AI_FOLLOW_UP_CONVERSATION_1: 'ai-follow-up-conversation-1',
  AI_FOLLOW_UP_CONVERSATION_2: 'ai-follow-up-conversation-2',
  AI_FOLLOW_UP_CONVERSATION_3: 'ai-follow-up-conversation-3',
} as const;

export const AI_SESSION_TYPES: string[] = [
  SESSION_TYPES.AI_BIO_FEEDBACK,
  SESSION_TYPES.AI_FOLLOW_UP_CONVERSATION,
  SESSION_TYPES.AI_MEMORY_INTERVIEW,
] as const;

export const AI_SESSION_PROMPT_BY_SESSION_TYPE = {
  'Bio Feedback': ['ai-bio-feedback-1', 'ai-bio-feedback-2', 'ai-bio-feedback-3'],
  'Memory Interview': ['ai-memory-interview-1', 'ai-memory-interview-2', 'ai-memory-interview-3'],
  'Follow Up Conversation': [
    'ai-follow-up-conversation-1',
    'ai-follow-up-conversation-2',
    'ai-follow-up-conversation-3',
  ],
} as const;

export const AI_SESSION_TYPES_NORMALISED: string[] = [
  'Bio Feedback',
  'Follow Up Conversation',
  'Memory Interview',
] as const;
