import { forwardRef, useImperativeHandle, useState } from 'react';
import './styles/circlesAnimation.css';
import { normalizeArray, normalizeArrayMic } from './utils';

type MicTestVisualizerProps = {
  width: number;
  height: number;
  isWarning: boolean;
  wrapperClassName?: string;
  clientFrequencies?: number[];
  serverFrequencies?: number[];
  mode?: 'micTest' | 'clientServer';
};

export const AUDIO_SOURCE = {
  SERVER: 'server',
  CLIENT: 'client',
  MICROPHONE: 'microphone',
};

export type MicTestVisualizerRef = {
  getFrequencies: (type: 'client' | 'server') => { values: number[] };
  updateVisualizer: (frequencies: Float32Array, source: string) => void;
};

const MicTestVisualizer = forwardRef<MicTestVisualizerRef, MicTestVisualizerProps>(
  ({ width, height, wrapperClassName, clientFrequencies, serverFrequencies, isWarning }, ref) => {
    const [frequencies, setCurrentFrequencies] = useState<number[] | Float32Array>([]);
    const [audioSource, setAudioSource] = useState<string>('');

    const innerCircleWidth = 70;

    const circleConfigs = [
      { offset: -3, left: -3, animateClass: 'animate-rotate-slow-medium', opacityBase: 0.3 },
      { offset: -5, left: -5, animateClass: 'animate-rotate-slow', opacityBase: 0.6 },
      { offset: 0, left: 3, animateClass: 'animate-rotate-medium', opacityBase: 0.4 },
      { offset: 5, left: 5, animateClass: 'animate-rotate-slow-medium', opacityBase: 0.3 },
      { offset: 7, left: 7, animateClass: 'animate-rotate-fast', opacityBase: 0.4 },
      { offset: 0, left: 0, animateClass: 'animate-rotate-fast', opacityBase: 0.5 },
    ];

    useImperativeHandle(ref, () => ({
      getFrequencies: (type: 'client' | 'server') => {
        if (type === 'client') {
          return { values: clientFrequencies || [] };
        } else if (type === 'server') {
          return { values: serverFrequencies || [] };
        }
        return { values: [] };
      },
      updateVisualizer: (frequencies: Float32Array, source: string) => {
        const newFrequencies =
          source === AUDIO_SOURCE.MICROPHONE
            ? normalizeArrayMic(frequencies, 10, true)
            : normalizeArray(frequencies, 10, true);
        setCurrentFrequencies(newFrequencies);
        setAudioSource(source);
      },
    }));

    const getStyle = (index: number, gradientStart: string, gradientEnd: string) => {
      if (index !== 5) {
        return {
          backgroundImage: `linear-gradient(to bottom right, ${gradientStart}, ${gradientEnd})`,
        };
      }
      return { background: 'white' };
    };

    const renderCircles = (frequencies: number[] | Float32Array, audioSource: string) => {
      return circleConfigs.map(({ offset, left, animateClass, opacityBase }, index) => {
        const amplitude = Math.min(frequencies[index] || 0, 1);

        let size;

        if (index === 5) {
          size = 80;
        } else if (audioSource !== AUDIO_SOURCE.MICROPHONE) {
          size = width + amplitude * 20;
        } else {
          size = width * amplitude;
        }
        const opacity = Math.min(opacityBase, 1);
        const rounded = index === 0 ? 45 : index == 5 ? 48 : 47;

        const gradientStart = isWarning
          ? `rgba(${235 + amplitude * 40}, 27, 27, ${opacity})`
          : audioSource === AUDIO_SOURCE.SERVER
            ? `rgba(${170 + amplitude * 40}, ${100 + amplitude * 20}, 255, ${opacity})`
            : `rgba(${120 + amplitude * 50}, ${80 + amplitude * 40}, 255, ${opacity})`;
        const gradientEnd = isWarning
          ? `rgba(${244 + amplitude * 50}, ${130 + amplitude * 30}, 255, ${opacity})`
          : audioSource === AUDIO_SOURCE.SERVER
            ? `rgba(${190 + amplitude * 50}, ${130 + amplitude * 30}, 255, ${opacity})`
            : `rgba(${150 + amplitude * 60}, ${110 + amplitude * 50}, 255, ${opacity})`;
        return (
          <div
            key={`${audioSource}-${index}`}
            className={`absolute bg-gradient-to-br ${animateClass}`}
            style={{
              ...getStyle(index, gradientStart, gradientEnd),
              width: `${size}px`,
              height: `${size}px`,
              opacity,
              borderRadius: `${rounded}%`,
              zIndex: `${
                index === 0 || index === 3 ? '4' : index === 2 ? '5' : index === 5 ? '10' : '1'
              }`,
              left: `${width / 2 - size / 2 + left}px`,
              top: `${height / 2 - size / 2 + offset}px`,
            }}
          ></div>
        );
      });
    };

    return (
      <div className={`flex items-center justify-center w-full bg-black ${wrapperClassName}`}>
        <div className={`relative w-[${width}px] h-[${height}px]`}>
          <div
            className="absolute bg-white z-20 rounded-full opacity-90 transition-transform duration-200"
            style={{
              width: `${innerCircleWidth}px`,
              height: `${innerCircleWidth}px`,
              left: `${width / 2 - innerCircleWidth / 2}px`,
              top: `${height / 2 - innerCircleWidth / 2}px`,
            }}
          />

          {renderCircles(frequencies, audioSource)}
        </div>
      </div>
    );
  }
);

export default MicTestVisualizer;
