import { CARDIAC_STRESS_LEVEL } from '@shared/constants/shenai/threshold';

export const cardiacStressThreshold = (value?: number): number | null => {
  if (!value) return null;
  if (value > 4 && value < 8) {
    return CARDIAC_STRESS_LEVEL.MEDIUM;
  } else if (value > 8) {
    return CARDIAC_STRESS_LEVEL.HIGH;
  } else {
    return null;
  }
};
