const writeString = (view: DataView, offset: number, string: string): void => {
  for (let i = 0; i < string.length; i++) {
    view.setUint8(offset + i, string.charCodeAt(i));
  }
};

export const audioBufferToWav = (buffer: AudioBuffer): ArrayBuffer => {
  const numOfChannels = buffer.numberOfChannels;
  const sampleRate = buffer.sampleRate;
  const bufferLength = buffer.length;
  const data = new Float32Array(bufferLength * numOfChannels);

  // Combine all channels into one array
  for (let channel = 0; channel < numOfChannels; channel++) {
    data.set(buffer.getChannelData(channel), channel * bufferLength);
  }

  // Create WAV file header and convert to binary format
  const wavHeader = new ArrayBuffer(44);
  const view = new DataView(wavHeader);

  // "RIFF" chunk descriptor
  writeString(view, 0, 'RIFF');
  view.setUint32(4, 36 + data.length, true); // Chunk size
  writeString(view, 8, 'WAVE');

  // "fmt " sub-chunk
  writeString(view, 12, 'fmt ');
  view.setUint32(16, 16, true); // Sub-chunk size
  view.setUint16(20, 1, true); // Audio format (1 = PCM)
  view.setUint16(22, numOfChannels, true); // Number of channels
  view.setUint32(24, sampleRate, true); // Sample rate
  view.setUint32(28, sampleRate * numOfChannels * 2, true); // Byte rate
  view.setUint16(32, numOfChannels * 2, true); // Block align
  view.setUint16(34, 16, true); // Bits per sample

  // "data" sub-chunk
  writeString(view, 36, 'data');
  view.setUint32(40, data.length * 2, true); // Data chunk size

  // Create a new ArrayBuffer to store the WAV data (header + audio data)
  const wavData = new ArrayBuffer(44 + data.length * 2);
  const wavView = new DataView(wavData);

  // Copy the WAV header into the new ArrayBuffer
  new Uint8Array(wavData).set(new Uint8Array(wavHeader), 0);

  // Convert the float32 audio data to Int16 and write it to the WAV data buffer
  const sampleData = new Int16Array(data.length);
  for (let i = 0; i < data.length; i++) {
    sampleData[i] = Math.max(-1, Math.min(1, data[i])) * 0x7fff; // Clamp to 16-bit range
  }

  for (let i = 0; i < sampleData.length; i++) {
    wavView.setInt16(44 + i * 2, sampleData[i], true); // Write Int16 PCM data to the buffer
  }

  return wavData;
};

export const processTranscriptToUrl = (audioData: number[]) => {
  if (!audioData) return;
  const maxValue = audioData.reduce((max, value) => Math.max(max, value), -Infinity);
  const minValue = audioData.reduce((min, value) => Math.min(min, value), Infinity);

  // Normalize the data to -1.0 to 1.0 range
  const range = maxValue - minValue;
  const normalizedData = audioData.map((value) => 2 * ((value - minValue) / range) - 1); // Normalize to [-1, 1]

  const sampleRate = 24000;
  const numSamples = normalizedData.length;

  const audioContext = new window.AudioContext();
  const buffer = audioContext.createBuffer(1, numSamples, sampleRate);
  const channelData = buffer.getChannelData(0);

  // Populate the buffer with the scaled data
  normalizedData.forEach((value, index) => {
    channelData[index] = value;
  });

  const wavData = audioBufferToWav(buffer);

  const blob = new Blob([wavData], { type: 'audio/wav' });
  const audioUrl = URL.createObjectURL(blob);

  return audioUrl;
};
