import { ShenaiSdkDataI } from '@shared/types/shenai/sdk';
import ShenaiMeasurementProgress from '../ShenaiMeasurementProgress';
import SimplifiedValueTimePlot from '@features/Plot/SimplifiedValueTimePlot';

type MeasurementBlockProps = {
  label: string;
  value?: JSX.Element | string | number | null;
  timeInSeconds: number;
  isCompleted: boolean;
  setCompleted: (completed: boolean) => void;
  sdkData: ShenaiSdkDataI | null;
  plotKey: string;
  measurementValue: number | undefined;
  conditionalColorSet?: {
    bgColor: string;
    textColor: string;
    plotColor: string;
  };
};

export const MeasurementBlock = ({
  label,
  value,
  timeInSeconds,
  isCompleted,
  setCompleted,
  sdkData,
  plotKey,
  measurementValue,
  conditionalColorSet,
}: MeasurementBlockProps) => {
  const { bgColor, textColor, plotColor } = conditionalColorSet || {};

  return (
    <div
      style={{ background: bgColor }}
      className="flex space-y-2 flex-col items-center justify-start w-20 p-2 rounded-lg"
    >
      <div
        style={{ color: textColor }}
        className={`text-xs text-gray-400 font-extrabold font-['Inter'] leading-[15px] h-6`}
      >
        {label}
      </div>
      <div className="w-14 h-4">
        {isCompleted && measurementValue && (
          <SimplifiedValueTimePlot
            key={plotKey}
            customColor={plotColor}
            sdkData={sdkData}
            valueForPlot={measurementValue}
            label={plotKey}
          />
        )}
      </div>
      <div
        style={{ color: textColor }}
        className={`flex h-5 text-purple-600 text-lg font-bold font-['Inter'] leading-[27px]`}
      >
        {value}
      </div>
      <div className="h-6">
        {!isCompleted && (
          <ShenaiMeasurementProgress
            key={`${plotKey}-progress`}
            timeInSeconds={timeInSeconds}
            measurementState={sdkData?.measurementState?.value}
            onCompleted={setCompleted}
            measurementValue={measurementValue}
          />
        )}
      </div>
    </div>
  );
};
