import { useState, forwardRef } from 'react';
import { ShamefulAny } from '@interfaces/index';
import { Loader } from '@shared/ui/loader/Loader';
import Button from '@components/Button';
import CopyButton from '@components/SessionComponents/InvitePatientStep/ui/CopyButton';
import { shallowEqual, useSelector } from '@xstate/react';
import { refaelaTherapistActor } from '@components/xState/machines/refaelaTherapistMachine';
import { formatTimestamp } from '@modules/Therapist/Home/model/utils/formatters';

const SelfReviewMediaDisplayBlock = forwardRef<
  HTMLVideoElement,
  { frame: ShamefulAny; recording: ShamefulAny; sessionReviewLink: string }
>(({ frame, recording, sessionReviewLink }, ref) => {
  const [showVideo, setShowVideo] = useState(true);

  const isLoading = !frame && !recording;

  const toggleMedia = () => {
    setShowVideo((prev) => !prev);
  };

  const selectSessionData = (snapshot: any) => ({
    aiSessionDateTime: snapshot.context.aiSessionDateTime,
    aiSessionOwnerName: snapshot.context.aiSessionOwnerName,
  });

  // comparator to avoid re-renders when selected values don't change
  const compareSessionData = (prev: any, next: any) => shallowEqual(prev, next);

  const sessionData = useSelector(refaelaTherapistActor, selectSessionData, compareSessionData);

  const { aiSessionDateTime, aiSessionOwnerName } = sessionData;
  const extendedSessionReviewLink = `${sessionReviewLink}&aiSessionDateTime=${aiSessionDateTime}&aiSessionOwnerName=${aiSessionOwnerName}`;
  return (
    <div className="mb-4 w-full">
      <div className="flex justify-between mb-2 gap-4 items-center">
        <div className="flex flex-col font-semibold">
          <div>Session of {aiSessionOwnerName}</div>
          <div>{formatTimestamp(aiSessionDateTime as number, 'full')}</div>
        </div>
        <div className="flex gap-2">
          <CopyButton
            label={'Copy session link'}
            value={extendedSessionReviewLink}
            variant="button"
          />
          <Button
            variant="outlinedPrimary"
            onClick={toggleMedia}
            text={showVideo ? 'Show Frame' : 'Show Video'}
            disabled={isLoading || (showVideo && !frame?.link) || (!showVideo && !recording?.link)}
          />
        </div>
      </div>

      <div className="w-full h-full rounded flex items-center justify-center">
        {isLoading ? (
          <div className="w-[600px] h-[300px] bg-gray-500 flex items-center justify-center">
            <Loader />
          </div>
        ) : showVideo && recording?.link ? (
          <div className="w-[600px] h-[300px] bg-gray-500">
            <video
              className="h-full w-full object-contain"
              controls={false}
              src={recording.link}
              ref={ref}
            >
              Your browser does not support the video tag.
            </video>
          </div>
        ) : !showVideo && frame?.link ? (
          <div className="w-[600px] h-[300px] bg-gray-500">
            <img
              className="h-full w-full object-contain"
              src={frame.link}
              alt="Frame"
            />
          </div>
        ) : (
          <div className="w-[600px] h-[300px] bg-gray-500 flex items-center justify-center">
            <p className="text-white">
              {showVideo ? 'Video is unavailable' : 'Frame is unavailable'}
            </p>
          </div>
        )}
      </div>
    </div>
  );
});

export default SelfReviewMediaDisplayBlock;
