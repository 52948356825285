import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useSelector } from '@xstate/react';
import { refaelaPatientActor } from '@components/xState/machines/refaelaPatientMachine';
// Constants
import { PATIENT_MACHINE_STATES, PATIENT_MACHINE_EVENTS } from '@machines/patient/constants';
import { AI_SESSION_TYPES, SESSION_TYPES } from '@interfaces/constants';
// Types
import { AssignedHomeworks } from '@interfaces/session';
// Hooks
import usePatientRouting from '@hooks/usePatientRouting';
// Components
import PatientBreadcrumbs from '@components/Breadcrumbs/Patient/PBreadcrumbs';
import NotificationBar from '@components/NotificationBar';
import PatientNavigation from '@components/Navigation/Patient/PatientNavigation';
import Header from '@features/Layout/Header/Header';
import PatientWaitingRoom from '../WaitingRoom';
import { dashboardActor } from '@components/RealtimeDashboard/model/xstate/dashboardMachine';
import { getAISession } from '@api/userAPI';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// import { ShamefulAny } from '@interfaces/index';
// import { getUserMetadata } from '@api/userAPI';
// import { updateClerkUserDataById } from '@api/user/updateClerkUserDataById';
// import { fetchCurrentUserDetails } from '@api/user/me';

const excludedStates = [
  PATIENT_MACHINE_STATES.PATIENT_VIDEO_SESSION.ROOT,
  PATIENT_MACHINE_STATES.END_SESSION_PAGE,
  PATIENT_MACHINE_STATES.PATIENT_TERMS_OF_USE_AND_PRIVACY,
];

type PatientOfficeModeProps = {
  incomingSessionType?: string;
  roomUrl?: string;
  user: any;
  assignedHomeworks?: AssignedHomeworks;
  areTermsOfPrivacyAccepted?: boolean;
};
const PatientOfficeMode = ({
  incomingSessionType,
  assignedHomeworks,
  roomUrl,
  user,
}: PatientOfficeModeProps) => {
  const { areTermsOfPrivacyAccepted, patientId } =
    useSelector(refaelaPatientActor, (state) => state.context.currentUser) || {};
  const { PageComponent, snapshot, props } = usePatientRouting();

  const memoizedNavigation = useMemo(() => <PatientNavigation />, []);
  const memoizedBreadcrumbs = useMemo(() => <PatientBreadcrumbs />, []);
  const memoizedNotification = useMemo(() => <NotificationBar />, []);

  const shouldShowHeader = !excludedStates.some((state) => snapshot.matches(state));
  const [sessionPrompt, setSessionPrompt] = useState('');

  useEffect(() => {
    const getLastSession = async () => {
      const { prompt } = await getAISession(patientId!);
      setSessionPrompt(prompt);
    };

    getLastSession();
  }, []);

  useLayoutEffect(() => {
    if (!areTermsOfPrivacyAccepted) {
      refaelaPatientActor.send({
        type: PATIENT_MACHINE_EVENTS.GO_TO_GREETING_PAGE,
      });
    } else if (roomUrl && incomingSessionType === SESSION_TYPES.THERAPIST) {
      refaelaPatientActor.send({
        type: PATIENT_MACHINE_EVENTS.GO_TO_PATIENT_PRETALK_WAITING_ROOM,
        currentUser: user, // not necessary
      });
    } else if (
      assignedHomeworks?.count! > 0 &&
      incomingSessionType &&
      AI_SESSION_TYPES.includes(incomingSessionType) &&
      sessionPrompt
    ) {
      dashboardActor.send({
        type: 'SESSION_PROMPT_UPDATE',
        sessionPrompt: sessionPrompt || '',
      });

      refaelaPatientActor.send({
        type: PATIENT_MACHINE_EVENTS.GO_TO_AI_DASHBOARD,
        currentUser: user, // not necessary
      });
    }
  }, [sessionPrompt, incomingSessionType, areTermsOfPrivacyAccepted]);

  // TODO: delete it after testing completed
  // const onRemoveConsent = async () => {
  //   try {
  //     const existingMetadata = await getUserMetadata(user.patientId);
  //     await updateClerkUserDataById(user.patientId, {
  //       metadata: {
  //         ...existingMetadata,
  //         areTermsOfPrivacyAccepted: false,
  //       },
  //     });
  //     const updatedUser = await fetchCurrentUserDetails(user.patientId);
  //     refaelaPatientActor.send({
  //       type: PATIENT_MACHINE_EVENTS.UPDATE_PATIENT_USER,
  //       currentUser: updatedUser,
  //     });
  //   } catch (e: ShamefulAny) {
  //     console.error('Error updating patient agreement', e.message, e.details);
  //   }
  // };

  return (
    <div className="flex h-full w-full justify-center">
      <div className="flex flex-col items-center min-w-full h-full">
        {/* <button onClick={onRemoveConsent}>Remove Consent</button> */}
        {shouldShowHeader && (
          <Header
            navigation={memoizedNavigation}
            breadcrumbs={memoizedBreadcrumbs}
            notification={memoizedNotification}
            stateValue={snapshot.value}
          />
        )}
        {/* // TODO: add patient calibration data on PATIENT_MACHINE_STATES.PATIENT_VIDEO_SESSION.ROOT level to share data via state*/}
        {snapshot.matches('PatientVideoSession') && <PatientWaitingRoom />}
        {PageComponent && <PageComponent {...props} />}
      </div>
    </div>
  );
};

export default PatientOfficeMode;
