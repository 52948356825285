import { memo, useEffect, useState } from 'react';

import { MemoAISimplifiedRealtimeAI } from './ui/AISimplifiedRealtimeAI';
// import { useActor, useActorRef, useSelector } from '@xstate/react';
// import aiSessionMachine, { aiSessionActor } from './model/xState/aiSessionMachine';
import TopBar from './ui/TopBar';
import { appActor } from '@machines/shared/appMachine';

type AISessionSimplifiedProps = {
  onConnectConversation: any;
  onDisconnectConversation: any;
  isConnected: boolean;
  onOpenAdvancedMode: (x: boolean) => void;
  sessionId: string;
  isSDKInitialized: boolean;
  startTimeRef: any;
  wavStreamPlayerRef: any;
  wavRecorderRef: any;
  shenaiSDK: any;
  onPauseSession: any;
  isSimplifiedSessionOpened: any;
  onFinishSession: () => Promise<void>;
  onSetInitializationSettings: any;
  realtimeClientInstance: any;
};

const AISessionSimplified = ({
  onConnectConversation,
  // onDisconnectConversation,
  isConnected,
  onFinishSession,
  sessionId,
  isSDKInitialized,
  startTimeRef,
  wavRecorderRef,
  wavStreamPlayerRef,
  shenaiSDK,
  onPauseSession,
  isSimplifiedSessionOpened,
  onSetInitializationSettings,
  onOpenAdvancedMode,
  realtimeClientInstance,
}: AISessionSimplifiedProps) => {
  const [currentDateTime, setCurrentDateTime] = useState({
    time: '',
    date: '',
  });
  const [isWatingSessionEnd, setIsWaitingsSessionEnd] = useState(false);
  useEffect(() => {
    const updateDateTime = () => {
      const now = new Date();
      const time = now.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
      const date = now.toLocaleDateString('en-GB').replace(/\//g, '.');
      setCurrentDateTime({ time, date });
    };

    updateDateTime();
    const interval = setInterval(updateDateTime, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (isSimplifiedSessionOpened) {
      // onConnectConversation?.(sessionId);
      appActor.send({ type: 'HEADER_HIDDEN_UPDATE', isHeaderHidden: true });
      shenaiSDK?.attachToCanvas('#mxcanvas-ai-simplified');
      shenaiSDK?.setShowUserInterface(false);
    } else {
      // onDisconnectConversation?.();
      appActor.send({ type: 'HEADER_HIDDEN_UPDATE', isHeaderHidden: false });
      shenaiSDK?.setShowUserInterface(true);
      shenaiSDK?.attachToCanvas('#mxcanvas1', false);
    }
  }, [isSimplifiedSessionOpened]);

  useEffect(() => {
    // Cleanup function to handle disconnecting the conversation
    return () => {
      appActor.send({ type: 'HEADER_HIDDEN_UPDATE', isHeaderHidden: false });
      // if (onDisconnectConversation) {
      //   onDisconnectConversation();
      // }
    };
  }, []);

  const handleRestartSession = () => {
    console.log('restart session clicked');
  };

  const handleFinishSession = async () => {
    try {
      setIsWaitingsSessionEnd(true);
      await onFinishSession();
    } catch {
    } finally {
      setIsWaitingsSessionEnd(false);
    }
  };

  return (
    <div className="relative w-full h-full">
      <TopBar
        time={currentDateTime.time}
        date={currentDateTime.date}
        onRestartSession={handleRestartSession}
        onFinishSession={handleFinishSession}
        onPauseAISession={onPauseSession}
        onOpenAdvancedMode={onOpenAdvancedMode}
        isWatingSessionEnd={isWatingSessionEnd}
      />

      <MemoAISimplifiedRealtimeAI
        key="AI-simplified-realtime-ai"
        sessionId={sessionId}
        onConnectConversation={onConnectConversation}
        isConnected={isConnected || isWatingSessionEnd}
        startTimeRef={startTimeRef}
        onSetInitializationSettings={onSetInitializationSettings}
        wavRecorderRef={wavRecorderRef}
        wavStreamPlayerRef={wavStreamPlayerRef}
        isSDKInitialized={isSDKInitialized}
        shenaiSDK={shenaiSDK}
        realtimeClientInstance={realtimeClientInstance}
      />
    </div>
  );
};

const MemoAISessionSimplified = memo(AISessionSimplified);
MemoAISessionSimplified.displayName = 'AISessionSimplified';

export default MemoAISessionSimplified;
