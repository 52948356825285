import { USER_ROLES } from '@interfaces/user';
import { SessionInfoData, RecordingInstance } from './types';

/**
 * Delay function that returns a promise
 */
export const delay = (time = 1500) =>
  new Promise<void>((resolve) => setTimeout(() => resolve(), time));

/**
 * Safe parse JSON
 */
export function safeParseJSON(data: unknown) {
  if (typeof data === 'object') {
    return data;
  } else if (typeof data === 'string') {
    try {
      return JSON.parse(data);
    } catch (error) {
      throw new Error('Failed to parse string to JSON');
    }
  } else {
    throw new Error('Failed to parse JSON. Data is not a string or object');
  }
}

export const isUserPatient = (role: string) => role === USER_ROLES.PATIENT;
export const isUserTherapist = (role: string) => role === USER_ROLES.THERAPIST;

export const getTherapistRecordingInstances = (data: SessionInfoData): RecordingInstance | null => {
  // Find the first matching recording instance for the therapist
  return (
    data.recordingInstances.find((instance) => instance.userId.startsWith('therapist_')) || null
  );
};

export const getPatientRecordingInstances = (data: SessionInfoData): RecordingInstance | null => {
  // Find the first matching recording instance for the patient
  return data.recordingInstances.find((instance) => instance.userId.startsWith('patient_')) || null;
};
