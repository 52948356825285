import { useEffect, useState } from 'react';

type ConversationModelEventsProps = {
  realtimeClientInstance: any;
};

const ROLE_MAP_LABEL: Record<string, string> = {
  user: 'You',
  assistant: 'AI',
};

const ConversationItems = ({ realtimeClientInstance }: ConversationModelEventsProps) => {
  const [items, setItems] = useState<any[]>([]);

  useEffect(() => {
    let timeoutId;

    timeoutId = setInterval(() => {
      const items = realtimeClientInstance.conversation.getItems();
      const lightItems = [];

      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        const [content] = item.content;

        if (content.text?.includes('user_') || content.text?.includes('session_')) {
          continue;
        }

        const { formatted, ...rest } = item;
        lightItems.push({
          ...rest,
          formatted: {
            ...formatted,
            audio: [],
            audioBuffer: {},
            file: {},
          },
        });
      }
      setItems(lightItems);
    }, 100);

    return () => {
      clearInterval(timeoutId);
    };
  }, []);

  return (
    <div className="w-full flex justify-center">
      <div
        className="w-1/2 max-w-xl flex justify-center text-[#6e6e7f] relative flex-grow pt-1 leading-[1.2em]"
        data-conversation-content
      >
        <div className="p-2 overflow-x-hidden overflow-y-auto h-full">
          {items.slice(-2).map((conversationItem: any) => {
            return (
              <div
                className="conversation-item flex max-h-28 overflow-y-auto"
                key={conversationItem.id}
              >
                <div className={`speaker w-24 mr-3 shrink-0 ${conversationItem.role || ''}`}>
                  <div>
                    {(
                      ROLE_MAP_LABEL[conversationItem.role] || (conversationItem.type as string)
                    ).replace(/_/g, ' ')}
                  </div>
                </div>
                <div className={`speaker-content w-96 flex overflow-y-auto pr-2`}>
                  {/* tool response */}
                  {conversationItem.type === 'function_call_output' && (
                    <span className="text-start">{conversationItem.formatted.output}</span>
                  )}
                  {/* tool call */}
                  {!!conversationItem.formatted.tool && (
                    <span className="text-start">
                      {conversationItem.formatted.tool.name}(
                      {conversationItem.formatted.tool.arguments})
                    </span>
                  )}
                  {!conversationItem.formatted.tool && conversationItem.role === 'user' && (
                    <span className="text-start">
                      {conversationItem.formatted.transcript ||
                        (Object.keys(conversationItem.formatted.audio).length > 0
                          ? '(awaiting transcript)'
                          : conversationItem.formatted.text || '...')}
                    </span>
                  )}
                  {!conversationItem.formatted.tool && conversationItem.role === 'assistant' && (
                    <span className="text-start">
                      {conversationItem.formatted.transcript ||
                        conversationItem.formatted.text ||
                        '...'}
                    </span>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ConversationItems;
