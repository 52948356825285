import { useCallback } from 'react';
import { getFrames } from '@api/frame';
import { USER_ROLES } from '@interfaces/user';
import { ShamefulAny } from '@interfaces/index';

export const useGetTherapistPatientFrames = (
  patientId: string,
  therapistId: string,
  sessionId: string,

  setLoadingState: React.Dispatch<React.SetStateAction<Record<string, ShamefulAny>>>
) => {
  const fetchFrames = useCallback(async () => {
    setLoadingState((prev) => ({
      ...prev,
      frames: { ...prev.frames, isLoading: true, error: null },
      therapistFrames: { ...prev.therapistFrames, isLoading: true, error: null },
    }));

    try {
      const [framesResponse, therapistFramesResponse] = await Promise.all([
        getFrames(USER_ROLES.PATIENT, patientId, therapistId, sessionId),
        getFrames(USER_ROLES.THERAPIST, patientId, therapistId, sessionId),
      ]);

      setLoadingState((prev) => ({
        ...prev,
        frames: { ...prev.frames, isLoading: false, data: framesResponse.frames || [] },
        therapistFrames: {
          ...prev.therapistFrames,
          isLoading: false,
          data: therapistFramesResponse.frames || [],
        },
      }));
    } catch (error: ShamefulAny) {
      setLoadingState((prev) => ({
        ...prev,
        frames: { ...prev.frames, isLoading: false, isFailed: true, error: error.message },
        therapistFrames: {
          ...prev.therapistFrames,
          isLoading: false,
          isFailed: true,
          error: error.message,
        },
      }));
      console.error('Error fetching frames:', error.message);
    }
  }, [patientId, therapistId, sessionId, setLoadingState]);

  return { fetchFrames };
};

export default useGetTherapistPatientFrames;
