import { useSelector } from '@xstate/react';

import ToggleableTabs from '@features/Tab/ToggleableTabs/ToggleableTabs';

import { dashboardActor } from '../model/xstate/dashboardMachine';
import { TOGGLEABLE_SECTIONS } from '../model/constants/togglableSections';
import { RealtimeEventHandler } from '@openai/realtime-api-beta/dist/lib/event_handler';
import { memo, useEffect, useState } from 'react';
import { askChatGPT } from '@api/userAPI';

import { refaelaTherapistActor } from '@components/xState/machines/refaelaTherapistMachine';
import { refaelaPatientActor } from '@components/xState/machines/refaelaPatientMachine';
import { isUserTherapist } from '@utils/helpers';
import { Patient } from '@interfaces/patient';

type SuggestionDataProps = {
  sessionId: string;
  actor: typeof refaelaPatientActor | typeof refaelaTherapistActor;
  realtime: RealtimeEventHandler;
  onSectionToggleByKey: (key: string) => void;
};

const SuggestionData = ({
  actor,
  sessionId,
  realtime,
  onSectionToggleByKey,
}: SuggestionDataProps) => {
  const sections = useSelector(dashboardActor, (state) => state.context.sections);
  const [suggestionData, setSuggestionData] = useState({
    topicsToExplore: ['Waiting for data...'],
    topicsToExpand: ['Waiting for data...'],
    deepeningQuestions: ['Waiting for data...'],
  });

  const [conversation, setConversation] = useState<string[]>([]);

  const currentUser = useSelector(actor, (state) => state.context.currentUser)!;

  const userId = isUserTherapist(currentUser.role!)
    ? currentUser.therapistId!
    : (currentUser as Patient).patientId;

  const handleSectionToggle = (key: string) => {
    onSectionToggleByKey(key);
  };

  useEffect(() => {
    const sessionCreateHandler = async () => {
      while (true) {
        const transcript_evt: any = await realtime.waitForNext(
          'server.conversation.item.input_audio_transcription.completed'
        );
        setConversation((prev) => [...prev, transcript_evt.transcript]);
      }
    };

    realtime.on('server.session.created', sessionCreateHandler);
    return () => {
      if ('server.session.created' in realtime) {
        try {
          realtime.off('server.session.created', sessionCreateHandler);
        } catch (e) {
          console.log('error removing event listener: ', e);
        }
      }
    };
  }, [realtime, realtime.eventHandlers]);

  useEffect(() => {
    const askAI = async () => {
      try {
        const { result } = await askChatGPT(userId, sessionId, conversation, 'suggestion-ai-agent');

        setSuggestionData((prev) => ({
          topicsToExplore: result?.topicsToExplore || prev.topicsToExplore,
          topicsToExpand: result?.topicsToExpand || prev.topicsToExpand,
          deepeningQuestions: result?.deepeningQuestions || prev.deepeningQuestions,
        }));
      } catch (e) {
        console.error('Error asking gpt', JSON.stringify(e));
      }
    };

    conversation.length && askAI();
  }, [conversation]);

  return (
    <div className="mb-4 space-y-4">
      <ToggleableTabs
        key="rtSuggestion"
        isShown={sections[TOGGLEABLE_SECTIONS.SUGGESTIONS]}
        onToggle={() => handleSectionToggle(TOGGLEABLE_SECTIONS.SUGGESTIONS)}
        withCardStyle
        tabs={[
          {
            label: 'Suggestion Agent: ',
            content: (
              <div className="flex flex-col">
                <div className="mb-4">
                  <span className="text-black text-[13px] font-bold font-['Inter'] leading-normal">
                    Topics to Explore
                  </span>

                  <span>
                    {suggestionData.topicsToExplore.map((el: any, i: number) => (
                      <p key={`${el}+${i}`}>{el}</p>
                    ))}
                  </span>
                </div>
                <div className="mb-4">
                  <span className="text-black text-[13px] font-bold font-['Inter'] leading-normal">
                    Topics to Expand
                  </span>

                  <span>
                    {suggestionData.topicsToExpand.map((el: any, i: number) => (
                      <p key={`${el}+${i}`}>{el}</p>
                    ))}
                  </span>
                </div>
                <div className="mb-4">
                  <span className="text-black text-[13px] font-bold font-['Inter'] leading-normal">
                    Deepening Questions
                  </span>

                  <span>
                    {suggestionData.deepeningQuestions.map((el: any, i: number) => (
                      <p key={`${el}+${i}`}>{el}</p>
                    ))}
                  </span>
                </div>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default memo(SuggestionData);
