import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from '@xstate/react';
import { useTransition } from '@react-spring/web';

import TherapistCalendar from '../Calendar';
import TherapistNotification from '../Notifications';
import PatientList from '../PatientList';
import TherapistHome from '../Home';
import TherapistHeader from '../Header';
import TherapistSettings from '../Settings';

import GreetingPage from '../GreetingPage';

import PatientCard from '@components/PatientCard/PatientCard';
import SessionReviewByTherapist from '@components/SessionReview/SessionReviewByTherapist';
import TherapistTimeline from '@components/Timeline/TherapistTimeline';
import PatientViewTimeline from '@components/Timeline/PatientViewTimeline';

import { patientList as patientListMock } from '@mockData/patientMockData';
import { findPatientById } from '@utils/patient';

import { Patient, ShamefulAny } from '@interfaces/index';
import { refaelaTherapistActor } from '@components/xState/machines/refaelaTherapistMachine';
import { AppContext } from '../../../contextApp';
import { getSessionsCountByUser } from '@utils/sessions';
import { Session } from '../Session/Session';
import { delay } from '@utils/helpers';
import RealtimeDashboard from '@components/RealtimeDashboard/RealtimeDashboard';
import TermsOfUse from '@pages/shared/terms-of-use/TermsOfUse';
import { AssignAISessionWrapper } from '@components/SessionComponents/AssignAISessionWrapper';
import SelfWorkReview from '@components/SelfWorkReview/SelfWorkReview';
import { getSessionDataFromURL } from '@utils/getSessionDataFromURL';
import { THERAPIST_MACHINE_EVENTS } from '@machines/therapist/constants/states';
import { Modal } from '@features/Modal/ui/Modal';
import { AISessionPresetModal } from '../AISessionPresetModal/AISessionPresetModal';
import { appActor } from '@machines/shared/appMachine';

const OfficeMode: React.FC = () => {
  const snapshot = useSelector(refaelaTherapistActor, (state) => state);
  const currentUser = useSelector(refaelaTherapistActor, (state) => state.context.currentUser);
  const { isDefaultHeaderHidden } = useSelector(appActor, (state) => state.context);
  const patientList = currentUser?.patientList;
  const isAdmin = currentUser?.isAdmin;

  const sessionsCount = getSessionsCountByUser(snapshot.context.currentUser);

  const { setPatientToMeetId } = useContext(AppContext);

  const [showTimeline, setShowTimeline] = useState(
    !snapshot.matches('TherapistSession.TherapistPretalkWaitingRoom' as ShamefulAny)
  );
  const [isAISessionPresetModalOpen, setIsAISessionPresetModalOpen] = useState(false);
  const [showPatientTimeline, setShowPatientTimeline] = useState(false);
  const [dataForTimeline, setDataForTimeline] = useState<Patient | null>(null);
  const [show, setShow] = useState(false);

  const noTimeline =
    snapshot.matches('TherapistSession') ||
    snapshot.matches('TherapistCalendar') ||
    snapshot.matches('AIVideo') ||
    snapshot.matches('SessionReplayByTherapist') ||
    snapshot.matches('AISessionReview') ||
    snapshot.matches('TermsOfUse');

  useEffect(() => {
    const handleComponentUnmount = async () => {
      if (noTimeline) {
        setShowTimeline(false);
        setShowPatientTimeline(false);
        await delay(3000);
        setShow(true);
      } else if (snapshot.matches('PatientView')) {
        if (snapshot.context.currentPatientId) {
          const patientId = snapshot.context.currentPatientId;
          const patientData = findPatientById(patientListMock, patientId);
          setDataForTimeline(patientData || null);
          setShowTimeline(false);
          await delay(2000);
          setShowPatientTimeline(true);
        }
      } else {
        await delay(2000);
        setShowTimeline(true);
        setShowPatientTimeline(false);
        setShow(false);
      }
    };

    handleComponentUnmount();
  }, [noTimeline, snapshot]);

  const timelineTransition = useTransition(showTimeline, {
    from: { opacity: 0, transform: 'translate3d(0,-40px,0)' },
    enter: { opacity: 1, transform: 'translate3d(0,0px,0)' },
    leave: { opacity: 0, transform: 'translate3d(0,-40px,0)' },
    config: { duration: 2000 },
  });

  const patientViewTransition = useTransition(showPatientTimeline, {
    from: { opacity: 0, transform: 'translate3d(0,-40px,0)' },
    enter: { opacity: 1, transform: 'translate3d(0,0px,0)' },
    leave: { opacity: 0, transform: 'translate3d(0,-40px,0)' },
    config: { duration: 2000 },
  });

  useEffect(() => {
    setPatientToMeetId('');
    const { userId, sessionId, aiSessionDateTime, aiSessionOwnerName } = getSessionDataFromURL(
      window.location.href
    );

    if (isAdmin && sessionId && userId) {
      refaelaTherapistActor.send({
        type: THERAPIST_MACHINE_EVENTS.GO_TO_AI_SESSION_REVIEW,
        userId: userId,
        sessionId: sessionId,
        aiSessionDateTime: aiSessionDateTime,
        aiSessionOwnerName: aiSessionOwnerName,
      });
    }
  }, []);

  return (
    <div className="flex h-full w-full justify-center">
      <div className="flex flex-col items-center min-w-full h-full">
        {!(
          snapshot.matches('TherapistSession.TherapistMeetingRoom' as ShamefulAny) ||
          snapshot.matches('TherapistSession.TherapistDebriefWaitingRoom' as ShamefulAny) ||
          isDefaultHeaderHidden
        ) && <TherapistHeader />}
        {snapshot.matches('TherapistSession') && show && <Session />}

        {snapshot.matches('PatientView') && <PatientCard />}
        {snapshot.matches('PatientList') && <PatientList patients={patientList} />}
        {snapshot.matches('TherapistSettings') && <TherapistSettings />}
        {snapshot.matches('TherapistCalendar') && show && <TherapistCalendar />}
        {snapshot.matches('SessionReplayByTherapist') && <SessionReviewByTherapist />}
        {snapshot.matches('AISessionReview') && <SelfWorkReview />}
        {snapshot.matches('TherapistNotification') && <TherapistNotification />}
        {snapshot.matches('TherapistOfficeMode') &&
          (sessionsCount !== 0 || snapshot.context.isFirstSessionSkipped) && (
            <TherapistHome setIsAISessionPresetModalOpen={setIsAISessionPresetModalOpen} />
          )}
        {snapshot.matches('TherapistOfficeMode') &&
          sessionsCount == 0 &&
          !snapshot.context.isFirstSessionSkipped && <GreetingPage />}
        {snapshot.matches('AIDashboard') && <RealtimeDashboard actor={refaelaTherapistActor} />}
        {snapshot.matches('AssignAISession') && <AssignAISessionWrapper />}
        {snapshot.matches('TermsOfUse') && <TermsOfUse actor={refaelaTherapistActor} />}

        {!snapshot.matches('AIDashboard') &&
          !snapshot.matches('TermsOfUse') &&
          !snapshot.matches('AISessionReview') &&
          !snapshot.matches('AssignAISession') &&
          !snapshot.matches('AIVideo') &&
          !snapshot.matches('TherapistSession') &&
          !snapshot.matches('TherapistSession.TherapistPretalkWaitingRoom' as ShamefulAny) &&
          timelineTransition((_styles, item) => {
            return item && !showPatientTimeline ? (
              <TherapistTimeline
                patients={patientList}
                isTimelineOpen={showTimeline}
              />
            ) : null;
          })}

        {!snapshot.matches('AIDashboard') &&
          !snapshot.matches('TermsOfUse') &&
          !snapshot.matches('TherapistSession') &&
          !snapshot.matches('AISessionReview') &&
          !snapshot.matches('TherapistSession.TherapistPretalkWaitingRoom' as ShamefulAny) &&
          snapshot.matches('PatientView') &&
          patientViewTransition((_styles, item) => {
            return item && dataForTimeline ? (
              <PatientViewTimeline
                patientData={dataForTimeline}
                isTimelineOpen={showPatientTimeline}
              />
            ) : null;
          })}
      </div>
      <Modal
        isOpen={isAISessionPresetModalOpen}
        title="Start AI Session"
        onCancel={() => setIsAISessionPresetModalOpen(false)}
      >
        {isAISessionPresetModalOpen && (
          <AISessionPresetModal
            currentUser={currentUser}
            onCancel={() => setIsAISessionPresetModalOpen(false)}
          />
        )}
      </Modal>
    </div>
  );
};

export default OfficeMode;
