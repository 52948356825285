import { InputHTMLAttributes, ReactNode, SyntheticEvent } from 'react';

// Updated props to handle both input and textarea types with a unified approach
type TextInputControllerProps<T> = {
  label?: string;
  id: T;
  type?: 'text' | 'email' | 'textarea' | 'tel'; // Allowing flexibility for input type or textarea
  StartAdornment?: ReactNode;
  onInputChange?: ({ key, value }: { key: T; value: string }) => void;
} & InputHTMLAttributes<HTMLInputElement> & InputHTMLAttributes<HTMLTextAreaElement>;

export const TextInputController = <T,>({
  onInputChange,
  label,
  id,
  type = 'text',
  StartAdornment,
  ...props
}: TextInputControllerProps<T>) => {
  const handleInputChange = (e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onInputChange?.({ key: id, value: (e.target as HTMLInputElement | HTMLTextAreaElement).value });
  };

  const startAdornmentStyle = StartAdornment ? 'pl-10' : '';

  const commonProps = {
    id,
    name: id,
    onInput: handleInputChange,
    className: `block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-button-hover placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${startAdornmentStyle}`,
    ...props,
  };

  return (
    <div>
      {label && (
        <label
          htmlFor={id}
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          {label}
        </label>
      )}
      <div className="mt-1 relative">
        {StartAdornment && (
          <div className="absolute text-gray-400 top-[7px] md_d:top-[15px] left-3">
            {StartAdornment}
          </div>
        )}
        {type === 'textarea' ? (
          <textarea
            {...commonProps}
            className={`${commonProps.className} h-[80px] md_d:h-[120px]`}
          />
        ) : (
          <input type={type} {...commonProps} />
        )}
      </div>
    </div>
  );
};
