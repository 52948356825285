import { ShenaiSdkDataI } from '@shared/types/shenai/sdk';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Uplot from 'uplot';
import UplotReact from 'uplot-react';

const SimplifiedValueTimePlot: React.FC<{
  valueForPlot?: number;
  sdkData: ShenaiSdkDataI | null;
  label?: string;
  customColor?: string;
}> = ({ valueForPlot, label = 'label', customColor }) => {
  const [valueStates, setValueStates] = useState<{ valueForPlot: number }[]>([]);
  const [chartSize, setChartSize] = useState<{ width: number; height: number }>({
    width: 400,
    height: 16,
  });
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (valueForPlot !== undefined) {
      setValueStates((prev) => [...prev, { valueForPlot }]);
    }
  }, [valueForPlot]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      if (entries[0].contentRect) {
        const { width, height } = entries[0].contentRect;
        setChartSize({ width, height });
      }
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, []);

  const data = useMemo(() => {
    const values = valueStates.map((entry) => entry.valueForPlot);

    if (values.length === 0) {
      return [[0], [0]] as Uplot.AlignedData;
    }

    return [values.map((_, idx) => idx), values] as Uplot.AlignedData;
  }, [valueStates]);

  const chartOptions: Uplot.Options = {
    width: chartSize.width,
    height: chartSize.height,
    padding: [0, 0, 0, 0],
    scales: {
      x: { auto: true },
      y: { auto: true },
    },
    series: [
      {},
      {
        label: `${label}`,
        stroke: customColor || '#9333ea',
        value: (_, v) => `${v}`,
        spanGaps: false, //when true, null data values will not cause line breaks
      },
    ],
    axes: [
      {
        show: false,
      },
      {
        show: false,
      },
    ],
    legend: {
      show: false,
    },
  };

  return (
    <div
      ref={containerRef}
      style={{ width: '100%', position: 'relative' }}
    >
      <UplotReact
        data={data}
        options={chartOptions}
      />
    </div>
  );
};

export default SimplifiedValueTimePlot;
