import { MEASUREMENT_STATE } from '@features/Plot/model/constants/states';
import ProgressBar from '@shared/ui/bar/progress';
import { useState, useEffect } from 'react';
import { MeasurementState } from 'shenai-sdk';

interface ShenaiMeasurementProgressProps {
  timeInSeconds: number;
  measurementState: MeasurementState;
  onCompleted: (isCompleted: boolean) => void;
  measurementValue?: number;
}

const ShenaiMeasurementProgress: React.FC<ShenaiMeasurementProgressProps> = ({
  timeInSeconds,
  measurementState,
  onCompleted,
  measurementValue,
}) => {
  const [progress, setProgress] = useState(0);
  const [startTime, setStartTime] = useState(Date.now());
  const [, setResetTrigger] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if (measurementValue !== null && measurementValue !== undefined) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  }, [measurementValue]);

  useEffect(() => {
    if (measurementState === MEASUREMENT_STATE.WAITING_FOR_FACE) {
      setProgress(0);
      setStartTime(Date.now());
      setResetTrigger(true);
    } else if (measurementState === MEASUREMENT_STATE.RUNNING_SIGNAL_BAD) {
      setIsPaused(true);
    } else if (measurementState === MEASUREMENT_STATE.RUNNING_SIGNAL_GOOD) {
      setResetTrigger(false);
      setIsPaused(false);
    }
  }, [measurementState]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (measurementState === MEASUREMENT_STATE.RUNNING_SIGNAL_GOOD && !isPaused) {
        const elapsed = (Date.now() - startTime) / 1000;
        const newProgress = Math.min((elapsed / timeInSeconds) * 100, 100);
        setProgress(newProgress);

        if (newProgress === 100) {
          onCompleted(true);
        }
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [startTime, timeInSeconds, measurementState, onCompleted, isPaused]);

  if (!isVisible) return null;

  return (
    <ProgressBar
      progress={progress}
      color="#9333EA"
    />
  );
};

export default ShenaiMeasurementProgress;
