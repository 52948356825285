import React, { useEffect, useMemo, useRef, useState } from 'react';
import Uplot from 'uplot';
import UplotReact from 'uplot-react';
import Button from '@components/Button';
import { ShamefulAny } from '@interfaces/index';

import { createPurpleAndOrangeLine, createRedAndOrangeLine } from './model/utils/plotLinesUtils';

// Two lines with the same set of data but for purple when state is not good set value to null.
// The red line is draw first and the purple - second so it displayed over red.  In the gaps of purple red is displayed

const ValueTimeAISessionPlot: React.FC<{
  isCleared?: boolean;
  label?: string;
  currentSelfReviewTime: number;
  dataForPlot?: ShamefulAny;
  plotHeight?: number;

  isFullDataToShow?: boolean;
  observeDataWindow?: number;
}> = ({
  label = 'label',

  dataForPlot,
  currentSelfReviewTime,
  plotHeight,
  isFullDataToShow = false,
  observeDataWindow = 20,
}) => {
  const [valueStates, setValueStates] = useState<
    { timestamp: number; value: number; stateValue: number | undefined }[]
  >([]);
  const [chartSize, setChartSize] = useState<{ width: number; height: number }>({
    width: 400,
    height: plotHeight || 200,
  });
  const [isFullData, setIsFullData] = useState(isFullDataToShow);
  const [dataWindow, setDataWindow] = useState(observeDataWindow);
  const [offset, setOffset] = useState(0); // Offset in seconds
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (Array.isArray(dataForPlot)) {
      const newValueStates = dataForPlot
        .filter((item) => item.parameter !== undefined && item.parameter !== null)
        .map((item) => ({
          timestamp: item.timestamp ?? Date.now(),
          value: item.parameter,
          stateValue: item.measurementState,
        }))
        .sort((a, b) => a.timestamp - b.timestamp);

      setValueStates(newValueStates);
    }
  }, [dataForPlot]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      if (entries[0].contentRect) {
        const { width, height } = entries[0].contentRect;
        setChartSize({ width, height });
      }
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, []);

  const data = useMemo(() => {
    const offsetInMillis = offset * 1000;
    const timestamps = valueStates.map((entry) => entry.timestamp - offsetInMillis); // Apply offset

    const { purpleLine, orangeLine: skippedGoodSignal } = createPurpleAndOrangeLine(valueStates);
    const { redLine, orangeLine: skippedBadSignal } = createRedAndOrangeLine(valueStates);

    if (timestamps.length === 0) {
      return [[currentSelfReviewTime], [null], [null], [null]] as Uplot.AlignedData;
    }

    return [
      timestamps,
      purpleLine,
      redLine,
      [...skippedBadSignal, ...skippedGoodSignal],
    ] as Uplot.AlignedData;
  }, [valueStates, currentSelfReviewTime, offset]);

  const xAxisRange = useMemo(() => {
    if (isFullData) {
      const timestamps = valueStates.map((entry) => entry.timestamp - offset * 1000); // Apply offset
      return [Math.min(...timestamps), Math.max(...timestamps)];
    }
    const halfWindow = (dataWindow * 1000) / 2; // time before and after currentSelfReviewTime
    return [currentSelfReviewTime - halfWindow, currentSelfReviewTime + halfWindow];
  }, [valueStates, currentSelfReviewTime, isFullData, dataWindow, offset]);

  const chartOptions: Uplot.Options = {
    width: chartSize.width,
    height: chartSize.height,
    padding: [0, 0, 0, -12],

    scales: {
      x: {
        time: true,
        range: () => xAxisRange as [number, number],
      },
      y: {
        auto: true,
      },
    },
    series: [
      // orange is top priority, purple is low
      {},
      {
        label: `${label} (Purple)`,
        stroke: '#9333ea',
        value: (_, v) => `${v}`,
        spanGaps: false,
      },
      {
        label: `${label} (Red)`,
        stroke: '#ef4444',
        width: 1.8,
        value: (_, v) => `${v}`,
        spanGaps: false,
      },
      {
        label: `${label} (Orange)`,
        stroke: '#f97316',
        width: 1.8,
        value: (_, v) => `${v}`,
        spanGaps: false,
      },
    ],
    axes: [
      {
        stroke: '#9ca3af',
        grid: { show: true, stroke: '#e5e7eb' },
        values: (_uplot, ticks) => ticks.map((tick) => new Date(tick).toLocaleTimeString()),
      },
      {
        stroke: '#9ca3af',
        grid: { show: true, stroke: '#e5e7eb' },
      },
    ],
    legend: {
      show: false,
    },
    hooks: {
      ready: [
        (u) => {
          const tooltip = document.createElement('div');
          tooltip.style.position = 'absolute';
          tooltip.style.pointerEvents = 'none';
          tooltip.style.backgroundColor = 'rgba(255, 255, 255, 0.8)';
          tooltip.style.padding = '5px';
          tooltip.style.borderRadius = '4px';
          tooltip.style.fontSize = '11px';
          tooltip.style.boxShadow = '0 2px 4px rgba(0, 0, 0, 0.2)';
          tooltip.style.display = 'none';
          u.root.appendChild(tooltip);

          u.over.addEventListener('mousemove', (e) => {
            const { left, top } = u.over.getBoundingClientRect();
            const x = e.clientX - left;
            const y = e.clientY - top;
            const idx = u.posToIdx(x);

            tooltip.style.display = 'block';
            tooltip.style.left = `${x + 5}px`;
            tooltip.style.top = `${y + 5}px`;

            const purpleValue = u.data[2][idx];
            if (purpleValue !== null) {
              tooltip.textContent = `Value: ${purpleValue}`;
            } else {
              tooltip.style.display = 'none';
            }
          });

          u.over.addEventListener('mouseleave', () => {
            tooltip.style.display = 'none';
          });
        },
      ],
      draw: [
        (u) => {
          const ctx = u.ctx;

          if (!isFullData) {
            const xPos = chartSize.width / 2; // Middle of the chart
            ctx.beginPath();
            ctx.moveTo(xPos, 0); // Top of the chart
            ctx.lineTo(xPos, u.bbox.height); // Bottom of the chart
            ctx.strokeStyle = '#9333ea';
            ctx.lineWidth = 2;
            ctx.stroke();
            ctx.closePath();
          }
        },
      ],
    },
  };

  return (
    <div>
      <div className="flex items-center mb-2 gap-3 justify-between">
        <Button
          variant="outlinedPrimary"
          onClick={() => setIsFullData((prev) => !prev)}
          text={isFullData ? 'Windowed' : 'Full'}
          className="h-6"
        />
        <div className="text-xs">
          <div className="flex gap-2 items-center">
            <input
              type="number"
              value={dataWindow}
              onChange={(e) => setDataWindow(Number(e.target.value))}
              className="border rounded-md px-2 py-1 w-16 border-gray-200 bg-transparent h-6 text-center text-xs"
              disabled={isFullData}
            />
            <span>window (sec)</span>
          </div>
          <div className="flex gap-2 items-center">
            <input
              type="number"
              value={offset}
              onChange={(e) => setOffset(Number(e.target.value))}
              className="border rounded-md px-2 py-1 w-16 border-gray-200 bg-transparent h-6 text-center text-xs"
            />
            <span>offset (sec)</span>
          </div>
        </div>
      </div>

      <div
        ref={containerRef}
        className="w-full relative"
      >
        <UplotReact
          data={data}
          options={chartOptions}
        />
      </div>
    </div>
  );
};

export default ValueTimeAISessionPlot;
