import { ShenaiSdkDataI } from '@shared/types/shenai/sdk';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Uplot from 'uplot';
import UplotReact from 'uplot-react';
import { MEASUREMENT_STATE } from './model/constants/states';

// Two lines with the same set of data but for purple when state is not good set value to null.
// The red line is draw first and the purple - second so it displayed over red.  In the gaps of purple red is displayed

const ValueTimePlot: React.FC<{
  valueForPlot?: number;
  isCleared?: boolean;
  sdkData: ShenaiSdkDataI | null;
  label?: string;
  height?: number;
  width?: number;
}> = ({ valueForPlot, label = 'label', isCleared, sdkData, height = 200, width = 400 }) => {
  const [valueStates, setValueStates] = useState<
    { timestamp: number; valueForPlot: number; sdkValue: number | undefined }[]
  >([]);
  const [chartSize, setChartSize] = useState<{ width: number; height: number }>({
    width: width,
    height: height,
  });
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (valueForPlot !== undefined) {
      const timestamp = Date.now();
      const sdkValue = sdkData?.measurementState?.value;
      setValueStates((prev) => [...prev, { timestamp, valueForPlot, sdkValue }]);
    }
  }, [valueForPlot, sdkData]);

  useEffect(() => {
    if (isCleared === false) return;
    setValueStates([]);
  }, [isCleared]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      if (entries[0].contentRect) {
        const { width, height } = entries[0].contentRect;
        setChartSize({ width, height });
      }
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, []);

  const data = useMemo(() => {
    const timestamps = valueStates.map((entry) => entry.timestamp);
    const purpleLine = valueStates.map((entry) =>
      entry.sdkValue === MEASUREMENT_STATE.RUNNING_SIGNAL_GOOD ? entry.valueForPlot : null
    );
    const redLine = valueStates.map((entry) => entry.valueForPlot); // Red line always present

    if (timestamps.length === 0) {
      return [[0], [0], [0]] as Uplot.AlignedData;
    }

    return [timestamps, redLine, purpleLine] as Uplot.AlignedData;
  }, [valueStates]);

  const chartOptions: Uplot.Options = {
    width: chartSize.width,
    height: chartSize.height,
    padding: [0, 0, 0, -12],

    scales: {
      x: {
        time: true,
      },
      y: {
        auto: true,
      },
    },
    series: [
      {},
      {
        label: `${label} (Red)`,
        stroke: '#ef4444',
        value: (_, v) => `${v}`,
        spanGaps: true,
      },
      {
        label: `${label} (Purple)`,
        stroke: '#9333ea',
        value: (_, v) => `${v}`,
        spanGaps: false,
      },
    ],
    axes: [
      {
        stroke: '#9ca3af',
        grid: { show: true, stroke: '#e5e7eb' },
        values: (_uplot, ticks) => ticks.map((tick) => new Date(tick).toLocaleTimeString()),
      },
      {
        stroke: '#9ca3af',
        grid: { show: true, stroke: '#e5e7eb' },
      },
    ],
    legend: {
      show: false,
    },
    hooks: {
      ready: [
        (u) => {
          const tooltip = document.createElement('div');
          tooltip.style.position = 'absolute';
          tooltip.style.pointerEvents = 'none';
          tooltip.style.backgroundColor = 'rgba(255, 255, 255, 0.8)';
          tooltip.style.padding = '5px';
          tooltip.style.borderRadius = '4px';
          tooltip.style.fontSize = '11px';
          tooltip.style.boxShadow = '0 2px 4px rgba(0, 0, 0, 0.2)';
          tooltip.style.display = 'none';
          u.root.appendChild(tooltip);

          u.over.addEventListener('mousemove', (e) => {
            const { left, top } = u.over.getBoundingClientRect();
            const x = e.clientX - left;
            const y = e.clientY - top;
            const idx = u.posToIdx(x);

            tooltip.style.display = 'block';
            tooltip.style.left = `${x + 5}px`;
            tooltip.style.top = `${y + 5}px`;

            const purpleValue = u.data[2][idx];
            if (purpleValue !== null) {
              tooltip.textContent = `Value: ${purpleValue}`;
            } else {
              tooltip.style.display = 'none';
            }
          });

          u.over.addEventListener('mouseleave', () => {
            tooltip.style.display = 'none';
          });
        },
      ],
    },
  };

  return (
    <div
      ref={containerRef}
      style={{ width: '100%', position: 'relative' }}
    >
      <UplotReact
        data={data}
        options={chartOptions}
      />
    </div>
  );
};

export default ValueTimePlot;
