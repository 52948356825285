type BoundingBox = {
  x: number;
  y: number;
  width: number;
  height: number;
};

type FaceOrientation = {
  yaw: number;
  pitch: number;
};

export const rateFacePosition = (boundingBox: BoundingBox, orientation?: FaceOrientation): number => {
  if (!boundingBox) {
    return 0;
  }

  // Ideal bounding box settings
  const idealBox: BoundingBox = {
    x: 0.35,
    y: 0.35,
    width: 0.3,
    height: 0.6,
  };

  // Define thresholds for scoring (lower is stricter)
  const positionTolerance = 0.1;
  const sizeTolerance = 0.1;

  // Calculate the center of the user's bounding box
  const userCenterX = boundingBox.x + boundingBox.width / 2;
  const userCenterY = boundingBox.y + boundingBox.height / 2;

  // Calculate deviations from the ideal
  const xDeviation = Math.abs(userCenterX - idealBox.x);
  const yDeviation = Math.abs(userCenterY - idealBox.y);
  const widthDeviation = Math.abs(boundingBox.width - idealBox.width);
  const heightDeviation = Math.abs(boundingBox.height - idealBox.height);

  // Score calculation (1 to 10 scale)
  let score = 10;

  // Deduct points based on deviations
  if (xDeviation > positionTolerance) {
    score -= (xDeviation / positionTolerance) * 2; // Deduct up to 2 points for x deviation
  }
  if (yDeviation > positionTolerance) {
    score -= (yDeviation / positionTolerance) * 2; // Deduct up to 2 points for y deviation
  }
  if (widthDeviation > sizeTolerance) {
    score -= (widthDeviation / sizeTolerance) * 3; // Deduct up to 3 points for width deviation
  }
  if (heightDeviation > sizeTolerance) {
    score -= (heightDeviation / sizeTolerance) * 3; // Deduct up to 3 points for height deviation
  }

  // Check if the face is centered within the ideal range
  const centerTolerance = 0.1; // Tolerance for how off-center the face can be

  if (Math.abs(userCenterX - 0.5) > centerTolerance) {
    score -= 2; // Deduct 2 points if the face is too far horizontally off-center
  }
  if (Math.abs(userCenterY - 0.5) > centerTolerance) {
    score -= 2; // Deduct 2 points if the face is too far vertically off-center
  }

  // Check if the face is turned towards the center
  if (orientation) {
    const yawTolerance = 15; // Tolerance for horizontal rotation in degrees
    const pitchTolerance = 15; // Tolerance for vertical rotation in degrees

    if (Math.abs(orientation.yaw) > yawTolerance) {
      score -= 2; // Deduct 2 points if the face is turned too far horizontally
    }
    if (Math.abs(orientation.pitch) > pitchTolerance) {
      score -= 2; // Deduct 2 points if the face is turned too far vertically
    }
  }

  // Ensure score stays within 1-10
  score = Math.max(1, Math.min(10, Math.round(score)));

  return score;
};
