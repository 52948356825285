import { getEventSourceByType } from '@components/Calibration/utils';
import { EventLog } from '@models/eventLog';
import { AppContext } from '../../../contextApp';
import { useContext, useEffect } from 'react';
import { useWebSocket } from './../../../websocket';

const useSocketIOLogging = (roomUrl: string, userId: string) => {
  const { isLogsSending, getEventLogs, clearEventLogs } = useContext(AppContext);
  const { socket } = useWebSocket();
  useEffect(() => {
    if (!isLogsSending || !roomUrl || !userId || !socket) return;

    const sessionId = roomUrl.split('/').pop()!;

    const sendLogsInterval = setInterval(() => {
      const logs: EventLog[] = getEventLogs(); // copy logs
      if (isLogsSending) {
        const logsWithSource = logs.map((log) => ({
          ...log,
          source: getEventSourceByType(log.type),
        }));

        if (logs.length > 0 && socket?.connected) {
          socket.emit('logs-ai', {
            userId,
            sessionId,
            logs: logsWithSource,
            json_obj: JSON.stringify(logs),
          });
          clearEventLogs();
        }
      }
    }, 2000);

    return () => {
      clearInterval(sendLogsInterval);
    };
  }, [isLogsSending, roomUrl, userId]);
};

export default useSocketIOLogging;
