import { memo } from 'react';

import InfoRow from '@shared/ui/caption/InfoRow';
import CharacteristicPlot from '@features/Plot/CharacteristicPlot';
import ToggleableTabs from '@features/Tab/ToggleableTabs/ToggleableTabs';
import { TOGGLEABLE_SECTIONS } from '../../RealtimeDashboard/model/constants/togglableSections';
import { useSelector } from '@xstate/react';
import { dashboardActor } from '../../RealtimeDashboard/model/xstate/dashboardMachine';

type SuggestionDataProps = {
  characteristicData: {
    openness: number;
    introspection: number;
    depthOfSharing: number;
  };
};

const SelfReviewCharacteristicData = ({
  characteristicData,
}: SuggestionDataProps) => {
  const sections = useSelector(dashboardActor, (state) => state.context.sections);

  const handleSectionToggle = (key: string) => {
    dashboardActor.send({ type: 'TOGGLE_SECTION', key });
  };

  return (
    <div className="mb-4 space-y-4">
      <div className="flex flex-col gap-4 w-full">
        <ToggleableTabs
          isShown={sections[TOGGLEABLE_SECTIONS.CHARACTERISTIC_ANALYSIS]}
          onToggle={() => handleSectionToggle(TOGGLEABLE_SECTIONS.CHARACTERISTIC_ANALYSIS)}
          tabs={[
            {
              label: 'Characteristic analysis',
              content: (
                <div>
                  <div className="flex flex-col gap-4 w-full">
                    <InfoRow
                      key="openness"
                      label={'Openness'}
                      value={''}
                    />
                    <CharacteristicPlot
                      characteristicValue={characteristicData.openness}
                      label="Openness"
                    />
                  </div>
                  <div className="flex flex-col gap-4 w-full">
                    <InfoRow
                      key="introspection"
                      label={'Introspection'}
                      value={''}
                    />
                    <CharacteristicPlot
                      characteristicValue={characteristicData.introspection}
                      label="Introspection"
                    />
                  </div>
                  <div className="flex flex-col gap-4 w-full">
                    <InfoRow
                      key="depth-of-sharing"
                      label={'Depth Of Sharing'}
                      value=""
                    />
                    <CharacteristicPlot
                      characteristicValue={characteristicData.depthOfSharing}
                      label="Depth Of Sharing"
                    />
                  </div>
                </div>
              ),
            },
          ]}
        />
      </div>
    </div>
  );
};

export default memo(SelfReviewCharacteristicData);
